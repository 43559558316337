import React, { Component } from 'react';
import { Tabs, Tab } from '@smartplatform/ui';
import store from 'client/store';
import WarehouseStore from './store';

import { observer, Provider } from 'mobx-react';
import { observable } from 'mobx';
import t from 'i18n';
import { WAREHOUSE_TABS, CREATE_WAREHOUSE_TABS } from './tabs';
import { WarehousesEdit } from './WarehousesEdit';

@observer
export class WarehouseMain extends Component {
	@observable record = null;
	@observable isLoading = true;
	@observable isSaving = false;

	constructor(props) {
		super(props);
		this.id = props.match.params?.id;
		this.init();
		this.store = new WarehouseStore(this.id);
	}

	init = async () => {
		this.isLoading = true;

		if (this.id) {
			this.record = await store.model.Warehouse.findById(this.id);
			store.ui.title = this.record.name;
		} else {
			this.record = new store.model.Warehouse();
			store.ui.title = t('warehouse.new');
		}

		this.isLoading = false;
	};

	render() {

		if (!this.record) return t('warehouse.errors.notExist');

		let _routes = CREATE_WAREHOUSE_TABS;
		if (this.id) {
			_routes = WAREHOUSE_TABS.filter((route) => (route.visible ? route.visible() : true));
		}

		return (
			<Provider store={this.store}>
				{this.id ? (
					<>
						<Tabs params={{ id: this.id }}>
							{_routes.map((route, i) => (
								<Tab key={i} {...route} />
							))}
						</Tabs>
					</>
				) : (
					<WarehousesEdit />
				)}
			</Provider>
		);
	}
}


