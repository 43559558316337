import { ORDERS_MODE_KANBAN } from 'client/modules/sales/orders/Orders'; //!если удалить, то в консольке лезет ошибка
import { MODES } from './constants';

export const localStoreConfig = {
	isDarkMode: false,
	operations: {},
	analytics: {},
	documents: {
		mode: MODES.LIST,
	},
	projects: {
		mode: MODES.LIST,
		projectLists: [],
		order: 'lastUpdate desc',
		user: 'ME',
		closed: null,
	},
	tasks: {
		boardLists: [],
		closed: false,
		order: 'updatedAt desc nulls last',
		projects: [],
		search: '',
		page: 1,
		user: 'ME',
		owner: 'ALL',
	},
	task: {
		isTaskLogsSortedByNew: false,
	},
	orders: {
		mode: MODES.LIST,
	},
	submenu: {},
};

