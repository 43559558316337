import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faPaperclip,
	faPen,
	faUserPlus,
	faXmark,
	faChartLine,
	faEye,
	faEyeSlash,
	faCheck,
	faClone,
	faInfoCircle,
	faTag,
	faPlus,
	faPlusCircle,
	faChevronDown,
	faChevronUp,
	faCommentAlt,
} from '@fortawesome/free-solid-svg-icons';

const renderFaIcons = {
	attachIcon: faPaperclip,
	editIcon: faPen,
	memberIcon: faUserPlus,
	xMark: faXmark,
	chartIcon: faChartLine,
	eyeIcon: faEye,
	eyeSlashIcon: faEyeSlash,
	checkIcon: faCheck,
	copyIcon: faClone,
	info: faInfoCircle,
	priceListIcon: faTag,
	plus: faPlus,
	circlePlus: faPlusCircle,
	arrowDown: faChevronDown,
	arrowUp: faChevronUp,
	comment: faCommentAlt
};

Object.keys(renderFaIcons).forEach((faIcon) => {
	renderFaIcons[faIcon] = <FontAwesomeIcon icon={renderFaIcons[faIcon]} />;
});

export { renderFaIcons };

