import React from 'react';
import { observer } from 'mobx-react';
import { UserSelect } from 'components';

export const ToolbarUserSelect = observer(({ label, ...recordSelectProps }) => {
	return (
		<div className='toolbar-user-select'>
			{label && <label>{label}</label>}
			<UserSelect {...recordSelectProps} />
		</div>
	);
});

