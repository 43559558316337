import React from 'react';
import { RecordSelect as _RecordSelect } from '@smartplatform/ui';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import classNames from 'classnames';
import './recordSelect.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@observer
export class RecordSelect extends React.Component {
	onClear = (e, emptyValue) => {
		e.stopPropagation();
		this.props.onChange(emptyValue);
	};
	render() {
		const { value, showClearButton } = this.props;
		let beforeInput;
		if (showClearButton) {
			const isArrayValue = Array.isArray(toJS(value));
			const isEmptyValue = isArrayValue ? value.length === 0 : value === null;
			beforeInput = !isEmptyValue && (
				<FontAwesomeIcon icon={faTimes} className='clear-button' onClick={(e) => this.onClear(e, isArrayValue ? [] : null)} />
			);
		}
		const className = classNames(this.props.className, 'custom-record-select');
		return <_RecordSelect {...this.props} beforeInput={beforeInput} className={className} />;
	}
}

