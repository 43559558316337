import { Field, Row, NumberInput } from '@smartplatform/ui';
import store from 'client/store';
import { ModelEdit } from 'components';
import t from 'i18n';
import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import Values from '../../Values';

@observer
export default class Edit extends React.Component {
	@observable record = null;

	constructor(props) {
		super(props);
	}

	getRecord = async (record) => {
		this.record = record;
		if (!record.materials) {
			this.record.materials = [];
		}
	};

	render() {
		let { modelName, path, match } = this.props;
		const id = parseInt(match.params.id);
		return (
			<ModelEdit model={store.model[modelName]} stay path={path} id={id} getRecord={this.getRecord}>
				<Field property='name' label={t('material.name')} />
				{this.record && this.record.id ? (
					<div className='form-field'>
						<label>{t('material.type.materials')}: </label>
						<Values record={this.record} relation={'materials'} modelName={'Material'} foreignKey={'materialTypeId'} />
					</div>
				) : (
					<div className='workshop-warning'>{t('material.type.new')}</div>
				)}
			</ModelEdit>
		);
	}
}
