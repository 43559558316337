import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Warehouses from '../storehouse/warehouses';
import { Nomenclatures } from './nomenclatures';
import { Movements } from './nomenclatureMovements';

/**
 * Главный компонент с роутами и компонентами модуля.
 * Также может содержать, например, некую асинхронную логику инициализации.
 */

export default () => (
	<Switch>
		<Route path='/warehouses' component={Warehouses} />
		<Route path='/nomenclatures' component={Nomenclatures} />
		<Route path='/nomenclatureMovements' component={Movements} />
	</Switch>
);

