export const DOCUMENT_INCLUDE = [
	{ relation: 'user', scope: { fields: ['id', 'avatar', 'lastName', 'firstName', 'middleName'] } },
	{
		relation: 'labels',
		scope: {
			fields: ['id', 'name', 'color'],
		},
	},
	{
		relation: 'contragent',
		scope: {
			fields: ['id', 'name'],
		},
	},
];

export const DOCUMENT_LIST_INCLUDE = [
	'type',
	'status',
	'contragent',
	'labels',
	'attachments',
	{
		relation: 'projects',
		scope: {
			include: ['projectList'],
		},
	},
];

export const DOCUMENT_FORM_INCLUDE = ['user', ...DOCUMENT_LIST_INCLUDE];

export const LOCAL_STORAGE_FIELDS = ['mode'];

