import qs from 'qs';
import { createBrowserHistory } from 'history';
import { Rest, ModelStore } from '@smartplatform/model-store';
import { RouteStore } from '@smartplatform/route-store';
import { LocalStore } from '@smartplatform/local-store';
import UIStore from './ui';
import { io } from 'socket.io-client';
import { localStoreConfig } from './localStoreConfig';

/**
 * Глобальный контейнер сторов
 */
export class AppStore {
	local; // Локальное хранилище
	model; // Хранилище моделей
	route; // Хранилище маршрута браузера
	api; // API обмена с сервером
	admin; // Хранилище @admin
	ui; // ui
	socket; // вебсокет для общения с сервером
	config; // Конфигурация клиента

	subscribers = {};

	constructor() {
		this.history = createBrowserHistory();
		this.history.listen(this.onHistoryChange);
		this.ui = new UIStore(this);
		this.local = new LocalStore();
		this.socket = new io();

		this.local.extend(localStoreConfig);
		this.local.save();

		this.rest = new Rest();
		this.model = new ModelStore({
			transport: this.rest,
			cache: false,
			autoConnect: false,
			onLogin: this.onLogin,
		});

		this.route = new RouteStore({
			history: this.history,
			decode: qs.parse,
			encode: qs.stringify,
		});
	}

	/**
	 * Вызывается при успешной авторизации и переподключает вебсокет
	 * @param user
	 */
	onLogin = (user) => {
		// переподключаем вебсокет
		console.log(this.socket);
		this.socket.disconnect().connect();
	};

	get isAdmin() {
		return this.model.roles.map((i) => i.name).includes('admin');
	}

	get maxSelectItems() {
		return window.innerHeight > 1000 ? 15 : 10;
	}

	/**
	 * Инициализация стора
	 * @returns {Promise<void>}
	 */
	init = async () => {
		await this.model.connect();

		// переподключаем вебсокеты так как возможно пользователь уже авторизовался
		this.socket.disconnect().connect();

		document.body.className = this.local.isDarkMode ? 'dark-theme' : 'default-theme';
	};

	subscribe = (event, callback) => {
		if (!this.subscribers[event]) this.subscribers[event] = [];
		this.subscribers[event].push(callback);
	};

	unsubscribe = (event, callback) => {
		if (this.subscribers[event]) {
			const index = this.subscribers[event].findIndex((cb) => cb === callback);
			if (index !== -1) this.subscribers[event].splice(index, 1);
			if (this.subscribers[event].length === 0) delete this.subscribers[event];
		}
	};

	onHistoryChange = (e) => {
		// console.log('onHistoryChange', e);
		if (this.subscribers.history) this.subscribers.history.forEach((cb) => cb(e));
	};
}

window.APP_STORE = new AppStore();

export default window.APP_STORE;

