import React from 'react';
import { Field, ManyToManySelect, Pager, Row, Input, RelationSelect, NumberValue } from '@smartplatform/ui';
import { inject, observer } from 'mobx-react';
import { Attachments, BoxLabel, Form, UserSelectOld } from 'components';
import { observable } from 'mobx';
import { renderStatus, renderUser, renderProject, renderPlaceholder, renderLabelItem } from 'client/tools';
import store from 'client/store';
import t from 'i18n';

import './documentEdit.scss';

@inject('documentStore')
@observer
export class DocumentEdit extends React.Component {
	@observable attachments = [];
	@observable page = 1;
	@observable perPage = 1;

	constructor(props) {
		super(props);
		this.record = props.documentStore.record;
		this.getAttachments();
	}

	getAttachments = async () => {
		if (this.record.id) this.attachments = await this.record.attachments();
	};

	get docFiles() {
		return this.attachments.map((attachment) => {
			const attachmentUrl = `${window.location.origin}/api/containers/attachments/download/${attachment.id}-filename`;
			return (
				<div className='preview-file' key={attachment.id}>
					<span>{attachment.filename}</span>
					<iframe src={`https://docs.google.com/viewer?embedded=true&url=${attachmentUrl}`} className='preview-iframe' />
				</div>
			);
		});
	}

	onPageChange = (page) => {
		this.page = page;
	};

	back = () => store.route.push({ path: this.props.path });

	render() {
		const formProps = {
			className: 'document-form',
			record: this.record,
			onDelete: this.back,
			onSave: this.back,
			onCancel: this.back,
			stay: true,
			disableSave: !this.record.statusId || !this.record.name,
		};

		return (
			<div className='main-info'>
				<Form {...formProps}>
					<Row>
						<Field property='name' label={t('document.card.name')} isRequired>
							<Input placeholder={t('document.card.addName')} />
						</Field>
						<Field relation='type' property='name' label={t('document.card.type')}>
							<RelationSelect empty={renderPlaceholder(t('select'))} />
						</Field>
					</Row>
					<Row className='form-row'>
						<div className='number-total'>
							<Field property='number' label={t('document.card.number')}>
								<Input placeholder={t('document.card.addNumber')} />
							</Field>
							<Field property='total' label={t('document.card.totalSum')}>
								<Input placeholder={t('document.card.addSum')} />
							</Field>
						</div>
						<Field relation='status' computed={renderStatus} label={t('document.card.status')} isRequired>
							<RelationSelect empty={renderPlaceholder(t('select'))} />
						</Field>
					</Row>
					<Row>
						<Field
							relation='projects'
							computed={renderProject}
							itemTag={renderProject}
							label={t('document.card.project')}
							filter={{ include: ['projectList'] }}
						>
							<ManyToManySelect empty={renderPlaceholder(t('select'))} />
						</Field>
						<Field relation='user' label={t('document.card.responsible')}>
							<UserSelectOld />
							{/* <RelationSelect empty={renderPlaceholder(t('select'))} /> */}
						</Field>
					</Row>
					<Row>
						<Field relation='contragent' property='name' itemTag={(r) => r.name} label={t('document.card.contragents')}>
							<ManyToManySelect empty={renderPlaceholder(t('select'))} />
						</Field>
						<div />
					</Row>
					<Row>
						<Field relation='labels' property='name' label={t('document.card.labels')}>
							<ManyToManySelect
								className='box-labels-multiselect'
								noSearch
								computed={renderLabelItem}
								renderItemTag={renderLabelItem}
							/>
						</Field>
					</Row>
					<div className='sp-row'>
						{this.record.id && (
							<div className='form-field'>
								<label>{t('files')}:</label>
								<Attachments record={formProps.record} canUpload={true} uploadIsImage />
							</div>
						)}
					</div>
				</Form>
				<div className='preview'>
					{this.attachments && this.docFiles[this.page - 1]}
					{this.attachments.length > 1 && (
						<Pager current={this.page} totalCount={this.attachments.length} onChange={this.onPageChange} itemsPerPage={this.perPage} noCount />
					)}
				</div>
			</div>
		);
	}
}

