import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { NomenclatureEdit } from './NomenclatureEdit';
import { NomenclatureList } from './NomenclatureList';
import { Movement } from './movement/Movement';
import './style.scss';

export const Nomenclatures = ({ match }) => {
	const { path } = match;
	return (
		<Switch>
			<Route path={path} exact children={({ match }) => <NomenclatureList match={match} path={path} />} />
			<Route path={path + '/createmovement'} component={Movement}  />
			<Route path={path + '/:id'} children={({ match }) => <NomenclatureEdit match={match} path={path} />} />
		</Switch>
	);
};

