export const fio = record => (record.lastName || record.firstName || record.middleName) ?
	`${record.lastName || ''} ${record.firstName || ''} ${record.middleName || ''}`.trim()
	:
	record.username;

export const fioOrEmpty = record => (record.lastName || record.firstName || record.middleName) ?
	`${record.lastName || ''} ${record.firstName || ''} ${record.middleName || ''}`.trim()
	:
	undefined;

export const fioShortOrEmpty = record => {
	if (!record) return null;
	if (record.lastName || record.firstName || record.middleName) {
		if (!record.lastName) {
			return `${record.firstName || ''} ${record.middleName || ''}`.trim();
		}
		return `${record.lastName} ${record.firstName ? record.firstName.substr(0, 1) + '.' : ''} ${record.middleName ? record.middleName.substr(0, 1) + '.' : ''}`;
	}
	return undefined;
}

export const fioShort = record => {
	if (!record) return null;
	if (record.lastName || record.firstName || record.middleName) {
		if (!record.lastName) {
			return `${record.firstName || ''} ${record.middleName || ''}`.trim();
		}
		return `${record.lastName} ${record.firstName ? record.firstName.substr(0, 1) + '.' : ''} ${record.middleName ? record.middleName.substr(0, 1) + '.' : ''}`;
	}
	return record.username;
}

export const initials = user => user ? (
	(user.lastName ? user.lastName : '') + ' ' +
	(user.firstName ? user.firstName.charAt(0)+'.' : '') + ' ' +
	(user.middleName ? user.middleName.charAt(0)+'.' : '')
) : '';
