import React from 'react';
import ReactDOM from 'react-dom';
import store from './store';
import App from './App';

const render = (Component) => {
	ReactDOM.render(<Component />, document.getElementById('root'));
};

const init = async () => {
	await store.init();
	render(App);
};

init();

