import store from 'client/store';
import { observable } from 'mobx';

export default class WarehouseStore {
	@observable isLoading = true;

	constructor(id) {
		this.id = id;
		this.init();
	}

	init = async () => {
		this.isLoading = true;

		if (this.id) {
			this.record = await store.model.Warehouse.findById(this.id);
		} else {
			this.record =null;
		}

		this.isLoading = false;
	};
}