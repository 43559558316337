import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Popup, Checkbox } from '@smartplatform/ui';
import { Button, CancelButton } from 'components';
import t from 'i18n';

import './editColumnsPopup.scss';

@inject('reportStore')
@observer
export class EditColumnsPopup extends Component {
	@observable selectedColumns = [];

	constructor(props) {
		super(props);
		this.store = props.reportStore;
		this.selectedColumns = this.store.selectedColumns.map((column) => ({ ...column }));
	}

	onVisibleFieldChange = (value, index) => {
		this.selectedColumns[index].isSelected = value;
	};

	onConfirm = () => {
		this.store.selectedColumns = [...this.selectedColumns];
		this.store.onToggleEdit(false)();
	};

	onSelectAll = () => {
		this.selectedColumns.forEach((item) => (item.isSelected = true));
	};

	onClearAll = () => {
		this.selectedColumns.forEach((item) => (item.isSelected = false));
	};

	render() {
		return (
			<Popup onClose={this.store.onToggleEdit(false)} className='report-order-popup-edit'>
				<div className='columns'>
					{this.selectedColumns.map((column, index) => {
						return (
							<div
								className='checkbox'
								onClick={() => this.onVisibleFieldChange(!column.isSelected, index)}
								key={column.label}
							>
								<Checkbox value={column.isSelected} label={column.label} />
							</div>
						);
					})}
				</div>
				<div className='actions'>
					<div className='text-actions'>
						<span onClick={this.onSelectAll}>{t('selectAll')}</span>
						<span onClick={this.onClearAll}>{t('clearAll')}</span>
					</div>
					<div className='button-actions'>
						<Button text={t('confirm')} onClick={this.onConfirm} />
						<CancelButton onClick={this.store.onToggleEdit(false)} />
					</div>
				</div>
			</Popup>
		);
	}
}

