import { autorun, observable } from 'mobx';
import { debounce } from 'lodash';
import { endOfDay, startOfDay } from 'date-fns';
import { MODES, TABLE_ROW_HEIGHT } from 'client/constants';
import store from 'client/store';
import { getRoleNames, getPerPage } from 'client/tools';
import { ORDER_LIST_INCLUDE, ORDER_KANBAN_INCLUDE, KANBAN_INITIAL_LIMIT, LOCAL_STORAGE_FIELDS, ORDER_TILES_INCLUDE } from './constants';

import t from 'i18n';

export const ALL = 'ALL';

export default class OrdersStore {
	@observable search = '';
	@observable priceFrom = null;
	@observable priceTo = null;
	@observable orderList = [];
	@observable paid = null;
	@observable startDateFrom = null;
	@observable startDateTo = null;
	@observable endDateFrom = null;
	@observable endDateTo = null;
	@observable category = null;

	@observable mode = MODES.LIST;
	@observable isLoading = true;
	@observable records = [];
	@observable filters = { and: [] };
	@observable page = 1;
	@observable order = 'id desc';
	@observable kanbanLists = [];
	@observable hiddenLists = [];

	isReportMode = false;
	perPage = null;
	availableListIds = [];
	isManagerOrAdmin = false;
	localStorage = store.local.orders;
	kanban = {};
	//*функция которая = init из reportStore
	fetchReportRecords;

	constructor() {
		this.user = store.model.user;
		this.doSearch = debounce(this.doSearch, 500, { leading: false, trailing: true });
		this.loadLocalStorage();
		autorun(() => {
			this.hiddenLists.length;
			this.isInitialized && this.saveToLocalStorage();
		});
		this.isInitialized = true;
	}

	init = async () => {
		this.roles = getRoleNames();
		this.isManagerOrAdmin = this.roles.includes('admin') || this.roles.includes('manager');
		if (!this.isManagerOrAdmin) this.availableListIds = await store.model.ViewOrderList.getLists({ rolesNames: this.roles });
	};

	selectFetchMode = async () => {
		if (this.isReportMode) {
			this.fetchReportRecords();
		} else {
			const modes = {
				[MODES.KANBAN]: this.fetchKanbanRecords,
				[MODES.LIST]: this.fetchListRecords,
				[MODES.TILES]: this.fetchTilesRecords,
			};
			modes[this.mode]();
		}
	};

	fetchTilesRecords = async () => {
		const tilesPageSize = 20;

		this.records = await store.model.ViewOrder.find({
			where: this.filters,
			include: ORDER_TILES_INCLUDE,
			skip: (this.page - 1) * tilesPageSize,
			limit: tilesPageSize,
			order: this.order,
		});

		this.isLoading = false;
	};

	fetchListRecords = async () => {
		this.records = await store.model.ViewOrder.find({
			where: this.filters,
			include: ORDER_LIST_INCLUDE,
			skip: (this.page - 1) * this.perPage,
			limit: this.perPage,
			order: this.order,
		});

		this.isLoading = false;
	};

	fetchKanbanRecords = async () => {
		let listWhere = { and: [] };

		if (this.orderList.length) listWhere.and.push({ id: { inq: this.orderList.map((orderList) => orderList.id) } });

		this.kanbanLists = await store.model.ViewOrderList.find({
			where: listWhere,
			include: [
				{
					relation: 'orders',
					scope: {
						where: this.filters,
						limit: KANBAN_INITIAL_LIMIT,
						order: this.order,
						include: ORDER_KANBAN_INCLUDE,
					},
				},
			],
			order: 'priority desc',
		});

		this.kanban.reload?.();
		this.isLoading = false;
	};

	doSearch = () => {
		this.compileFilters();
	};

	onChange = (prop) => (value) => {
		if (prop !== 'page' && this.mode !== MODES.KANBAN) this.page = 1;
		this[prop] = value;

		this.compileFilters();
	};

	setPerPage = (value) => {
		this.page = 1;
		this.perPage = value;
	};

	onDatechange = (prop, startDay) => (value) => {
		if (startDay) {
			this[prop] = value ? startOfDay(value) : null;
		} else {
			this[prop] = value ? endOfDay(value) : null;
		}

		this.compileFilters();
	};

	onSearch = (e) => {
		this.search = e.target.value;
		this.doSearch();
	};

	onQueryUpdate = (query) => {
		const { _totalCount, ...rest } = query;
		this.query = rest;
		if (query.order) {
			this.order = query.order;
		} else {
			this.order = 'id desc';
		}

		this.compileFilters();
	};

	compileFilters = () => {
		const idFilter = this.availableListIds.length ? { inq: this.availableListIds } : { neq: null };
		const where = {
			and: [{ listId: idFilter }],
		};

		const trimmed = this.search.trim();
		if (trimmed.length > 0) {
			const words = trimmed.split(' ');
			words.forEach((word) =>
				where.and.push({
					or: [
						{ id: { like: `${word}%` } },
						{ description: { ilike: `%${word}%` } },
						{ clientName: { ilike: `%${word}%` } },
						{ fio: { ilike: `%${word}%` } },
						{ username: { ilike: `%${word}%` } },
						{ contragentName: { ilike: `%${word}%` } },
						{ invoiceNumber: { ilike: `%${word}%` } },
					],
				})
			);
		}

		if (parseFloat(this.priceFrom)) {
			where.and.push({ total: { gte: parseFloat(this.priceFrom) } });
		}

		if (parseFloat(this.priceTo)) {
			where.and.push({ total: { lte: parseFloat(this.priceTo) } });
			if (!parseFloat(this.priceFrom)) {
				where.and.push({ total: { gt: 0 } });
			}
		}

		if (this.orderList.length) {
			where.and.push({ listId: { inq: this.orderList.map((orderList) => orderList.id) } });
		}

		if (this.paid !== null) {
			where.and.push({ paid: this.paid });
		}

		if (this.startDateFrom) {
			where.and.push({ startDate: { gte: this.startDateFrom } });
		}

		if (this.startDateTo) {
			where.and.push({ startDate: { lte: this.startDateTo } });
		}

		if (this.endDateFrom) {
			where.and.push({ endDate: { gte: this.endDateFrom } });
		}

		if (this.endDateTo) {
			where.and.push({ endDate: { lte: this.endDateTo } });
		}

		if (this.category) {
			where.and.push({ categoryIds: { like: `%"${this.category.id}"%` } });
		}

		this.filters = where;
		this.selectFetchMode();
	};

	cleanFilters = () => {
		this.search = '';
		this.priceFrom = null;
		this.priceTo = null;
		this.orderList = [];
		this.paid = null;
		this.startDateFrom = null;
		this.startDateTo = null;
		this.endDateFrom = null;
		this.endDateTo = null;
		this.category = null;
		this.page = 1;
		this.order = 'id desc';

		this.compileFilters();
	};

	onModeChange = (mode) => {
		this.page = 1;
		this.mode = mode;
		this.saveToLocalStorage();
	};

	saveToLocalStorage = () => {
		LOCAL_STORAGE_FIELDS.forEach((field) => (this.localStorage[field] = this[field]));
		store.local.save();
	};

	loadLocalStorage = () => {
		const storageKeys = Object.keys(this.localStorage);
		storageKeys.length && storageKeys.forEach((key) => (this[key] = this.localStorage[key]));
	};
}

