import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import t from 'i18n';
import { Button } from './Button';
import { faFilterCircleXmark, faUpload } from '@fortawesome/free-solid-svg-icons';
import ImportIcon from 'client/img/icons/import.svg';
import ExportIcon from 'client/img/icons/export.svg';

import './button.scss';

const makeButton =
	({ className, ...rest }) =>
	(props) =>
		<Button {...rest} {...props} className={classNames(props.className, className)} />;

export const CancelButton = makeButton({ text: t('cancel'), className: 'cancel-button', variant: 'default' });
export const CleanButton = makeButton({
	text: t('clean'),
	startIcon: <FontAwesomeIcon icon={faFilterCircleXmark} />,
	variant: 'default',
});
export const ExportButton = makeButton({ text: t('export'), startIcon: <FontAwesomeIcon icon={faUpload} />, variant: 'default' });
export const ImportButton = makeButton({ text: t('import'), startIcon: <ImportIcon />, variant: 'default' });

export { Button } from './Button';
export { DeleteButton } from './DeleteButton';

