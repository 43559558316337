import React from 'react';
import { NomenclatureRemains } from './NomenclatureRemains';
import { IconTitle } from 'components';
import { faWarehouse, faBoxes } from '@fortawesome/free-solid-svg-icons';
import { WarehousesEdit } from './WarehousesEdit';
import t from 'i18n';
import { WAREHOUSES_PATH } from './constants';

export const WAREHOUSE_TABS = [
	{
		title: <IconTitle faIcon={faWarehouse} text={t('production.warehouses')} />,
		path: `${WAREHOUSES_PATH}/:id`,
		exact: true,
		component: WarehousesEdit,
	},
	{
		title: <IconTitle faIcon={faBoxes} text={t('warehouse.nomenclatureRemains')} />,
		path: `${WAREHOUSES_PATH}/:id/nomenclatureList`,
		component: NomenclatureRemains,
	},
]

export const CREATE_WAREHOUSE_TABS = [
	{
		title: <IconTitle faIcon={faWarehouse} text={t('production.warehouses')} />,
		path: `${WAREHOUSES_PATH}/create`,
		component: WarehousesEdit,
		exact: true,
	},
];