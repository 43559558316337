import React from 'react';
import t from 'i18n';
import { Button } from 'components';
import { ActionbarContext } from './Actionbar';

export const SaveButton = () => {
	const { form, record, saveTitle, noSave, disableSave } = React.useContext(ActionbarContext);

	if (noSave) return null;

	const onSave = async () => await form.save();

	const defaultTitle = record.id ? t('save') : t('create');

	return <Button onClick={onSave} variant='primary' disabled={disableSave} text={saveTitle || defaultTitle} />;
};
