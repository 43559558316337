import React, { useContext } from 'react';
import List from 'client/img/mode-list.svg';
import Kanban from 'client/img/mode-kanban.svg';
import Tiles from 'client/img/mode-tiles.svg';
import { MODES } from 'client/constants';
import './style.scss';

const ModeContext = React.createContext(null);

const makeModeIcon = (Component) => {
	const ModeIcon = () => {
		const { mode, onChange } = useContext(ModeContext);
		const componentModeName = Component.displayName;
		const onClick = () => onChange(componentModeName);
		return <Component className={mode === componentModeName ? 'active' : ''} onClick={onClick} />;
	};

	return ModeIcon;
};

export const Mode = ({ mode, onChange, children }) => {
	return (
		<ModeContext.Provider value={{ mode, onChange }}>
			<div className='modes'>{children}</div>
		</ModeContext.Provider>
	);
};

List.displayName = MODES.LIST;
Kanban.displayName = MODES.KANBAN;
Tiles.displayName = MODES.TILES;

Mode.List = makeModeIcon(List);
Mode.Kanban = makeModeIcon(Kanban);
Mode.Tiles = makeModeIcon(Tiles);

