import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { AddCircleButton, DeleteIconButton } from 'components';
import { RecordSelect, NumberInput, Button, Table, Column } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';

@observer
export class MaterialsTable extends Component {
	@observable materials = [];
	@observable isAdding = false;

	newMaterial = null;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.materials = await store.model.ProcessMaterial.find({
			where: {
				processId: this.props.process.id,
			},
			include: ['material'],
		});
	};

	onChange = (prop, record) => (value) => {
		if (prop === 'material') {
			record.materialId = value.id;
			record.material = value;
		} else if (prop === 'quantity') {
			record[prop] = value;
		}
	};

	onSave = async () => {
		await this.newMaterial.save();
		this.isAdding = false;
	};

	onAdd = async () => {
		this.isAdding = true;
		this.newMaterial = new store.model.ProcessMaterial({
			processId: this.props.process.id,
			techRoadMapId: this.props.process.techMapId,
		});
		this.materials.push(this.newMaterial);
	};

	onCancel = () => {
		this.materials.pop();
		this.isAdding = false;
	};

	renderMaterial = ({ record }) => {
		if (!record.id) {
			return (
				<RecordSelect
					model={store.model.Material}
					property='name'
					value={record.material}
					onChange={this.onChange('material', record)}
					placeholder={t('techRoadMap.material')}
				/>
			);
		} else {
			return <div className='string-value'>{record.material?.name}</div>;
		}
	};

	renderQuantity = ({ record }) => {
		if (!record.id) {
			return (
				<NumberInput
					integerOnly
					positiveOnly
					value={record.quantity}
					onChange={this.onChange('quantity', record)}
					disabled={record.id ? true : false}
					placeholder={t('techRoadMap.quantity')}
				/>
			);
		} else {
			return <div className='string-value'>{record.quantity}</div>;
		}
	};

	renderNumber = (r, row) => row + 1;

	renderActions = ({ record }) => {
		if (!record.id) {
			return (
				<>
					<Button variant='primary' onClick={this.onSave} disabled={!record.materialId || !record.quantity}>
						{t('add')}
					</Button>
					<Button onClick={this.onCancel}>{t('cancel')}</Button>
				</>
			);
		} else {
			return <DeleteIconButton noBorder onConfirm={() => this.renderDeleteButton(record)} />;
		}
	};

	renderDeleteButton = async (record) => {
		const index = this.materials.findIndex((item) => item.id === record.id);
		await record.delete();
		if (index !== -1) this.materials.splice(index, 1);
	};

	render() {
		return (
			<div className={this.props.className}>
				<Table rows={this.materials}>
					<Column computed={this.renderNumber} label='№' className='materials-number' />
					<Column relation='material' property='name' label={t('techRoadMap.material')}>
						{(record) => this.renderMaterial(record)}
					</Column>
					<Column property='quantity' label={t('techRoadMap.quantity')} className='materials-quantity'>
						{(data) => this.renderQuantity(data)}
					</Column>
					<Column className='materials-actions'>{this.renderActions}</Column>
				</Table>
				<AddCircleButton onClick={this.onAdd} disabled={this.isAdding} />
			</div>
		);
	}
}

