import React from 'react';
import ReactECharts from 'echarts-for-react';
import { getChartOptions, getPieOptions } from './options';
import { inject, observer } from 'mobx-react';
import { COLORS, COSTS, INCOME } from '../constants';
import store from 'client/store';
import t from 'i18n';

const _Charts = ({ store: _store }) => {
	const { pieCharts, chartRecords, isDashboardFetching } = _store;
	if (isDashboardFetching) return <div className='charts-loading' />;

	const getTitle = (title) => t('payment.' + title.toLowerCase());
	const chartOptions = getChartOptions(chartRecords);
	const incomePie = getPieOptions(pieCharts[INCOME], { color: COLORS.GREEN, title: getTitle(INCOME) });
	const costsPie = getPieOptions(pieCharts[COSTS], { color: COLORS.RED, title: getTitle(COSTS) });

	return (
		<div className='charts' id='charts'>
			<div className='chart'>
				<ReactECharts option={chartOptions} theme={store.local.isDarkMode ? 'dark' : ''} />
			</div>
			<div className='pie'>
				<ReactECharts option={incomePie} theme={store.local.isDarkMode ? 'dark' : ''} />
			</div>
			<div className='pie'>
				<ReactECharts option={costsPie} theme={store.local.isDarkMode ? 'dark' : ''} />
			</div>
		</div>
	);
};
export const Charts = inject('store')(observer(_Charts));

