import { Column, Pager, Table } from '@smartplatform/ui';
import React from 'react';
import t from 'i18n';
import { COSTS, INCOME, TOTAL_COLORS, TRANSFER } from '../constants';
import { inject, observer } from 'mobx-react';
import './style.scss';
import { renderMoney, renderBankAccount, declinationOfNumber } from 'client/tools';
import { observable } from 'mobx';
@inject('store')
@observer
export class List extends React.Component {
	@observable expandedRecords = [];
	renderTotal = ({ paymentType, total, currencyName }) => {
		const { color, prependSymbol } = TOTAL_COLORS[paymentType] || TOTAL_COLORS['default'];
		return (
			<span style={{ color }}>
				{total ? prependSymbol : ''}
				{renderMoney(total, 2)}
				{/* {currencyName} */}
			</span>
		);
	};

	getDeclinationArray = (name) => [1, 2, 5].map((number) => t('declinationOfNumber.' + name + number));
	renderChildrenRelation = (record, field, wordsArray) => {
		const children = record.children();
		const isNoChildren = !children.length;
		if (isNoChildren) return record[field]?.name;
		const names = new Set([...children, record].map((i) => i[field]?.name).filter(Boolean));
		if (names.size) {
			return (
				<div className='box-label'>
					{names.size} {declinationOfNumber(names.size, wordsArray)}
				</div>
			);
		}
	};

	renderContragent = (record) => {
		const { paymentType, recipient, payer } = record;
		if ([INCOME, COSTS].includes(paymentType) && recipient && payer) {
			const isContragentPayer = !payer.myCompany;
			const bankAccount = isContragentPayer ? 'payer' : 'recipient';
			return this.renderChildrenRelation(record, bankAccount, this.getDeclinationArray('contragent'));
		}
	};
	renderBankAccount = ({ paymentType, payerBankAccount, recipientBankAccount }) => (
		<div>
			{[TRANSFER, COSTS].includes(paymentType) && renderBankAccount(payerBankAccount)}
			{[TRANSFER, INCOME].includes(paymentType) && renderBankAccount(recipientBankAccount)}
		</div>
	);

	renderProject = (record) => this.renderChildrenRelation(record, 'project', this.getDeclinationArray('project'));
	renderCashFlow = (record) => {
		if (record.paymentType === TRANSFER) return t('payment.transferCashFlow');
		return this.renderChildrenRelation(record, 'cashFlow', this.getDeclinationArray('cashFlow'));
	};

	expandPayment = (record) => {
		if (!record.parentId) {
			const expandRecordIndex = this.expandedRecords.findIndex((id) => id === record.id);
			expandRecordIndex === -1 ? this.expandedRecords.push(record.id) : this.expandedRecords.splice(expandRecordIndex, 1);
		}
	};

	renderExpand = ({ record }) => {
		if (record.parentId) return <span className='empty' />;
		let content;
		if (record.children().length) {
			content = <span className='expand'>{this.expandedRecords.findIndex((id) => id === record.id) === -1 ? '+' : '-'}</span>;
		}

		return content;
	};

	get records() {
		const records = this.props.store.records.slice(0);
		records.forEach((record) => {
			if (this.expandedRecords.includes(record.id)) {
				const index = records.findIndex(({ id }) => id === record.id);
				const children = record.children();
				records.splice(index + 1, 0, ...children);
			}
		});
		return records;
	}

	render() {
		let { onChange, page, onRowClick, perPage, isRecordsFetching, records } = this.props.store;
		let content;

		if (!isRecordsFetching) {
			content = records.length ? (
				<>
					<Table rows={this.records} onRowClick={onRowClick}>
						<Column width={20} onClick={this.expandPayment}>
							{this.renderExpand}
						</Column>
						<Column property='date' label={t('date')} width={100} />
						<Column property='total' label={t('payment.total')} computed={this.renderTotal} width={100} />
						<Column computed={this.renderBankAccount} label={t('payment.bankAccount')} />
						<Column computed={this.renderCashFlow} label={t('cashFlow.shortTitle')} />
						<Column computed={this.renderContragent} label={t('contragent.title')} />
						<Column computed={this.renderProject} label={t('project.title')} />
					</Table>
				</>
			) : (
				<></>
			);
		}
		return (
			<div className='table'>
				{records.totalCount === 0 && <span className='not-found'>{t('operation.notFound')}</span>}
				<div id='operations-table'>{content}</div>
				<Pager current={page} totalCount={records.totalCount || 0} onChange={onChange('page')} itemsPerPage={perPage} noCount />
			</div>
		);
	}
}

