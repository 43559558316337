import { DndContext } from '@smartplatform/ui';
import 'bootstrap-4-grid/scss/grid.scss';
import { observer } from 'mobx-react';
import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import Layout from './layout';
import { Login, Register, Restore, Reset } from 'components/auth';
import PAGES from './pages';
import store from './store';
import './styles/main.scss';
import { Notifications, Dictionary } from 'components';
import { DICTIONARIES } from './dictionaries';
import { getAvailableModules } from 'client/tools';
import { AuthLayout } from './components/auth/components/auth-layout';

const App = () => {
	if (!store.model.isInitialized) return null;
	if (!store.model.isAuthorized) {
		return (
			<Router history={store.history}>
				<AuthLayout>
					<div className='auth'>
						<Switch>
							{store.model.User.INFO.CREATE && <Route path='/register' component={Register} />}
							<Route path='/restore' component={Restore} />
							<Route path='/login' component={Login} />
							<Route path='/reset-password/:token' component={Reset} />
							<Redirect from='/*' to='/login' />
						</Switch>
					</div>
				</AuthLayout>
			</Router>
		);
	}

	const className = 'project-vars';

	const modulesRoutes = [];
	for (let module of getAvailableModules()) {
		const Module = module.component;
		modulesRoutes.push(<Module key={module.code} />);
	}

	return (
		<DndContext className={className}>
			<Router history={store.history}>
				<Layout>
					{!!store.ui.notifications.length && <Notifications />}
					{modulesRoutes}
					<Switch>
						<Route path='/' exact>
							<Redirect to='/management/tasks' />
						</Route>
						<Route path='/management/' exact>
							<Redirect to='/management/tasks' />
						</Route>
						{PAGES.map(({ component: Page, path }, i) => {
							return <Route key={path} path={path} render={(props) => <Page {...props} path={path} />} />;
						})}
						{DICTIONARIES.map((dictionaryProps, i) => (
							<Route
								key={i}
								path={dictionaryProps.path || '/' + dictionaryProps.modelName.toLowerCase() + 's'}
								render={(routeProps) => <Dictionary {...dictionaryProps} {...routeProps} />}
							/>
						))}
					</Switch>
				</Layout>
			</Router>
		</DndContext>
	);
};

export default observer(App);

