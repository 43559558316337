import React from 'react';
import { Filters } from './Filters';
import { Toolbar as _Toolber, ChartButton } from 'components';
import t from 'i18n';
import { inject, observer } from 'mobx-react';
@inject('store')
@observer
export class Toolbar extends React.Component {
	render() {
		const { toggleProp, onSearch, records, search, showCharts } = this.props.store;

		return (
			<div className='toolbar'>
				<_Toolber>
					<_Toolber.CreateBtn text={t('operation.new')} onClick={() => toggleProp('showEditPopup')(true)} />
					<_Toolber.Search inputValue={search} onSearch={onSearch} />
					<_Toolber.ExportBtn onClick={() => {}} />
					<_Toolber.Count text={t('operation.total')} count={records.totalCount || 0} />
				</_Toolber>
				<Filters />
				<div className='action-bar'>
					<ChartButton onClick={toggleProp('showCharts')} className={showCharts ? 'active' : undefined} text={t('chart')} />
				</div>
			</div>
		);
	}
}
