import { Field, RecordSelect, Row } from '@smartplatform/ui';
import { inject, observer } from 'mobx-react';
import React from 'react';
import t from 'i18n';
import store from 'client/store';
import './style.scss';
import { renderBankAccount } from 'client/tools';
import { Attachments, Form } from 'components';
@inject('editStore')
@observer
export class Transfer extends React.Component {
	render() {
		const { onChange, record, myCompanyBankAccount, contragentBankAccount, beforeSave, onDelete, afterSave, onClose } =
			this.props.editStore;
		const showValue = (placeholder, value) => value || <span className='placeholder'>{placeholder}</span>;
		const disableSave = !record.date || !record.total || !myCompanyBankAccount || !contragentBankAccount;
		return (
			<Form
				record={record}
				beforeSave={beforeSave}
				onSave={afterSave}
				onDelete={onDelete}
				stay
				disableSave={disableSave}
				onCancel={onClose}
				deleteTitle={t('payment.delete')}
			>
				<Row>
					<Field property='date' label={t('payment.date')} isRequired />
					<Field property='total' label={t('payment.total')} isRequired />
				</Row>
				<Row>
					<div className='form-field'>
						<label className='required'>{t('payment.bankAccountFrom')}</label>
						<RecordSelect
							model={store.model.ViewBankAccount}
							value={myCompanyBankAccount}
							onChange={onChange('myCompanyBankAccount')}
							filter={{
								where: { myCompany: true, id: contragentBankAccount ? { neq: contragentBankAccount.id } : undefined },
								include: ['contragent'],
							}}
							showValue={showValue(t('payment.selectBankAccount'), renderBankAccount(myCompanyBankAccount))}
							computed={renderBankAccount}
							isRequired
						/>
					</div>
					<div className='form-field'>
						<label className='required'>{t('payment.bankAccountTo')}</label>
						<RecordSelect
							model={store.model.ViewBankAccount}
							value={contragentBankAccount}
							onChange={onChange('contragentBankAccount')}
							filter={{
								where: { myCompany: true, id: myCompanyBankAccount ? { neq: myCompanyBankAccount.id } : undefined },
								include: ['contragent'],
							}}
							showValue={showValue(t('payment.selectBankAccount'), renderBankAccount(contragentBankAccount))}
							computed={renderBankAccount}
							isRequired
						/>
					</div>
				</Row>
				<Row>
					<Field property='description' label={t('description')}>
						<textarea rows={3} />
					</Field>
					<div />
				</Row>

				<Attachments record={record} canUpload={true} withFormContext />
			</Form>
		);
	}
}

