import React, { Component } from 'react';
import { Field, Row, Button, RecordSelect } from '@smartplatform/ui';
import store from 'client/store';
import { ModelEdit } from 'components';
import t from 'i18n';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

@observer
export class EquipmentEdit extends Component {
	@observable record = null;

	constructor(props) {
		super(props);
	}

	getRecord = (record) => {
		this.record = record;
	};

	back = () => store.route.history.goBack();

	render() {
		const { modelName, path, match, title, relation, relationLabel } = this.props;
		const id = parseInt(match.params.id);

		return (
			<ModelEdit
				model={store.model[modelName]}
				path={path}
				id={id}
				getRecord={this.getRecord}
				title={title}
				disableSave={!this.record?.name}
				filter={{ include: [relation] }}
			>
				<Row>
					<Field property='name' label={t('name')} />
					<Field relation={relation} property='name' label={t(relationLabel)} >
						<RecordSelect />
					</Field>
				</Row>
				<Row>
					<Field property='info' label={t('information')} />
					<Field property='investmentNumber' label={t('process.equipment.invNumber')} />
				</Row>
			</ModelEdit>
		);
	}
}
