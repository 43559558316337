import { action, observable } from 'mobx';
import t from 'i18n';
import { v4 as uuid } from 'uuid';

export default class UI {
	@observable title = '';
	@observable menu = false;
	@observable activeSubMenu = null;
	@observable kanban = false;
	@observable notifications = [];

	constructor(store) {
		this.store = store;
	}

	setNotification = (notification) => {
		if (notification) {
			const { message, error } = notification;
			this.notifications.unshift({ message, error, id: uuid() });
		}
	};

	removeNotification = () => {
		this.notifications.pop();
	};
}

