import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import { Desktop } from '../../components';
import store from 'client/store';
import User from './User';
import './style.scss';

import Logo from 'client/img/sp-logo-new.svg';
import LogoSmart from 'client/img/SMART-logo.svg';
import LogoErp from 'client/img/ERP-logo.svg';

@observer
export default class Header extends React.Component {
	constructor(props) {
		super(props);
	}

	toggleMenu = () => {
		store.ui.menu = !store.ui.menu;
	};

	render() {
		return (
			<header className='topbar'>
				<div className='header'>
					<div className='mobile-menu' onClick={this.toggleMenu}>
						<FontAwesomeIcon icon={faBars} />
					</div>
					<div className='title'>
						<div className='logo'>
							<Link to='/'>
								<Logo className='logo-icon' />
								<LogoSmart />
								<LogoErp className='logo-erp' />
							</Link>
						</div>
					</div>
				</div>
				<div className='toolbar'>
					<Desktop>
						<div className='toolbar-left'>{store.ui.title || ''}</div>
					</Desktop>
					{store.model.isAuthorized && (
						<div className='toolbar-right'>
							<User />
						</div>
					)}
				</div>
			</header>
		);
	}
}

