import React from 'react';
import { observer } from 'mobx-react';
import { renderProject, renderPlaceholder } from 'client/tools';
import store from 'client/store';
import t from 'i18n';
import { toJS } from 'mobx';
import { RecordSelect } from './RecordSelect';

const DEFAULT_FILTER = {
	include: [{ relation: 'projectList', scope: { fields: ['id', 'color'] } }],
	fields: ['id', 'name', 'projectListId'],
	order: 'name asc',
};

@observer
export class ProjectSelect extends React.Component {
	static defaultProps = {
		filter: DEFAULT_FILTER,
		searchPlaceholder: t('search'),
		showClearButton: true,
	};

	get showValue() {
		const { value, computed = renderProject } = this.props;

		if (Array.isArray(toJS(value))) {
			if (value.length === 1) {
				return computed(value[0]);
			}
			if (value.length > 1) {
				return `${t('payment.selectedProjects')}: ${value.length}`;
			}
			return renderPlaceholder(t('payment.allProjects'));
		}
		return computed(value);
	}
	render() {
		const { computed, model, showValue, maxItems, itemsPerPage, ...restProps } = this.props;

		return (
			<RecordSelect
				itemsPerPage={itemsPerPage || store.maxSelectItems}
				maxItems={maxItems || store.maxSelectItems}
				model={model || store.model.Project}
				computed={computed || renderProject}
				showValue={showValue || this.showValue}
				{...restProps}
			/>
		);
	}
}

