import React from 'react';
import PropTypes from 'prop-types';

import { Avatar , Popover} from '@smartplatform/ui';
import { fioOrEmpty, fioShortOrEmpty} from 'client/tools';
import { TruncateText } from '../text';
import classNames from "classnames";
import './style.scss';

const User = (props) => {
	const { user, size, full, text, popoverFullName, popoverProps } = props;

	const renderUserText = () => {
		let _content = text;

		if (!_content) {
			_content = full ? fioOrEmpty(user): fioShortOrEmpty(user);

			if (!_content) {
				_content = props.user.email || `userId: ${user.id}`;
			}
		}

		return _content;
	};

	let userText = renderUserText();

	const contentRender = (
		<div className='avatar-fio'>
			<Avatar user={user} size={size}/>
			<TruncateText className='fio'>{userText}</TruncateText>
		</div>
	);

	const contentPopover = fioOrEmpty(user);

	if (!popoverFullName || !contentPopover) {
		return contentRender;
	}
	else{

		const props = {
			...popoverProps,
			className: classNames('wrapper-popover-user', popoverProps?.className)
		}
		return(
			<Popover content={contentPopover} {...props}>
				{contentRender}
			</Popover>
		);
	}
};

User.propTypes = {
	user: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	size: PropTypes.number,
	full: PropTypes.bool,
	popoverFullName: PropTypes.bool,
	popoverProps: PropTypes.object
};

User.defaultProps = {
	size: 22,
	popoverFullName: true,
};

export default User;

