import MODULES from 'client/modules';
import store from 'client/store';

export function getAvailableModules() {
	const availableModules = [];
	const configModules = store.model.config.modules || [];
	for (let module of Object.values(MODULES)) {
		if (!configModules.includes(module.code)) continue;
		if (module.enabled === false) continue;
		if (typeof module.enabled === 'function' && !module.enabled(store)) continue;
		availableModules.push(module);
	}
	// console.log('availableModules', availableModules.map(m => m.code));
	return availableModules;
}

