import React from 'react';
import { observer } from 'mobx-react';

import MenuItem from './MenuItem';
import SubMenu from './SubMenu';
import ModuleMenu from './ModuleMenu';
import items from './config';
import store from 'client/store';
import { getAvailableModules } from 'client/tools';
import './style.scss';

@observer
export default class Menu extends React.Component {
	componentDidMount() {
		store.subscribe('history', this.onRouteChange);
	}

	componentWillUnmount() {
		store.unsubscribe('history', this.onRouteChange);
	}

	onRouteChange = (e) => {
		// console.log('onRouteChange', e);
		store.ui.menu = false;
	};

	render() {
		// новое меню, основанное на подключенных модулях, заменит собой текущее меню
		const modulesMenu = [];
		for (let module of getAvailableModules()) {
			modulesMenu.push(<ModuleMenu key={module.code} module={module} />);
		}

		// текущее меню
		const menu = items.map((item, i) => {
			if (item.type === 'submenu') {
				if (item.enabled && !item.enabled(store)) return null;
				let visibleCount = item.items.length;
				item.items.forEach((item) => {
					if (item.enabled && !item.enabled(store)) visibleCount--;
				});
				if (visibleCount === 0) return null;
				return <SubMenu key={item.id} title={item.label} items={item.items} code={item.id} />;
			}
			return <MenuItem key={'root' + i} item={item} />;
		});

		return (
			<div className='menu' ref={this.onMount}>
				{modulesMenu}
				{menu}
			</div>
		);
	}
}

