import React from 'react';
import store from 'client/store';
import { User, BoxLabel, TruncateText } from 'components';
import t from 'i18n';
import './style.scss';

const defautColor = 'grey';

export const renderUser = (user, size = 24, text) => {
	return user ? <User user={user} size={size} text={text} /> : '-';
};

export const renderStatus = (boardList) =>
	boardList && (
		<div className='render-status'>
			<span className='color' style={{ background: boardList.color || defautColor }} />
			<TruncateText>{boardList.name}</TruncateText>
		</div>
	);

export const renderProject = (project) =>
	project && (
		<div className='render-project'>
			<span className='color' style={{ background: project.projectList?.color || defautColor }} /> {project.name}
		</div>
	);

export const renderList = (list) => (
	<div className='render-order'>
		<span className='color' style={{ background: list.color }} /> {list.name}
	</div>
);

export const renderBankAccount = (bankAccount) => bankAccount && <div>{bankAccount.name || bankAccount.checkingAccount}</div>;

export const renderMoney = (sum = 0, fixed = 0, local = 'ru-RU') => {
	sum = Number(sum);
	if (!sum) sum = 0;
	console.assert(typeof sum === 'number', 'wrong renderMoney sum argument type');
	if (!Number.isInteger(sum)) sum = Number(sum.toFixed(fixed)).toLocaleString();
	return sum;
};
export const renderPlaceholder = (placeholder) => placeholder && <span className='placeholder'>{placeholder}</span>;

export const renderLabelItem = (label, closeCallback) => <BoxLabel label={label} closeCallback={closeCallback} />;

