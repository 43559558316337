import React, { Component } from 'react';
import { Column, Table, Pager } from '@smartplatform/ui';
import { observer, inject } from 'mobx-react';
import store from 'client/store';
import { NOMENCLATURE_FILTER, NOMENCLATURE_MODEL, NOMENCLATURE_PATH } from './constants';
import { ListStore } from 'components/stores';
import t from 'i18n';

@inject('store')
@observer
export class NomenclatureRemains extends Component {

	constructor(props) {
		super(props);
		this.model = store.model[NOMENCLATURE_MODEL];
		this.store = new ListStore({ filters: this.filters, model: this.model, path: this.props.path });
		this.storeWarehouse = this.props.store;
		this.id = this.storeWarehouse.id;
		this.record = this.storeWarehouse.record;
		store.ui.title = this.record?.name ? `${t('warehouse.nomenclatureRemains')} ${this.record.name}` : t('warehouse.new');
	}

	get filters() {
		const filters = { ...NOMENCLATURE_FILTER };

		const where = { and: [] };
		if (this.id) {
			where.and.push({ warehouses: { ilike: `%"id": "${this.id}"%` } });
			where.and.push({ total: { gt: 0 } });
		}

		filters.where = where;
		return filters;
	}

	componentDidMount() {
		this.store.setPerPage();
		this.store.updateFilters(this.filters);
	}

	getTotal = (r) => {
		r = JSON.parse(r.warehouses).filter((r) => r.total > 0 && r.id === this.id);
		return r.length ? r[0].total : 0;
	}

	onRowClick = (record) => console.log(record) || store.route.push({ path: `${NOMENCLATURE_PATH}/${record.id}` });

	render() {
		const { tableProps, page, totalCount, onChange: listStoreOnChange, perPage } = this.store;

		return (
			<div className='nomenclature-list'>
				<div id='table'>
					<Table {...tableProps} onRowClick={this.onRowClick}>
						<Column property='name' label={t('nomenclature.title')} />
						<Column computed={this.getTotal} label={t('nomenclature.quantity')} />
						<Column relation='measure' property='name' label={t('measure.title')} />
					</Table>
				</div>
				<Pager current={page} totalCount={totalCount || 0} onChange={listStoreOnChange('page')} itemsPerPage={perPage} noCount />
			</div>
		);
	}
}

