import tinymce from 'tinymce/tinymce';

export default function () {
	tinymce.PluginManager.add('youtube', function (editor, url) {
		const getYoutubeId = (url) => {
			const match1 = url.match(/https:\/\/youtu.be\/(.+)/);
			if (match1) {
				return match1[1];
			}
			const match2 = url.match(/https:\/\/www.youtube.com\/watch\?v=([^&]+)/);
			if (match2) {
				return match2[1];
			}
			return null;
		};

		const openDialog = function () {
			return editor.windowManager.open({
				title: 'Youtube video',
				body: {
					type: 'panel',
					items: [
						{
							type: 'input',
							name: 'url',
							label: 'Ссылка, например:<br />https://www.youtube.com/watch?v=Ya_4rht4DG8,<br />https://youtu.be/Ya_4rht4DG8:',
						},
					],
				},
				buttons: [
					{
						type: 'cancel',
						text: 'Close',
					},
					{
						type: 'submit',
						text: 'Save',
						primary: true,
					},
				],
				onSubmit: function (api) {
					var data = api.getData();
					const id = getYoutubeId(data.url);
					console.log('getYoutubeId', id, data.url);
					if (id) {
						editor.insertContent(
							`<iframe width="560" height="315" src="https://www.youtube.com/embed/${id}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
							{ format: 'raw' }
						);
					} else {
						editor.insertContent('Некорректная youtube-ссылка: ' + data.url, { format: 'raw' });
					}
					api.close();
				},
			});
		};

		// Add a button that opens a window
		editor.ui.registry.AddCircleButton('youtube', {
			text: 'Youtube',
			onAction: function () {
				// Open window
				openDialog();
			},
		});

		// Adds a menu item, which can then be included in any menu via the menu/menubar configuration
		editor.ui.registry.addMenuItem('youtube', {
			text: 'Youtube video',
			onAction: function () {
				// Open window
				openDialog();
			},
		});

		return {
			getMetadata: function () {
				return {
					name: 'Example plugin',
					url: 'http://exampleplugindocsurl.com',
				};
			},
		};
	});
}

