import React from 'react';
import { faFolder, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { default as translate } from 'i18n';
import { IconTitle } from 'components';
import Projects from './projects';
import { DocumentEdit } from './edit';

const path = '/documents';

const t = (slug) => translate(`document.card.tabs.${slug}`);

export const tabs = (recordId) => [
	{
		title: <IconTitle faIcon={faInfoCircle} text={t('info')} />,
		path: `${path}/:documentId`,
		exact: true,
		isVisible: true,
		component: (props) => <DocumentEdit {...props} path={path} />,
	},
	{
		title: <IconTitle faIcon={faFolder} text={t('projects')} />,
		path: `${path}/:documentId/projects`,
		isVisible: !!recordId,
		component: (props) => <Projects {...props} />,
	},
];

