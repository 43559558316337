import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import './iconTitle.scss';

export const IconTitle = ({ icon, faIcon, text, sizeIcon, className, children }) => {
	if (faIcon) {
		icon = <FontAwesomeIcon icon={faIcon} size={sizeIcon || null} />;
	}
	const _className = classNames('icon-title', className);
	return (
		<span className={_className}>
			{icon}
			{children}
			{text}
		</span>
	);
};

