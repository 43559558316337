import React from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';

import {Avatar, NumberValue, ColorUtils, Popover} from '@smartplatform/ui';
import {fio, relative, renderFaIcons} from 'client/tools';
import { PATH } from 'client/modules/sales/constants';
import store from 'client/store';
import t from 'i18n';
import { TruncateText } from 'components';

@inject('store')
@observer
export default class OrderTile extends React.Component {
	constructor(props) {
		super(props);
	}

	gotoOrder = () => {
		store.route.push({ path: `${PATH}/orders/${this.props.order.id}` });
	};

	render() {
		const { order, store } = this.props;
		const { isManagerOrAdmin } = store;

		const _positions = order.positions();

		const listStyle = {
			background: order.list?.color || '#ddd',
			color: order.list?.color ? ColorUtils.textColor(order.list.color, ColorUtils.formula(0)) : '#000',
		};

		return (
			<div className='order-tile' onClick={this.gotoOrder}>
				<div className='top'>
					<div className='id'>
						#{order.id}
					</div>
					<div className='updated'>
						{ order.comment ?
								<Popover content={order.comment} offset={0} contentClassName='popover-order-comment' trigger='hover' position='bottom'>
									{renderFaIcons.comment}
								</Popover>
								: ''
						}
						{relative(order.updatedAt)}
					</div>
				</div>
				<div className='main'>
					{order.manager &&
						<Popover content={fio(order.manager)} trigger='hover' position='bottom'>
							<Avatar className='manager' user={order.manager} />
						</Popover>
					}
					{order.description && <TruncateText className='name'>{order.description}</TruncateText>}
					<div className='contragent'>
						{t('client.title')}:
						{
							order.contragentName || order.clientName ?
								<Popover content={order.contragentName || order.clientName} trigger="hover" position='bottom'>
									{order.contragentName || order.clientName}
								</Popover>
							: <em> -</em>
						}

					</div>
					<div className='paidStatus'>
						{t('order.paidStatus')}: <em>{order.paid ? 'оплачен' : 'не оплачен'}</em>
					</div>
					<div className='list'>
						<span style={listStyle}>{order.list?.name}</span>
					</div>
					{_positions.length > 0 && (
						<div className='positions'>
							{_positions.slice(0, 2).map((position, i) => (
								<div key={position.id} className='position'>
									<TruncateText className='category'>
										{i + 1}. {position.category.name}
									</TruncateText>
									<div className='amount'>{position.amount} шт.</div>
									{isManagerOrAdmin && (
										<div className='price'>
											<NumberValue value={position.price} type='currency' />
										</div>
									)}
								</div>
							))}
							{_positions.length > 2 && <div>...</div>}
						</div>
					)}
					{_positions.length > 0 && (
						<div className='total'>
							{isManagerOrAdmin && (
								<div className='price'>
									{t('order.totalShort')}:{' '}
									<em>
										<NumberValue value={order.total} type='currency' />
									</em>
								</div>
							)}
							<div className='positions-count'>
								{t('order.positionsCount')}: {_positions.length}
							</div>
						</div>
					)}
				</div>
			</div>
		);
	}
}

