import { List } from './List';
import { Edit } from './Edit';
import React from 'react';
import { Route, Switch } from 'react-router';
import store from 'client/store';

export const Dictionary = (props) => {
	const { path } = props.match;

	const { listPropeties, listRelations, editRelations, editProperties, queryPath, WrapperEdit, ...restProps } = props || {};

	const listProps = {
		listPropeties,
		listRelations,
		...restProps,
	};

	const editProps = {
		editRelations,
		editProperties,
		disabled: !store.isAdmin || !store.model[restProps.modelName]?.INFO.WRITE,
		...restProps,
	};

	return (
		<Switch key={restProps.modelName}>
			<Route path={path} exact children={() => <List path={path} {...listProps} />} />
			<Route
				path={`${path}/:id`}
				render={({ match }) => (
					<ConditionalWrapper Wrapper={WrapperEdit} modelName={restProps.modelName} queryPath={queryPath}>
						{(wrapperData) => <Edit {...editProps} path={path} match={match} {...wrapperData} />}
					</ConditionalWrapper>
				)}
			/>
		</Switch>
	);
};

const ConditionalWrapper = ({ Wrapper, children, ...restProps }) =>
	Wrapper ? <Wrapper {...restProps}>{children}</Wrapper> : <>{children()}</>;

