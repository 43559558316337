import { Checkbox, DatePicker, Field, NumberInput, RecordSelect, Row } from '@smartplatform/ui';
import { inject, observer } from 'mobx-react';
import React from 'react';
import t from 'i18n';
import store from 'client/store';
import { TRANSFER } from '../constants';
import { renderBankAccount } from 'client/tools';
import { SplitPaymentTable } from './SplitPaymentTable';
import { Attachments, Form } from 'components';

@inject('editStore')
@observer
export class Payment extends React.Component {
	render() {
		const {
			onChange,
			record,
			beforeSave,
			myCompanyBankAccount,
			contragent,
			project,
			direction,
			cashFlow,
			onDelete,
			afterSave,
			hasWrongChild,
			onClose,
		} = this.props.editStore;

		const { isSplitPayment, onIsSplitPaymentChange } = this.props.editStore;
		const showValue = (placeholder, value) => value || <span className='placeholder'>{placeholder}</span>;

		const disableSave =
			!record.date ||
			!record.total ||
			!myCompanyBankAccount ||
			((!contragent || !cashFlow) && !isSplitPayment) ||
			(isSplitPayment && hasWrongChild);
		return (
			<Form
				record={record}
				beforeSave={beforeSave}
				stay
				disableSave={disableSave}
				onDelete={onDelete}
				onSave={afterSave}
				onCancel={onClose}
				deleteTitle={t('payment.delete')}
			>
				<div className='main'>
					<Row>
						<Field property='date' label={t('payment.date')} isRequired>
							<DatePicker showClearButton={false} />
						</Field>
						<div />
					</Row>
					<Row>
						<Field property='total' label={t('payment.total')} isRequired disabled={isSplitPayment}>
							<NumberInput positiveOnly className={!record.total && !isSplitPayment ? 'wrong-total' : undefined} />
						</Field>

						<div className='form-field'>
							<label className='required'>
								{this.paymentType === TRANSFER ? t('payment.bankAccountFrom') : t('payment.bankAccount')}
							</label>
							<RecordSelect
								model={store.model.ViewBankAccount}
								value={myCompanyBankAccount}
								onChange={onChange('myCompanyBankAccount')}
								filter={{
									where: { myCompany: true },
									include: ['contragent'],
								}}
								showValue={showValue(t('payment.selectBankAccount'), renderBankAccount(myCompanyBankAccount))}
								computed={renderBankAccount}
								isRequired
							/>
						</div>
					</Row>
					{!record.parentId && (
						<Row className='split'>
							<div className='form-field'>
								<label>{t('payment.splitTotal')}</label>
								<Checkbox value={isSplitPayment} onChange={onIsSplitPaymentChange} />
							</div>
						</Row>
					)}
				</div>
				{isSplitPayment ? (
					<SplitPaymentTable />
				) : (
					<>
						<Row>
							<div className='form-field'>
								<label className='required'>{t('contragent.title')}</label>
								<RecordSelect
									model={store.model.Contragent}
									property='name'
									value={contragent}
									onChange={onChange('contragent')}
									filter={{ where: { or: [{ myCompany: false }, { myCompany: null }] } }}
									showValue={showValue(t('payment.selectContragent'), contragent?.name)}
									isRequired
								/>
							</div>

							<div className='form-field'>
								<label className='required'>{t('cashFlow.shortTitle')}</label>
								<RecordSelect
									isRequired
									model={store.model.CashFlow}
									value={cashFlow}
									onChange={onChange('cashFlow')}
									property='name'
									showValue={showValue(t('cashFlow.selectCashFlow'), cashFlow?.name)}
								/>
							</div>
						</Row>
						<Row>
							<div className='form-field'>
								<label>{t('project.title')}</label>
								<RecordSelect
									model={store.model.Project}
									value={project}
									onChange={onChange('project')}
									property='name'
									showValue={showValue(t('payment.selectProject'), project?.name)}
								/>
							</div>
							<div className='form-field'>
								<label>{t('paymentDirection.title')}</label>
								<RecordSelect
									model={store.model.PaymentDirection}
									value={direction}
									property='name'
									showValue={showValue(t('payment.selectDirection'), direction?.name)}
									onChange={onChange('direction')}
								/>
							</div>
						</Row>
					</>
				)}
				<Row>
					<Field property='description' label={t('description')}>
						<textarea rows={3} />
					</Field>
					<div />
				</Row>
				<Attachments record={record} canUpload={true} withFormContext />
			</Form>
		);
	}
}

