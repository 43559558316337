import React from 'react';
import loadable from '@loadable/component';

export default [
	{ component: loadable(() => import('./acls')), path: '/acls' },
	{ component: loadable(() => import('./persons')), path: '/persons' },
	{ component: loadable(() => import('./dashboard')), path: '/dashboard' },
	{ component: loadable(() => import('./clients')), path: '/clients' },
	{ component: loadable(() => import('./profile')), path: '/profile' },
	{ component: loadable(() => import('./contragents')), path: '/contragents' },
	{ component: loadable(() => import('./contracts')), path: '/contracts' },
	{ component: loadable(() => import('./roles')), path: '/roles' },
	{ component: loadable(() => import('./users')), path: '/users' },
	{ component: loadable(() => import('./rolemappings')), path: '/rolemappings' },
	{ component: loadable(() => import('./app-settings')), path: '/app-settings' },
	{ component: loadable(() => import('./test')), path: '/test' },
];

