import React, { Component } from 'react';
import { Field, RecordSelect, Row, MaskedInput, Input } from '@smartplatform/ui';
import store from 'client/store';
import { Form, UserSelectOld } from 'components';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import t from 'i18n';

@inject('store')
@observer
export class WarehousesEdit extends Component {
	@observable record = null;
	@observable isLoading = true;
	@observable isSaving = false;

	constructor(props) {
		super(props);
		//this.id = props.match.params?.id;
		this.store = this.props.store;
		this.id = this.store.id;
		this.init();
	}

	init = async () => {
		this.isLoading = true;

		if (this.id) {
			this.record = await store.model.Warehouse.findById(this.id);
			store.ui.title = this.record.name;
			this.isSaving = true;
		} else {
			this.record = new store.model.Warehouse();
			store.ui.title = t('warehouse.new');
			this.isSaving = false;
		}

		this.isLoading = false;
	};

	back = () => store.route.push({ path: `/warehouses` });

	onSave = () => {
		if (!this.isSaving) {
			store.route.push({ path: `/warehouses/${this.record.id}` });
			this.isSaving = true;
			this.back();
		} else {
			store.route.push({ path: `/warehouses` });
		}
	};

	render() {
		if (this.isLoading) return 'Loading';

		if (!this.record) return t('warehouse.errors.notExist');
		const noDeleteIfExternalId = !!this.record?.externalId;

		return (
			<>
				<Form
					record={this.record}
					onCancel={this.back}
					deleteTitle={t('warehouse.remove')}
					onSave={this.onSave}
					onDelete={() => store.route.push({ path: '/warehouses' })}
					className='warehouse-create-form'
					stay
					disableSave={!this.record.name}
					noDelete={noDeleteIfExternalId}
				>
					<Row>
						<Field property='name' label={t('warehouse.warehouseTitle')} autoFocus={!this.record?.id} />
						<Field relation='contragent' property='name' label={t('warehouse.contragent')}>
							<RecordSelect model={store.model.Contragent} />
						</Field>
					</Row>
					<Row>
						<Field property='address' label={t('warehouse.address')} />
						<Field property='phone' label={t('warehouse.phone')}>
							<MaskedInput className='warehouse-create-form-phone' mask='+7 (999) 999 99-99' placeholder='Телефон' />
						</Field>
					</Row>
					<Row>
						<Field property='dopinfo' label={t('warehouse.dopinfo')}>
							<textarea rows='4' />
						</Field>
					</Row>
					<Row>
						<Field property='code' label={t('code')}>
							<Input placeholder={t('typeText')} />
						</Field>
					</Row>
					<Row>
						<Field relation='user' filter={{ order: 'lastName asc, firstName asc, username asc' }} label={t('executor')}>
							<UserSelectOld model={store.model.User} />
						</Field>
					</Row>
				</Form>
			</>
		);
	}
}

