import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Edit } from './Edit';
import { List } from './List';
import t from 'i18n';

import './process.scss';

const path = '/processes';
const title = t('process.plural');
const modelName = 'ProcessOperation';
const relation = 'equipment';
const relationLabel = t('process.equipment.title');

const Processes = () => {
	const props = { path, title, modelName, relation, relationLabel, createPath: path };

	return (
		<Switch>
			<Route path={path} exact children={({ match }) => <List match={match} {...props} />} />
			<Route path={`${path}/page/:page`} children={({ match }) => <List match={match} {...props} />} />
			<Route path={`${path}/:id`} children={({ match }) => <Edit match={match} {...props} />} />
		</Switch>
	);
};

export default Processes;
