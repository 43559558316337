import React from 'react';
import { observable, toJS } from 'mobx';
import { observer, inject } from 'mobx-react';
import {Pager, Table, Column, NumberValue, Popover} from '@smartplatform/ui';
import {getPerPage, initials, relative, renderList, fio, renderFaIcons} from 'client/tools';
import store from 'client/store';
import t from 'i18n';

import './orderList.scss';
import { DueDate } from 'components';

@inject('store')
@observer
export class OrdersList extends React.Component {
	@observable pageSize = 10;

	constructor(props) {
		super(props);
		this.store = props.store;
		this.store.isLoading = true;
	}
	componentDidMount() {
		this.props.store.setPerPage(getPerPage(54));
		this.props.store.fetchListRecords();
	}

	getInstance = (instance) => (this.table = instance);

	onRowClick = (order) => store.route.push({ path: `/sales/orders/${order.id}` });

	renderTotal = (record) =>
		record.total ? (
			<div>
				<NumberValue type='currency' value={record.total} />
				{record.paid && <div>{t('order.paid')}</div>}
			</div>
		) : (
			'-'
		);

	renderCategories = (positions) => {
		if (positions.length > 0) {
			const categoryNames = [...new Set(positions.map((pos) => pos.category?.name))];
			return (
				<>
					{categoryNames.map((name, i) => {
						const separator = i !== categoryNames.length - 1 ? ', ' : '';
						return `${name} ${separator}`
					})}
				</>
			);
		}
	};

	renderEndDate = (record) => (record.endDate ? <DueDate date={record.endDate} /> : '-');

	renderStartDate = (record) => relative(record.startDate);

	renderUserWithoutAvatar = (manager) => {
		return(
			<Popover content={fio(manager)} trigger='hover' position='bottom'>{initials(manager)}</Popover>
		)
	}

	renderCommentOrder = (order) => {
		if(!order.comment) return ' ';
		return(
			<Popover content={order.comment} offset={0} contentClassName='popover-order-comment' className='wrapper-popover-trigger-table' trigger='hover' position='left'>
				{renderFaIcons.comment}
			</Popover>
		)
	}

	renderContragent = (order) => {
		if(!order.contragentName && !order.clientName) return '-';
		return <Popover content={order.contragentName || order.clientName} trigger='hover' position='bottom'>{order.contragentName || order.clientName}</Popover>
	}

	render() {
		const { isManagerOrAdmin, records, onChange, page, onQueryUpdate, perPage } = this.store;

		const additionalColumns = [];
		if (isManagerOrAdmin)
			additionalColumns.push(
				<Column key={1} property='total' computed={this.renderTotal} label={t('order.price')} className='text-right' width={100} />
			);
		return (
			<div className='orders-list'>
				<div className='orders-list-wrapper'>
					<div id='table'>
						<Table rows={records} query={{}} onQueryUpdate={onQueryUpdate} onRowClick={this.onRowClick} getInstance={this.getInstance}>
							<Column property='id' label={t('order.n')} className='text-center number-order' width={60}/>
							<Column label={t('client.title')} computed={this.renderContragent} />
							<Column relation='list' computed={renderList} label={t('order.status')} />
							<Column relation='manager' computed={this.renderUserWithoutAvatar} label={t('order.manager')} />
							<Column relation='positions' computed={this.renderCategories} label={t('order.category')} width={150} />
							<Column property='invoiceNumber' label={t('order.invoiceNumber')} width={90} />
							<Column property='contractNumber' label={t('order.contractNumber')} width={97} />
							<Column property='startDate' label={t('order.startDate')} computed={this.renderStartDate} width={89} />
							{additionalColumns}
							<Column label='' computed={this.renderCommentOrder} width={20} />
						</Table>
						<Pager itemsPerPage={perPage} current={page} onChange={onChange('page')} totalCount={records.totalCount} noCount />
					</div>
				</div>
			</div>
		);
	}
}

