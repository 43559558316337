import React from 'react';
import t from 'i18n';

export const ORDERS_MODE_REPORT = 'report';

export const PAID_SELECT_ITEMS = [
	{ label: t('order.paid'), value: true },
	{ label: t('order.notPaid'), value: 'false' },
];

export const LOCAL_STORAGE_FIELDS = ['mode', 'hiddenLists'];

//*КАНБАН
export const ORDER_KANBAN_INCLUDE = [
	{ relation: 'client', scope: { fields: ['id', 'name'] } },
	{ relation: 'manager', scope: { fields: ['id', 'lastName', 'firstName', 'middleName', 'username', 'avatar'] } },
	{
		relation: 'positions',
		scope: {
			where: { categoryId: { neq: null } },
			include: [{ relation: 'category', scope: { fields: ['id', 'name'] } }],
			order: 'id asc',
		},
	},
	{
		relation: 'labels',
		scope: {
			fields: ['id', 'name', 'color'],
		},
	},
];
export const KANBAN_INITIAL_LIMIT = 20;

//*ЛИСТ
export const ORDER_LIST_INCLUDE = [{ relation: 'list', scope: { fields: ['id', 'color', 'name'] } }, ...ORDER_KANBAN_INCLUDE];

//*ПЛИТКА
export const ORDER_TILES_INCLUDE = ORDER_LIST_INCLUDE;
export const ORDER_TILES_SORTING_VARIANTS = [
	{ label: <>&uarr; {t('sorting.newFirst')}</>, value: 'id desc' },
	{ label: <>&darr; {t('sorting.oldFirst')}</>, value: 'id asc' },
	{ label: <>&uarr; {t('sorting.priceAsc')}</>, value: 'total desc' },
	{ label: <>&darr; {t('sorting.priceDesc')}</>, value: 'total asc' },
	{ label: <>&uarr; {t('order.startDate')}</>, value: 'startDate desc' },
	{ label: <>&darr; {t('order.startDate')}</>, value: 'startDate asc' },
	{ label: <>&uarr; {t('order.endDate')}</>, value: 'endDate desc' },
	{ label: <>&darr; {t('order.endDate')}</>, value: 'endDate asc' },
];
export const ORDER_KANBAN_SORTING_VARIANTS = [
	{ label: <>&uarr; {t('sorting.newFirst')}</>, value: 'id desc' },
	{ label: <>&darr; {t('sorting.oldFirst')}</>, value: 'id asc' },
	{ label: <>&uarr; {t('sorting.priceAsc')}</>, value: 'total desc' },
	{ label: <>&darr; {t('sorting.priceDesc')}</>, value: 'total asc' },
	{ label: <>&uarr; {t('order.startDate')}</>, value: 'startDate desc' },
	{ label: <>&darr; {t('order.startDate')}</>, value: 'startDate asc' },
	{ label: <>&uarr; {t('order.endDate')}</>, value: 'endDate desc' },
	{ label: <>&darr; {t('order.endDate')}</>, value: 'endDate asc' },
];

export const PER_PAGE = 20;

export const DEFAULT_PRIORITY = 10000;

