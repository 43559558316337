import React from 'react';
import Root from './Root';
import { ORDERS_PATH, ORDERS_REPORT_PATH, PRICE_CATEGORIES_PATH, PRICE_LISTS_PATH, SETTINGS_PATH } from './constants';
import t from 'i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCashRegister, faTag, faGear } from '@fortawesome/free-solid-svg-icons';
import OrderIcon from 'client/img/sidebar/order.svg';

/**
 * Конфигурация модуля.
 */

export default {
	/** Заголовок модуля */
	title: t('sales.title'),

	/** Код модуля на латинице, обязателен */
	code: 'sales',

	/** Опциональная дополнительная функция проверки доступности модуля */
	enabled: (store) => store.model.Order && store.model.Order.INFO.READ,

	/** Главный компонент с роутами и компонентами модуля */
	component: Root,

	/** Необязательный компонент для общего дашбоарда, см. исходник */
	// dashboardCard: SalesCard,

	/** Меню модуля, может быть массивом или функцией (в аргументе передастся глобальный стор) */
	menu: () => [
		{
			type: 'item',
			label: t('order.plural'),
			path: ORDERS_PATH,
			reactIcon: <OrderIcon className='sidebar-react-icon' />,
			enabled: (store) => store.model.Order && store.model.Order.INFO.READ,
		},
		{
			type: 'item',
			label: t('order.report'),
			path: ORDERS_REPORT_PATH,
			icon: faCashRegister,
			enabled: (store) => store.model.Order && store.model.Order.INFO.READ,
		},
		{
			type: 'item',
			label: t('priceList.title'),
			path: PRICE_CATEGORIES_PATH,
			icon: faTag,
			enabled: (store) => store.model.PriceCategory && store.model.PriceCategory.INFO.WRITE,
		},
		{
			type: 'item',
			label: t('priceList.betaTitle'),
			path: PRICE_LISTS_PATH,
			icon: faTag,
			enabled: (store) => store.model.PriceList && store.model.PriceList.INFO.READ,
		},

		{
			type: 'item',
			label: t('settings'),
			path: SETTINGS_PATH,
			reactIcon: <FontAwesomeIcon icon={faGear} />,
			enabled: (store) => store.model.Feature && store.model.Feature.INFO.WRITE,
		},
	],
};

