import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { ExportButton } from '../buttons';

@observer
export class ToolbarExportBtn extends React.Component {
	static propTypes = {
		onClick: PropTypes.func,
	};

	render() {
		return <ExportButton onClick={this.props.onClick} {...this.props} />;
	}
}

