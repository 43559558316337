import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Column, Table, NumberValue, Pager } from '@smartplatform/ui';
import { BoxLabel } from 'components';
import { DOCUMENT_LIST_INCLUDE } from '../constants';
import { observable } from 'mobx';
import { renderStatus, renderProject, getPerPage } from 'client/tools';
import ViewDocument from 'client/img/view-document.svg';
import Clip from 'client/img/clip.svg';
import store from 'client/store';
import t from 'i18n';

import './style.scss';

@inject('store')
@observer
export default class List extends React.Component {
	@observable record = [];

	constructor(props) {
		super(props);
		this.store = props.store;
		props.store.reload = this.doSearch;
		store.local.itemsPerPage = 10;
	}

	componentDidMount() {
		this.setPerPage(getPerPage());
		this.init();
	}


	init = async () => {
		const documentsWhere = this.compileFilter();

		this.store.query = {
			include: DOCUMENT_LIST_INCLUDE,
			where: documentsWhere,
			limit: this.perPage,
			skip: (this.page - 1) * this.perPage,
			order: this.order,
		};
		this.record = await store.model.ViewDocument.find(this.store.query);

	};

	onQueryUpdate = (query) => {
		if (query) this.store.query = query;
		if (query.order === undefined) {
			this.order = 'id desc';
			this.store.query.order = this.order;
		} else {
			this.order = query.order;
		}
		this.init();
	};

	compileFilter = () => {
		const { search } = this.store;
		const searchFields = ['typeName', 'statusName', 'labelNames'];

		const trimmed = search.name.trim();
		const pattern = new RegExp(trimmed, 'i');

		const where = {
			and: [{ name: { regexp: `${pattern}` } }],
		};

		for (const field of searchFields) {
			if (search[field].length) {
				let RExp = search[field].join('|');
				RExp = new RegExp(RExp, 'gi');
				where.and.push({ [field]: { regexp: `${RExp}` } });
			}
		}

		return where;
	};

	doSearch = () => {
		this.page = 1;
		this.init();
	};

	onOpenDocuments = (e, files) => {
		e.stopPropagation();

		for (let file of files) {
			const attachmentUrl = `${window.location.origin}/api/containers/attachments/download/${file.id}-filename`;
			window.open(`https://docs.google.com/viewer?url=${attachmentUrl}&embedded=true`);
		}
	};

	renderFileView = (files) =>
		files.length ? (
			<div className='field-file'>
				<ViewDocument className='view-document' onClick={(e) => this.onOpenDocuments(e, files)} />
				<Clip />
				<span>{files.map(({ filename }) => filename).join(', ')}</span>
			</div>
		) : (
			'-'
		);

	renderLabels = (labels) => (
		<span className='labels'>
			{labels.map((label) => (
				<BoxLabel label={label} key={label.id} />
			))}
		</span>
	);

	renderContragents = (contragents) => contragents.map((contragent) => contragent.name).join(', ');

	renderProjects = (project) => {
		const _projectItems = project.map((item) => <Fragment key={item.id}>{renderProject(item)}</Fragment>);

		return _projectItems;
	};

	onRowClick = (record) => store.route.push({ path: `/documents/${record.id}` });

	onChange = (prop) => (value) => {
		this.page = 1;
		this[prop] = value;
		this.init();
	};

	setPerPage = (value) => {
		this.page = 1;
		this.perPage = value;
	};

	render() {

		return (
			<div className='documents-list'>
				<div id='table'>
					<Table rows={this.record} query={{}} onQueryUpdate={this.onQueryUpdate} onRowClick={this.onRowClick}>
						<Column property='name' label={t('name')} />
						<Column property='number' label={t('number')} />
						<Column relation='type' property='name' label={t('type')} />
						<Column property='total' label={t('document.totalSum')}>
							<NumberValue type='currency' />
						</Column>
						<Column relation='contragent' computed={this.renderContragents} label={t('client.plural')} className='contragent' />
						<Column relation='projects' computed={this.renderProjects} label={t('project.name')} />
						<Column relation='status' computed={renderStatus} label={t('status')} />
						<Column relation='labels' computed={this.renderLabels} label={t('label.plural')} />
						<Column relation='attachments' computed={this.renderFileView} label={t('files')} />
					</Table>
				</div>
				<Pager current={this.page} totalCount={this.record.totalCount} onChange={this.onChange('page')} itemsPerPage={this.perPage} noCount />
			</div>
		);
	}
}

