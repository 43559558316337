import React, { Component } from 'react';
import { Column, Table } from '@smartplatform/ui';
import { DeleteIconButton, BasicList } from 'components';
import { observer } from 'mobx-react';
import { renderUser } from 'client/tools';
import store from 'client/store';
import t from 'i18n';

@observer
export class MovementsList extends Component {
	init;

	getInitFunction = (init) => {
		this.init = init;
	};

	onDelete = async (record) => {
		await record.delete();
		this.init();
	};

	onRowClick = (record) => store.route.push({ path: `/nomenclaturemovements/${record.id}` });

	renderDeleteButton = ({ record }) => <DeleteIconButton onConfirm={() => this.onDelete(record)} />;

	render() {
		return (
			<BasicList
				model={store.model.NomenclatureMovement}
				path={this.props.path}
				filter={{ include: ['nomenclature', 'warehouseFrom', 'warehouseTo'] }}
				getInit={this.getInitFunction}
				onRowClick={this.onRowClick}
			>
				<Column property='code' label={t('nomenclatureMovement.code')} />
				<Column property='date' label={t('date')} width={200} />
				<Column relation='nomenclature' property='name' label={t('nomenclature.title')} />
				<Column property='quantity' label={t('nomenclature.quantity')} width={200} />
				<Column property='price' label={t('nomenclatureMovement.price')} width={200} />
				<Column relation='warehouseFrom' property='name' label={t('warehouse.warehouseFrom')} />
				<Column relation='warehouseTo' property='name' label={t('warehouse.warehouseTo')} />
			</BasicList>
		);
	}
}