import React from 'react';
import Root from './Root';
import t from 'i18n';

import { faHouse, faChartPie, faReceipt } from '@fortawesome/free-solid-svg-icons';

/**
 * Конфигурация модуля.
 */

export default {
	/** Заголовок модуля */
	title: t('finance'),

	/** Код модуля на латинице, обязателен */
	code: 'finance',

	/** Опциональная дополнительная функция проверки доступности модуля */
	enabled: (store) => store.model.Payment?.INFO.READ,

	/** Главный компонент с роутами и компонентами модуля */
	component: Root,

	/** Необязательный компонент для общего дашбоарда */
	// dashboardCard: FinanceCard,

	/** Меню модуля, может быть массивом или функцией (в аргументе передастся глобальный стор) */
	menu: () => [
		{
			type: 'item',
			path: '/mycompanies',
			label: t('contragent.myCompanies'),
			icon: faHouse,
		},
		{
			type: 'item',
			path: '/analytics',
			label: t('analytics.plural'),
			icon: faChartPie,
		},
		{
			type: 'item',
			path: '/operations',
			label: t('operation.plural'),
			icon: faReceipt,
		},
		{
			type: 'item',
			path: '/cashflows',
			label: t('cashFlow.plural'),
		},
	],
};

