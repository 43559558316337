import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Button, CancelButton, IconButton } from 'components';
import { renderPlaceholder } from 'client/tools';
import { RecordSelect, FormContext } from '@smartplatform/ui';
import { WarehouseItem } from './WarehouseItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import store from 'client/store';
import t from 'i18n';

@observer
export class Warehouses extends Component {
	@observable selectedWarehouse = null;
	@observable isAdding = false;
	@observable warehouses = [];
	@observable warehousesIds = [];

	relation = '';
	id = null;
	workshop = null;

	static contextType = FormContext;

	constructor(props, context) {
		super(props);
		this.relation = props.__data.relation;
		this.workshop = context.record;
		this.id = context.record?.id;
		this.init();
	}

	init = async () => {
		if (this.id) {
			this.warehouses = await this.workshop.warehouses();
			this.getWarehousesIds();
		}
	};

	getWarehousesIds = (addSelectedId) => (this.warehousesIds = [addSelectedId || false, ...this.warehouses.map((item) => item.id)]);

	onNewWarehouseChange = (warehouse) => {
		this.selectedWarehouse = warehouse;
	};

	onDelete = (record) => async () => {
		if (this.id) {
			await this.workshop.warehouses.remove(record.id);
			this.init();
		} else {
			this.context.form.removeManyToMany(this.relation, record.id);
			const index = this.warehouses.findIndex((warehouse) => warehouse.id === record.id);
			this.warehouses.splice(index, 1);
			this.getWarehousesIds();
		}
	};

	onSave = async (newWarehouse) => {
		if (this.id) {
			await this.workshop.warehouses.add(newWarehouse.id);
		} else {
			this.context.form.addManyToMany(this.relation, newWarehouse.id);
		}

		this.warehouses.push(newWarehouse);
		this.selectedWarehouse = null;
		this.getWarehousesIds();
		this.isAdding = false;
	};

	onAddWarehouse = () => (this.isAdding = true);

	onCancel = () => {
		this.selectedWarehouse = null;
		this.isAdding = false;
	};

	render() {
		return (
			<div className='warehouse-items'>
				{this.warehouses.map((item) => {
					return (
						<WarehouseItem
							key={item.id}
							item={item}
							onDelete={this.onDelete}
							excludeIds={[this.selectedWarehouse?.id || false, ...this.warehousesIds]}
							getWarehousesIds={this.getWarehousesIds}
							onSave={this.onSave}
							init={this.init}
							relation={this.relation}
						/>
					);
				})}

				{this.isAdding && (
					<div className='warehouse-line'>
						<div className='warehouse-wrapper'>
							<RecordSelect
								model={store.model.Warehouse}
								onChange={this.onNewWarehouseChange}
								filter={{ where: { id: { nin: this.warehousesIds } } }}
								placeholder={renderPlaceholder(t('process.workshop.placeholder'))}
								isRequired
								property='name'
								value={this.selectedWarehouse}
							/>
						</div>
						<div className='warehouse-item-buttons'>
							<Button text={t('save')} onClick={() => this.onSave(this.selectedWarehouse)} />
							<CancelButton onClick={this.onCancel} />
						</div>
					</div>
				)}
				<div className='warehouse-added'>
					<IconButton
						className='add-button'
						icon={<FontAwesomeIcon icon={faPlus} />}
						onClick={this.onAddWarehouse}
						disabled={this.isAdding}
					/>
				</div>
			</div>
		);
	}
}

