import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { EquipmentEdit } from './EquipmentEdit';
import { EquipmentsList } from './EquipmentsList';
import t from 'i18n';

import './equipments.scss';

const path = '/equipments';
const title = t('processEquipment.title');
const modelName = 'ProcessEquipment';
const relationLabel = t('process.workshop.title');
const relation = 'workshop';

const Equipments = () => {
	const props = { title, modelName, relationLabel, relation, path, createPath: path };

	return (
		<Switch>
			<Route path={path} exact children={({ match }) => <EquipmentsList match={match} {...props} />} />
			<Route path={`${path}/page/:page`} children={({ match }) => <EquipmentsList match={match} {...props} />} />
			<Route path={`${path}/:id`} children={({ match }) => <EquipmentEdit match={match} {...props} />} />
		</Switch>
	);
};

export { Equipments };

