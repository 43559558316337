import React from 'react';
import t from 'i18n';
import format from 'date-fns/format';
import {Avatar, Popover} from "@smartplatform/ui";
import {fio} from "client/tools";
import store from "client/store";

const renderCategoriesAndAmount = (order) => {
	let string = '-';
	if (order.categoriesAndAmount) {
		string = order.categoriesAndAmount.split(' | ').map((item, index) => <span key={index}>{item}</span>);
	}

	return <div className='order-positions'>{string}</div>;
};

const renderDate = (property) => (order) => {
	return order[property] && format(new Date(order[property]), 'dd.MM.yyyy');
};

const renderPopoverFullNameContragent = (order) => {
	return order.contragentName ? <Popover content={order.contragentName} trigger='hover' className="popover-table">{order.contragentName}</Popover> : '-';
}

const renderFullNameOrder = (order) => {
	return order?.description ? <Popover content={order?.description} trigger='hover' className="popover-table">{order?.description}</Popover> : '-';
}

const renderManagerAvatar = (order) => {
	return order.manager ?
		<Popover content={fio(order.manager)} trigger='hover'>
			<Avatar className='manager' user={order.manager} />
		</Popover>
		: '-';
}

const onClickReport = (report) => {
	store.route.push({ path: `${ORDERS_PATH}/${report.id}` })
}

const _COLUMNS = [
	{ property: 'id', label: t('order.n'), width: 40 },
	{ property: 'description', label: t('order.title'), computed: renderFullNameOrder },
	{ property: 'status', label: t('order.status') },
	{ property: 'contragentName', label: t('order.client'), computed: renderPopoverFullNameContragent },
	{ property: 'total', label: t('order.total') },
	{ property: 'paid', label: t('order.paid'), width: 60},
	{ property: 'fio', label: t('order.manager'), computed : renderManagerAvatar },
	{ property: 'categoriesAndAmount', label: t('order.categoriesAndAmount'), computed: renderCategoriesAndAmount, width: 250},
	{ property: 'invoiceNumber', label: t('order.invoiceNumber')},
	{ property: 'contractNumber', label: t('order.contractNumber') },
	{ property: 'startDate', label: t('order.startDate'), computed: renderDate('startDate') },
	{ property: 'endDate', label: t('order.endDate'), computed: renderDate('endDate') },
];
Object.keys(_COLUMNS).forEach((key) => (_COLUMNS[key].isSelected = true));
export const COLUMNS = _COLUMNS;

const _COLUMNS_REPORT = [
	{ property: 'id', label: t('order.n') },
	{ property: 'description', label: t('order.title') },
	{ property: 'status', label: t('order.status') },
	{ property: 'contragentName', label: t('order.client') },
	{ property: 'total', label: t('order.total') },
	{ property: 'paid', label: t('order.paid') },
	{ property: 'fio', label: t('order.manager') },
	{ property: 'categoryName', label: 'Категория' },
	{ property: 'categoryAmount', label: 'Количество' },
	{ property: 'invoiceNumber', label: t('order.invoiceNumber') },
	{ property: 'contractNumber', label: t('order.contractNumber') },
	{ property: 'startDate', label: t('order.startDate') },
	{ property: 'endDate', label: t('order.endDate') },
];
Object.keys(_COLUMNS_REPORT).forEach((key) => (_COLUMNS_REPORT[key].isSelected = true));
export const COLUMNS_REPORT = _COLUMNS_REPORT;

export const REPORT_FIELDS = [
	'id',
	'description',
	'listId',
	'clientId',
	'total',
	'paid',
	'managerId',
	'invoiceNumber',
	'contractNumber',
	'startDate',
	'endDate',
	'categoriesAndAmount',
	'status',
	'contragentName',
	'fio',
];
export const ORDER_INCLUDE = [
	{ relation: 'list', scope: { fields: ['id', 'name'] } },
	{ relation: 'client', scope: { fields: ['id', 'name'] } },
	{ relation: 'manager', scope: { fields: ['id', 'lastName', 'firstName', 'middleName', 'username'] } },
	{
		relation: 'positions',
		scope: {
			where: { categoryId: { neq: null } },
			include: [{ relation: 'category', scope: { fields: ['id', 'name'] } }],
			order: 'id asc',
		},
	},
];

//report-toolbar
export const REPORT_SELECT_ITEMS = [
	{ value: 100000, label: t('all') },
	{ value: 50, label: 50 },
	{ value: 100, label: 100 },
	{ value: 500, label: 500 },
	{ value: 1000, label: 1000 },
];

const ORDERS_PATH = `/sales/orders`;

