import React, { Component } from 'react';
import store from 'client/store';
import { observer, Provider } from 'mobx-react';
import { observable } from 'mobx';
import { Switch, Route, matchPath } from 'react-router-dom';
import { Tabs, Tab, Select, Loader } from '@smartplatform/ui';
import { Mobile, Desktop } from 'components';
import { DOCUMENT_FORM_INCLUDE } from '../constants';
import { tabs } from './tabs';
import { DocumentStore } from './store';
import t from 'i18n';

@observer
export class Document extends Component {
	@observable route = null;
	@observable record = null;
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.store = new DocumentStore(props.match.params.id);
		this.store.fetchRecord();
		this.setTitle();
	}

	setTitle = () => {
		store.ui.title = this.store.id ? `${t('document.title')} #${this.store.id}` : t('document.new');
	};

	render() {
		const { isLoading, record } = this.store;

		if (isLoading) return <Loader />;
		if (!record) return <>{t('document.card.clientNotFound')}</>;

		const routes = tabs(record.id).filter((tab) => tab.isVisible);

		return (
			<Provider documentStore={this.store}>
				<div className='document-page'>
					<Tabs params={{ documentId: record.id }} className='documents-tabs-info'>
						{routes.map((route, i) => (
							<Tab key={i} {...route} component={route.component} />
						))}
					</Tabs>
				</div>
			</Provider>
		);
	}
}

