import React from 'react';
import t from 'i18n';
import store from 'client/store';
import { Toolbar } from './toolbar';
import AnalyticsStore from './store';
import { observer, Provider } from 'mobx-react';
import { Charts } from './charts';
import './style.scss';

@observer
export default class Analytics extends React.Component {
	
	constructor(props) {
		super(props);
		store.ui.title = t('analytics.plural');
		this.store = new AnalyticsStore();
		this.store.init();
	}
	
	render() {
		if (!this.store.isInitialized) return null;
		return (
			<Provider store={this.store}>
				<div className='finance-analitics'>
					<Toolbar />
					{this.store.bankAccounts.length ? <Charts /> : t('bankAccount.notSelected')}
				</div>
			</Provider>
		);
	}
}

