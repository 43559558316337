import React from 'react';
import { observer } from 'mobx-react';
import { ProjectSelect } from 'components';

export const ToolbarProjectSelect = observer(({ label, ...recordSelectProps }) => {
	return (
		<div className='toolbar-project-select'>
			{label && <label>{label}</label>}
			<ProjectSelect {...recordSelectProps} />
		</div>
	);
});

