import React, { Component } from 'react';
import { Field, Input, RecordSelect } from '@smartplatform/ui';
import { ModelEdit, Button, Actionbar, Editor, DeleteButton, TruncateText } from 'components';
import store from 'client/store';
import t from 'i18n';
import { observer } from 'mobx-react';
import { processText, renderPlaceholder, renderFaIcons } from 'client/tools';
import { observable } from 'mobx';

@observer
export class Edit extends Component {
	@observable isEdit = false;
	@observable record = null;
	@observable isSwitchDescription = false;

	prevName = '';
	prevDescr = '';

	constructor(props) {
		super(props);
		this.id = parseInt(props.match.params.id);
		this.title = this.id ? (store.ui.title = t('process.title') + ` #${this.id}`) : t('process.newProcess');
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.match.params?.id !== this.props.match.params?.id) {
			this.id = this.props.match.params?.id;
		}
	}

	onChange = (prop) => (value) => {
		this.record[prop] = value;

		if (prop === 'equipment') {
			this.record[prop] = value;
			this.record[`${prop}Id`] = value.id;
			this.record.patchAttributes({ equipmentId: this.record.equipmentId, equipment: this.record.equipment });
		}
	};

	getRecord = (record) => (this.record = record);

	onStartEditing = () => {
		this.isEdit = true;
		this.prevName = this.record.name;
		this.prevDescr = this.record.description;
	};

	onCancel = () => {
		this.record.name = this.prevName;
		this.record.description = this.prevDescr;
		this.isEdit = false;
		this.isSwitchDescription = false;

		if (!this.record?.id) store.route.push({ path: '/processes' });
	};

	onSave = async () => {
		await this.record.save();
		store.route.push({ path: `/processes/${this.record.id}` });
		this.isEdit = false;
		this.isSwitchDescription = false;
	};

	onDelete = async () => {
		await this.record.delete();
		store.route.push({ path: '/processes' });
	};

	switchDescription = () => {
		this.isSwitchDescription = true;
		this.prevName = this.record.name;
		this.prevDescr = this.record.description;
	};

	render() {
		const { modelName, relation } = this.props;

		const isRecordNewOrEdit = !this.id || this.isEdit;

		return (
			<div className='process-page fixed-page'>
				<ModelEdit
					model={store.model[modelName]}
					id={this.id}
					title={this.title}
					filter={{ include: [relation] }}
					noSave
					path='/processes'
					getRecord={this.getRecord}
					onSave={this.onSave}
					noDelete
					noCancel
				>
					<div className='process'>
						<div className='process-header'>
							<Field property='name'>
								{(record) => {
									return isRecordNewOrEdit ? (
										<Input placeholder={t('process.addName')} value={record.name} onChange={this.onChange('name')} />
									) : (
										<>
											<TruncateText className='name-value'>{record.name}</TruncateText>
										</>
									);
								}}
							</Field>
						</div>
						<div className='process-description-equipment'>
							<Field relation='equipment' property='name' label={t('process.equipment.title')}>
								<RecordSelect
									model={store.model.ProcessEquipment}
									isRequired
									placeholder={renderPlaceholder(t('select'))}
									onChange={this.onChange('equipment')}
								/>
							</Field>
							<Field property='description' label={t('description')}>
								{(record) =>
									this.isSwitchDescription || isRecordNewOrEdit ? (
										<>
											<Editor
												value={record.description}
												autoResize
												onChange={this.onChange('description')}
												minHeight={180}
												height={window.innerWidth < 768 ? 200 : 600}
											/>
											<div className='action-controls'>
												<Actionbar
													onDelete={this.onDelete}
													onCancel={this.onCancel}
													deleteTitle={t('process.delete')}
													disableSave={!this.record?.name}
												/>
											</div>
										</>
									) : (
										<div className='description'>
											<div
												className='rich-text'
												dangerouslySetInnerHTML={{
													__html: processText(
														record.description?.length > 0 ? record.description : t('process.addDescription')
													),
												}}
											/>
											<div className='switch-description'>
												<Button
													variant='default'
													onClick={this.switchDescription}
													text={t('modify.description')}
													startIcon={renderFaIcons.editIcon}
												/>
											</div>
											<div className='action-controls'>
												<Button
													text={t('modify.title')}
													onClick={this.onStartEditing}
													className='action-controls-save'
												/>
												<DeleteButton onConfirm={this.onDelete} text={t('process.delete')} />
											</div>
										</div>
									)
								}
							</Field>
						</div>
					</div>
				</ModelEdit>
			</div>
		);
	}
}

