import Root from './Root';
import t from 'i18n';

import { faWarehouse, faBoxes, faBookOpen, faRuler, faArrowRightArrowLeft } from '@fortawesome/free-solid-svg-icons';

/**
 * Конфигурация модуля.
 */

export default {
	/** Заголовок модуля */
	title: t('storehouse.title'),

	/** Код модуля на латинице, обязателен */
	code: 'storehouse',

	/** Опциональная дополнительная функция проверки доступности модуля */
	enabled: (store) => store.model.Nomenclature && store.model.Nomenclature.INFO.READ,

	/** Главный компонент с роутами и компонентами модуля */
	component: Root,

	/** Необязательный компонент для общего дашбоарда */
	// dashboardCard: StorehouseCard,

	/** Меню модуля, может быть массивом или функцией (в аргументе передастся глобальный стор) */
	menu: () => [
		{
			type: 'item',
			label: t('nomenclature.plural'),
			path: '/nomenclatures',
			icon: faBoxes,
		},
		{
			type: 'item',
			label: t('nomenclatureGroup.plural'),
			path: '/nomenclaturegroups',
			icon: faBookOpen,
		},
		{
			type: 'item',
			path: '/nomenclaturemovements',
			label: t('nomenclatureMovement.title'),
			icon: faArrowRightArrowLeft,
		},
		{
			type: 'item',
			label: t('measure.plural'),
			path: '/measures',
			icon: faRuler,
		},
		{
			type: 'item',
			path: '/warehouses',
			label: t('production.warehouses'),
			icon: faWarehouse,
		},
	],
};

