import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Avatar } from '@smartplatform/ui';
import { fioShort, relative } from 'client/tools';
import classNames from 'classnames';
import store from 'client/store';
import t from 'i18n';

import './log.scss';

@observer
export class Log extends Component {
	render() {
		const { className, instanceLog, actions } = this.props;

		return (
			<div className={classNames('log', className)}>
				<span className='who'>
					<Avatar user={instanceLog.owner} size={32} />
				</span>
				<div className='what'>
					<span className='subject'>{fioShort(instanceLog.owner)}</span>
					{actions.length > 0 &&
						actions.map((action, i) => {
							return (
								<span className='action' key={i}>
									<>{action.title} </>
									<span className='subject'>{action.subject}</span>
									{actions.length > 0 && i < actions.length - 1 ? ', ' : ''}
								</span>
							);
						})}

					<span className='date'>{relative(instanceLog.createdAt)}</span>
				</div>
			</div>
		);
	}
}

