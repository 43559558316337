import store from 'client/store';
import { observable } from 'mobx';
import { COST, INCOME, TRANSFER, PATH } from './constants';
import t from 'i18n';

export default class NomenclatureMovementStore {
	@observable movementType = INCOME;
	@observable movementLines = [];
	@observable code = null;
	@observable date = null;
	@observable warehouseTo = null;
	@observable warehouseFrom = null;
	@observable contragentTo = null;
	@observable contragentFrom = null;

	constructor() {
		this.init();
	}

	init = async () => {
		this.code = null;
		this.date = null;
		this.warehouseTo = null;
		this.warehouseFrom = null;
		this.contragentTo = null;
		this.contragentFrom = null;

		this.movementType = INCOME;

		this.addMovementLine();
	};

	onChangeValue = (prop) => (value) => {
		if (this[prop] !== value) {
			this[prop] = value;
		}
	};

	addMovementLine = () => {
		const line = new store.model.NomenclatureMovement({
			code: this.code,
			date: this.date,
			warehouseTo: this.warehouseTo,
			warehouseFrom: this.warehouseFrom,
			contragentTo: this.contragentTo,
			contragentFrom: this.contragentFrom,
			externalId: crypto.randomUUID(),
			nomenclature: null,
			quantity: 0,
			price: 0,
			currency: null,
			import: false,
		});

		this.movementLines.push(line);
	};

	deleteMovementLine = (line, index) => {
		this.movementLines.splice(index, 1);
	};

	validateData = () => {
		if (!this.date) return false;
		if (!this.warehouseFrom && (this.movementType === COST || this.movementType === TRANSFER)) return false;
		if (!this.warehouseTo && (this.movementType === INCOME || this.movementType === TRANSFER)) return false;

		for (let i = 0; i < this.movementLines.length; i++) {
			if (!this.movementLines[i].nomenclature || !this.movementLines[i].quantity) return false;
		}

		return true;
	}

	back = () => store.history.push(`${PATH}`);

	beforeSave = () => {
		//дополним все значения строк общими значениями из формы
		for (let i = 0; i < this.movementLines.length; i++) {
			this.movementLines[i].code = this.code;
			this.movementLines[i].date = this.date;
			if ((this.movementType === TRANSFER)) {
				this.movementLines[i].warehouseFrom = this.warehouseFrom;
				this.movementLines[i].warehouseTo = this.warehouseTo;
				this.movementLines[i].contragentFrom = null;
				this.movementLines[i].contragentTo = null;
			} else if ((this.movementType === COST)) {
				this.movementLines[i].warehouseFrom = this.warehouseFrom;
				this.movementLines[i].warehouseTo = null;
				this.movementLines[i].contragentFrom = null;
				this.movementLines[i].contragentTo = this.contragentTo;
			} else {
				this.movementLines[i].warehouseFrom = null;
				this.movementLines[i].warehouseTo = this.warehouseTo;
				this.movementLines[i].contragentTo = null;
				this.movementLines[i].contragentFrom = this.contragentFrom;
			}
		}
	};

	onSave = async () => {
		//подготовка данных перед записью
		this.beforeSave();

		//запись данных
		if (this.validateData()) {
			const promises = [];

			for (let line of this.movementLines) {
				promises.push(line.save());
			}

			await Promise.all(promises);

			this.back();
		}
	};
}