import React from 'react';
// import { ColorUtils } from '@smartplatform/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import User from './User';
import classNames from 'classnames';

// для itemTag manyToManySelect
export const BoxUser = ({ user, closeCallback, className, iconSize = 24 }) => {
	if (!user) return null;
	const _className = classNames('box-contragent', className);

	return (
		<div className={_className}>
			<User user={user} size={iconSize} />
			{closeCallback && <FontAwesomeIcon icon={faTimes} onClick={closeCallback} size='lg' className='close-btn' />}
		</div>
	);
};
