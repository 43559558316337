import { observable } from 'mobx';
import store from 'client/store';
import { FIELDS, PAYMENT_INCLUDE, PERIODS } from './constants';
import { endOfMonth, endOfQuarter, endOfYear, startOfMonth, startOfQuarter, startOfYear } from 'date-fns';
import debounce from 'lodash/debounce';
import { TABLE_HEADER_HEIGHT, TABLE_MIN_PER_PAGE, TABLE_ROW_HEIGHT } from './constants';

export default class OperationsStore {
	@observable isDashboardFetching = true;
	@observable isRecordsFetching = true;
	@observable isInitialized = false;
	@observable records = [];
	//
	@observable showCharts = true;
	@observable showEditPopup = false;
	@observable selectedRecord = null;
	// даты
	@observable period = PERIODS[0];
	@observable startDate = null;
	@observable endDate = null;
	// остальные фильтры
	@observable planFactGroup = null;
	@observable projects = [];
	@observable cashFlows = [];
	@observable contragents = [];
	@observable bankAccounts = [];
	@observable page = 1;
	@observable pieCharts;
	order = 'id desc';

	constructor() {
		this.doSearch = debounce(this.doSearch, 500, { leading: false, trailing: true });
	}

	init = async () => {
		this.onPeriodChange(this.period);
		await Promise.all([this.fetchDashBoardData(), this.getMyCompanies()]);
		this.isInitialized = true;
		await this.fetchRecords();
	};

	onSearch = (e) => {
		this.search = e.target.value;
		this.doSearch();
	};

	doSearch = () => this.refetch();

	getMyCompanies = async () => {
		this.myCompanies = await store.model.Contragent.find({ where: { myCompany: true }, include: ['bankAccounts'] });
	};

	fetchDashBoardData = async () => {
		this.isDashboardFetching = true;
		const { paymentTypeTotal, moneyOnBankAccountsChart } = await store.model.ViewPayment.getDashboardData({
			startDate: this.startDate,
			endDate: this.endDate,
			bankAccountIds: this.bankAccounts.map((i) => i.id),
			cashFlowIds: this.cashFlows.map((i) => i.id),
			contragentIds: this.contragents.map((i) => i.id),
			projectIds: this.projects.map((i) => i.id),
			charts: ['pie', 'moneyOnBankAccounts'],
		});
		this.chartRecords = moneyOnBankAccountsChart || [];
		this.pieCharts = paymentTypeTotal || [];
		this.isDashboardFetching = false;
	};

	// обновление дашборда и таблицы при закрытии попапа редактирования
	refetch = () => {
		this.fetchDashBoardData();
		this.fetchRecords();
	};

	fetchRecords = async () => {
		this.isRecordsFetching = true;
		this.records = await store.model.ViewPayment.find(this.filters);
		this.isRecordsFetching = false;
	};

	get filters() {
		const perPage = this.perPage;
		const filter = {
			where: { and: [{ parentId: null }] },
			include: PAYMENT_INCLUDE,
			limit: perPage,
			skip: perPage * (this.page - 1),
			order: this.order,
		};
		if (this.cashFlows.length) filter.where.and.push({ cashFlowId: { inq: this.cashFlows.map((i) => i.id) } });
		if (this.projects.length) filter.where.and.push({ projectId: { inq: this.projects.map((i) => i.id) } });

		if (this.contragents.length)
			filter.where.and.push({
				or: [{ payerId: { inq: this.contragents.map((i) => i.id) } }, { recipientId: { inq: this.contragents.map((i) => i.id) } }],
			});

		if (this.bankAccounts.length) {
			const bankAccountIds = this.bankAccounts.map((i) => i.id);
			filter.where.and.push({
				and: [
					{
						and: [
							{ or: [{ payerBankAccountId: { inq: bankAccountIds } }, { recipientBankAccountId: { inq: bankAccountIds } }] },
						],
					},
				],
			});
		}
		if (this.startDate) filter.where.and.push({ date: { gte: this.startDate } });
		if (this.endDate) filter.where.and.push({ date: { lte: this.endDate } });
		if (this.search?.length) {
			!Number.isNaN(+this.search) && filter.where.and.push({ total: +this.search });
			filter.where.and.push({ description: { ilike: `%${this.search}%` } });
		}
		return filter;
	}

	get perPage() {
		if (!this.tableHeight) {
			this.tableHeight = document.getElementById('operations-table')?.offsetHeight;
			this.chartsHeight = document.getElementById('charts')?.offsetHeight || 0;
		}
		const diff = this.showCharts ? this.tableHeight : this.tableHeight + (this.chartsHeight + 20);
		const perPage = Math.floor((diff - TABLE_HEADER_HEIGHT) / TABLE_ROW_HEIGHT);
		return perPage > TABLE_MIN_PER_PAGE ? perPage : TABLE_MIN_PER_PAGE;
	}

	onChange = (prop) => (value) => {
		this[prop] = value;
		if (prop !== 'page') {
			this.fetchDashBoardData();
		}
		this.fetchRecords();
	};

	toggleProp = (prop) => async (value) => {
		this[prop] = typeof value === 'boolean' ? value : !this[prop];
		if (prop === 'showCharts') {
			this.page = 1;
			this.fetchRecords();
		}
	};

	cleanFilters = () => {
		FIELDS.forEach((field) => {
			if (['cashFlows', 'bankAccounts', 'projects', 'contragents'].includes(field)) {
				this[field] = [];
			}
		});
		this.showCharts = true;
		this.onPeriodChange(PERIODS[0]);
		this.refetch();
	};

	onQueryUpdate = async (query) => {
		if (query.order) {
			this.order = query.order;
		} else this.order = 'id desc';
		this.page = 1;
		await this.fetchRecords();
	};

	onRowClick = (record) => {
		this.selectedRecord = record;
		this.showEditPopup = true;
	};

	onPeriodChange = (period) => {
		this.period = period;
		const currentDate = new Date();
		switch (period) {
			case 'currentMonth': {
				this.startDate = startOfMonth(currentDate);
				this.endDate = endOfMonth(currentDate);
				break;
			}
			case 'currentQuarter': {
				this.startDate = startOfQuarter(currentDate);
				this.endDate = endOfQuarter(currentDate);
				break;
			}
			case 'currentYear': {
				this.startDate = startOfYear(currentDate);
				this.endDate = endOfYear(currentDate);
				break;
			}
			case 'all': {
				this.startDate = null;
				this.endDate = null;
				break;
			}
		}
	};
}

