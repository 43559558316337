import { observable } from 'mobx';
import { DOCUMENT_FORM_INCLUDE } from '../constants';
import store from 'client/store';

export class DocumentStore {
	@observable record = null;
	@observable isLoading = true;

	constructor(id) {
		this.id = parseInt(id);
	}

	fetchRecord = async () => {
		this.isLoading = true;

		if (!this.id) {
			this.record = new store.model.Document();
		} else {
			this.record = await store.model.Document.findById(this.id, {
				include: DOCUMENT_FORM_INCLUDE,
			});
		}

		this.isLoading = false;
	};
}

