import React from 'react';

import { observer } from 'mobx-react';
import { renderUser, renderPlaceholder } from 'client/tools';
import store from 'client/store';
import t from 'i18n';
import { toJS } from 'mobx';
import classNames from 'classnames';
import './userSelect.scss';
import { RecordSelect } from './RecordSelect';

const DEFAULT_FILTER = {
	order: 'lastName asc, firstName asc, username asc',
	fields: ['id', 'firstName', 'lastName', 'middleName', 'avatar', 'email'],
};

@observer
export class UserSelect extends React.Component {
	static defaultProps = {
		filter: {},
		searchPlaceholder: t('search'),
		selectedPlaceholder: t('selected'),
		placeholder: t('all'),
		showClearButton: true,
	};

	get showValue() {
		const { value, computed = renderUser, selectedPlaceholder, placeholder } = this.props;
		const _computed = (value) => (value?.id === null ? t('task.notAssigned') : computed(value));

		if (Array.isArray(toJS(value))) {
			if (value.length === 1) {
				let firstItem = value[0];
				if (firstItem.id === store.model.user.id) {
					firstItem = new store.model.User({ avatar: firstItem.avatar, id: firstItem.id, lastName: t('task.me') });
				}
				return _computed(firstItem);
			}
			if (value.length > 1) {
				return `${selectedPlaceholder}: ${value.length}`;
			}

			return renderPlaceholder(placeholder);
		}

		return value ? _computed(value) : renderPlaceholder(placeholder);
	}

	render() {
		let { computed = renderUser, model, showValue, maxItems, filter, itemsPerPage, className, ...restProps } = this.props;
		className = classNames('user-select', className);
		filter = { ...DEFAULT_FILTER, ...filter };
		return (
			<RecordSelect
				{...restProps}
				filter={filter}
				itemsPerPage={itemsPerPage || store.maxSelectItems}
				maxItems={maxItems || store.maxSelectItems}
				model={model || store.model.User}
				computed={(record) => (record?.id === null ? t('task.notAssigned') : computed(record))}
				showValue={showValue || this.showValue}
				className={className}
			/>
		);
	}
}

