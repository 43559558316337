import React from 'react';
import { Switch, Route } from 'react-router-dom';

import MyCompanies from './mycompanies';
import Analytics from './analytics';
import Operations from './operations';

/**
 * Главный компонент с роутами и компонентами модуля.
 * Также может содержать, например, некую асинхронную логику инициализации.
 */

export default () => <Switch>
	<Route path="/mycompanies" component={MyCompanies} />
	<Route path="/analytics" component={Analytics} />
	<Route path="/operations" component={Operations} />
</Switch>;
