import React, { Component } from 'react';
import { Column, RecordSelect, Table } from '@smartplatform/ui';
import { Toolbar, BasicList } from 'components';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { renderPlaceholder } from 'client/tools';
import store from 'client/store';
import t from 'i18n';

@observer
export class EquipmentsList extends Component {
	@observable workshops = [];

	get filters() {
		const filters = {
			include: this.props.relation,
			where: {
				and: [],
			},
		};

		if (this.workshops.length) {
			filters.where.and.push({ workshopId: { inq: this.workshops.map((w) => w.id) } });
		}

		return filters;
	}

	onChange = (prop) => (value) => {
		this[prop] = value;
	};

	render() {
		const { relation, relationLabel } = this.props;

		const showValue = !!this.workshops.length
			? `${t('process.equipment.selectedWorkshops')} ${this.workshops.length}`
			: renderPlaceholder(t('process.equipment.selectWorkshops'));

		const customToolbarProps = {
			onChange: this.onChange,
			value: this.workshops,
			showValue,
		};

		return (
			<>
				<BasicList
					renderCustomToolbar={(props) => <CustomToolbar {...customToolbarProps} {...props} />}
					model={store.model.ProcessEquipment}
					title={this.props.title}
					filter={this.filters}
				>
					<Column property='name' label={t('name')} />
					<Column relation={relation} property='name' label={t(relationLabel)} />
					<Column property='info' label={t('information')} />
					<Column property='investmentNumber' label={t('process.equipment.invNumber')} />
				</BasicList>
			</>
		);
	}
}

const CustomToolbar = ({ totalCount, text, onChange, onClick, onSearch, search, showValue, value }) => (
	<Toolbar className='model-list-toolbar'>
		<Toolbar.CreateBtn onClick={onClick} text={text} />
		<Toolbar.Search value={search} onSearch={onSearch} />
		<RecordSelect
			model={store.model.ProcessWorkshop}
			property='name'
			value={value}
			onChange={onChange('workshops')}
			searchPlaceholder={t('process.workshop.search')}
			showValue={showValue}
		/>
		<Toolbar.Count count={totalCount} />
	</Toolbar>
);

