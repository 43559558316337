import { Column } from '@smartplatform/ui';
import store from 'client/store';
import { BasicList } from 'components';
import t from 'i18n';
import React from 'react';

export default ({ modelName, path, title, relation, relationLabel }) => {
	const renderMaterialsCount = (record) => record.length;

	return (
		<BasicList
			model={store.model[modelName]}
			path={path}
			title={title}
			filter={{ include: relation }}
			toolbarButtonText='material.type.add'
		>
			<Column property='name' label={t('name')} />
			<Column relation={relation} computed={renderMaterialsCount} label={relationLabel} />
		</BasicList>
	);
};

