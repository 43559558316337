export const PERIODS = ['all', 'currentMonth', 'currentQuarter', 'currentYear', 'specified'];
export const PLAN = 'PLAN';
export const FACT = 'FACT';
export const ALL = 'ALL';
export const COSTS = 'COSTS';
export const INCOME = 'INCOME';
export const TRANSFER = 'TRANSFER';
export const PAYMENT_INCLUDE = [
	...['project', 'cashFlow', 'recipient', 'payer'].map((name) => ({
		relation: name,
		scope: { fields: ['id', 'name'] },
	})),
	{ relation: 'recipientBankAccount', scope: { include: ['contragent'] } },
	{ relation: 'payerBankAccount', scope: { include: ['contragent'] } },

	{
		relation: 'children',
		scope: {
			include: [
				...['project', 'cashFlow', 'recipient', 'payer'].map((name) => ({
					relation: name,
					scope: { fields: ['id', 'name'] },
				})),
				{ relation: 'recipientBankAccount', scope: { include: ['contragent'] } },
				{ relation: 'payerBankAccount', scope: { include: ['contragent'] } },
			],
		},
	},
];
export const PER_PAGE = 10;
export const COLORS = {
	RED: '#FF0000',
	GREEN: '#008000',
	BLUE: '#42AAFF',
};
export const TOTAL_COLORS = {
	INCOME: { color: COLORS.GREEN, prependSymbol: '+ ' },
	COSTS: { color: COLORS.RED, prependSymbol: '- ' },
	TRANSFER: { color: COLORS.BLUE },
	default: { color: COLORS.BLUE },
};
export const FIELDS = [
	'showCharts',
	'period',
	'startDate',
	'endDate',
	'planFactGroup',
	'cashFlows',
	'contragents',
	'bankAccounts',
	'projects',
];
export const TABLE_HEADER_HEIGHT = 35;
export const TABLE_MIN_PER_PAGE = 5;
export const TABLE_ROW_HEIGHT = 50;

