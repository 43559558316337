export const DEFAULT_PRIORITY = 1000;
export const MODES = {
	KANBAN: 'KANBAN',
	LIST: 'LIST',
	TILES: 'TILES',
};

export const KANBAN_INITIAL_LIMIT = 20;
export const TABLE_ROW_HEIGHT = 48;

export const ME = 'ME';
export const ALL = 'ALL';

