import { observable } from 'mobx';
import {COLUMNS, COLUMNS_REPORT, ORDER_INCLUDE, REPORT_FIELDS} from './constants';
import store from 'client/store';

export default class ReportStore {
	@observable query = {};
	@observable totalCount = 0;
	@observable limit = 100;
	@observable isEdit = false;
	@observable isLoading = false;
	@observable records = [];
	@observable selectedColumns = COLUMNS;
	@observable selectedColumnsReport = COLUMNS_REPORT;

	isManagerOrAdmin = false;

	constructor(ordersStore) {
		this.store = ordersStore;
		this.store.fetchReportRecords = this.init;
		this.isManagerOrAdmin = this.store.isManagerOrAdmin;
		this.setVisibleFields();
	}

	init = async () => {
		this.query = { where: this.store.filters, include: ORDER_INCLUDE, fields: REPORT_FIELDS, order: 'id desc', limit: this.limit };
		this.isLoading = true;
		try {
			this.records = await store.model.ViewOrder.find(this.query);
		} catch (e) {
			console.error('Fetching orders error:', e);
		}
		this.isLoading = false;
	};

	setVisibleFields = () => {
		this.selectedColumns = this.selectedColumns.filter((col) => {
			if (['total'].includes(col.property) && !this.isManagerOrAdmin) return false;
			return true;
		});
	};

	downloadReport = async () => {
		const cols = this.selectedColumnsReport
			.filter((col) => col.isSelected)
			.reduce((acc, currentCol) => {
				if (currentCol.relation) {
					acc[currentCol.relation] = currentCol.label;
				} else {
					acc[currentCol.property] = currentCol.label;
				}

				return acc;
			}, {});

		const _query = { ...this.query, fields: [...Object.keys(cols)] };

		const params = {
			cols,
			filters: _query,
		};

		const query = `?params=${encodeURIComponent(JSON.stringify(params))}`;
		const path = `/api/ViewOrders/exportXLSX${query}`;

		const downloadLink = document.createElement('a');
		downloadLink.href = path;
		downloadLink.rel = 'noopener noreferrer';
		downloadLink.click();
		downloadLink.remove();
	};

	onToggleEdit = (isEdit) => () => {
		this.isEdit = isEdit;
	};

	onEditConfirm = () => {
		this.onToggleEdit(false)();
	};

	onLimitChange = (value) => {
		this.limit = value;
		this.init();
	};
}

