import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Button } from '../../buttons';
import { Logo, ErrorMessage, FieldPassword } from '../components/';
import { WaitTimer } from './WaitTimer';
import RefreshBtn from 'client/img/refresh.svg';
import classNames from 'classnames';
import store from 'client/store';
import t from 'i18n';

import './login.scss';

@observer
export class Login extends React.Component {
	@observable login = '';
	@observable password = '';
	@observable captcha = '';
	@observable message = null;
	@observable status = null;
	@observable reloads = 0;
	@observable waitUntil = null;
	@observable isLoading = false;
	@observable errors = {
		limit: null,
		noLogin: null,
		noPassword: null,
		general: null,
	};

	constructor(props, context) {
		super(props);
		this.init();
	}

	init = async () => {
		this.message = null;

		const res = await fetch('/api/checklogin', {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		});

		if (res.ok) {
			const status = await res.json();
			console.log('checklogin:', status);
			this.status = status;

			if (status.waitUntil) {
				this.errors.limit = t('auth.limitExceeded');
			}
		} else {
			this.errors.general = res.statusText;
		}
	};

	onChange = (prop) => (e) => {
		this[prop] = e.target.value;
	};

	checkFieldValidation = (field) => {
		this[field] = this[field].trim();

		if (this[field].length === 0) {
			const capitalizeFieldName = field.charAt(0).toUpperCase() + field.slice(1);
			const errorField = `no${capitalizeFieldName}`;
			this.errors[errorField] = t(`auth.enter${capitalizeFieldName}`);
			return false;
		}

		return true;
	};

	resetErrors = () => {
		Object.keys(this.errors).forEach((key) => {
			this.errors[key] = null;
		});
	};

	onSubmit = async () => {
		this.resetErrors();
		this.message = null;
		this.status = null;

		const isFieldsValidationSuccess = ['login', 'password'].every(this.checkFieldValidation);

		if (isFieldsValidationSuccess) {
			this.isLoading = true;
			try {
				await store.model.login(this.login, this.password, this.captcha);
				store.route.push({ path: '/management/tasks' });
			} catch (e) {
				this.status = e.loginStatus;
				console.log('?', e.code, e.loginStatus);
				this.errors.general = t(e.code);
				this.captcha = '';
			}
			this.isLoading = false;
		}
	};

	onKeyUp = (e) => {
		if (e.keyCode === 13) {
			this.onSubmit();
		}
	};

	onWaitTimeout = async () => {
		console.log('onWaitTimeout');
		this.resetErrors();
		this.status = null;
		this.message = t('auth.tryAgain');
	};

	refresh = () => this.reloads++;

	onGoToRegister = () => store.route.push({ path: '/register' });

	onGoToRestore = () => store.route.push({ path: '/restore' });

	render() {
		const yearNow = new Date().getFullYear();
		const waitUntil = this.status?.waitUntil || null;
		const now = new Date().getTime();
		const wait = waitUntil && now < waitUntil;
		const disableForm = this.isLoading || wait;

		return (
			<>
				<Logo />
				<h3>{t('auth.login')}</h3>
				<form action='submit' className='auth-form'>
					<div className='field'>
						<label>{t('user.email')}</label>
						<input
							type='text'
							name='email'
							placeholder='Введите логин'
							value={this.login}
							onChange={this.onChange('login')}
							disabled={disableForm}
						/>
						<ErrorMessage field={this.errors.noLogin} />
					</div>
					<FieldPassword
						className={classNames('field', { last: !this.status?.captcha })}
						label={t('user.password')}
						value={this.password}
						onChange={this.onChange('password')}
						onKeyUp={this.onKeyUp}
						disabled={disableForm}
						error={this.errors.noPassword}
					/>
					{this.status?.captcha && (
						<>
							<div className='field'>
								<label>{t('auth.captcha')}</label>
								<input
									type='text'
									name='captcha'
									value={this.captcha}
									onKeyUp={this.onKeyUp}
									onChange={this.onChange('captcha')}
								/>
							</div>
							<div className='captcha'>
								<RefreshBtn className='reload' onClick={this.refresh} />
								<img src={`/api/captcha?${this.status.lastTry}-${this.reloads}`} ref={(el) => (this.img = el)} alt='' />
							</div>
						</>
					)}
					<ErrorMessage field={this.errors.general} />
					<div className='submit'>
						{<Button text={t('login')} onClick={this.onSubmit} disabled={disableForm} />}
						{store.model.User.INFO.CREATE && (
							<Button text={t('auth.registration')} onClick={this.onGoToRegister} disabled={disableForm} />
						)}
					</div>
					{this.message && <div className='message'>{this.message}</div>}
				</form>
				<div className={classNames('restore', { disabled: disableForm })}>
					<span onClick={this.onGoToRestore}>{t('auth.forgotPassword')}</span>
				</div>
				{wait && <WaitTimer time={this.status.waitUntil} onTimeout={this.onWaitTimeout} />}
				<div className='copyright'>© 2015-{yearNow} ООО «Смарт Юнит»</div>
			</>
		);
	}
}

