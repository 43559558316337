import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Edit from './Edit';
import List from './List';

export const MaterialTypes = ({ path, ...otherProps }) => (
	<Switch>
		<Route path={path} exact children={({ match }) => <List match={match} {...otherProps} />} />
		<Route path={`${path}/page/:page`} children={({ match }) => <List match={match} {...otherProps} />} />
		<Route path={`${path}/:id`} children={({ match }) => <Edit match={match} path={path} {...otherProps} />} />
	</Switch>
);
