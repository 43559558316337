import { Column, DatePicker, NumberInput, RecordSelect, RelationSelect, Table } from '@smartplatform/ui';
import { inject, observer } from 'mobx-react';
import React from 'react';
import t from 'i18n';
import { AddCircleButton, DeleteIconButton } from 'components';
import store from 'client/store';
import classNames from 'classnames';

@inject('editStore')
@observer
export class SplitPaymentTable extends React.Component {
	renderAction = (child, index) => <DeleteIconButton onConfirm={() => this.props.editStore.deleteChild(child, index)} noBorder />;

	render() {
		const { children, addChild, calcTotal } = this.props.editStore;
		return (
			<div className='split-table'>
				<Table rows={children}>
					<Column property='date' label={t('payment.date')} width={100}>
						<DatePicker showClearButton={false} />
					</Column>
					<Column property='total' label={<span className='isRequired'>{t('payment.total')}</span>} width={120}>
						{({ record }) => {
							const isWrongTotal = !record.total;
							const className = classNames('total', { 'wrong-total': isWrongTotal });
							return (
								<NumberInput
									positiveOnly
									className={className}
									value={record.total}
									onChange={(value) => {
										record.total = value;
										calcTotal();
									}}
								/>
							);
						}}
					</Column>
					<Column relation='contragent' label={<span className='isRequired'>{t('payment.contragent')}</span>}>
						<RelationSelect property='name' />
					</Column>
					<Column relation='cashFlow' label={<span className='isRequired'>{t('cashFlow.shortTitle')}</span>}>
						<RelationSelect property='name' />
					</Column>
					<Column relation='project' label={t('project.title')}>
						<RelationSelect property='name' />
					</Column>
					<Column width={50} computed={this.renderAction} className='action' />
				</Table>
				<AddCircleButton onClick={addChild} />
			</div>
		);
	}
}

