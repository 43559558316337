import { Column } from '@smartplatform/ui';
import store from 'client/store';
import { ListStore, BasicList } from 'components';
import t from 'i18n';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

@observer
export class List extends Component {
	render() {
		const { modelName, title, relation, relationLabel } = this.props;

		return (
			<>
				<BasicList model={store.model[modelName]} title={title} filter={{ include: relation }} toolbarButtonText={t('process.add')}>
					<Column property='name' label={t('name')} />
					<Column relation={relation} property='name' label={t(relationLabel)} />
				</BasicList>
			</>
		);
	}
}

