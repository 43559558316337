import { Column, NumberInput, RelationSelect, Table } from '@smartplatform/ui';
import { inject, observer } from 'mobx-react';
import React from 'react';
import t from 'i18n';
import { AddCircleButton, DeleteIconButton } from 'components';
import './movement.scss';

@inject('store')
@observer
export class NomenclatureMovementTable extends React.Component {

	renderAction = (line, index) => <DeleteIconButton onConfirm={() => this.props.store.deleteMovementLine(line, index)} noBorder />;

	render() {
		const { addMovementLine, movementLines, id } = this.props.store;

		return (
			<div className='movement-table'>
				<Table rows={movementLines}>
					<Column relation='nomenclature' label={<span className='isRequired'>{t('nomenclature.title')}</span>} width={400}>
						<RelationSelect property='name' />
					</Column>
					<Column property='quantity' label={<span className='isRequired'>{t('nomenclature.quantity')}</span>} saveOnBlur={false}  width={150}>
						<NumberInput />
					</Column>
					<Column property='price' label={t('nomenclatureMovement.price')} saveOnBlur={false} width={150}>
						<NumberInput />
					</Column>
					<Column relation='currency' label={t('nomenclatureMovement.currency')} width={150}>
						<RelationSelect property='name' />
					</Column>
					{!id && (<Column width={20} computed={this.renderAction} className='action' />)}
				</Table>
				{!id && (<AddCircleButton onClick={addMovementLine} />)}
			</div>
		);
	}
}