import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Field, MaskedInput, Row } from '@smartplatform/ui';
import { Editor, Form } from 'components';
import store from 'client/store';

import t from 'i18n';
import './feedback.scss';

@observer
export class Feedback extends Component {
	@observable newFeedback = null;
	@observable isSend = false;
	@observable isLoading = false;
	@observable error = null;
	@observable attachmentIds = [];
	constructor(props) {
		super(props);
		store.ui.title = t('feedback.title');
		this.addNewFeedback();
	}

	addNewFeedback = () => {
		this.newFeedback = new store.model.Feedback();
		if(store.model.user.id) {
			this.newFeedback.email = store.model.user?.email;
			this.newFeedback.phone = store.model.user?.phone;
		}
	}

	onEditorInit = (editor) => {
		this.editor = editor;
	};

	onTextChange = (value) => {
		this.newFeedback.description = value;
	};

	handleEnd = (error, result, media, success, failure) => {
		if (error) {
			console.warn('upload error:', error);
			failure(error.message);
		} else {
			const url = 'cid:' + `${media.id}-${media.filename}`;
			this.attachmentIds.push(media.id);
			success(url);
		}
	};

	imagesUploadHandler = (blobInfo, success, failure) => {
		const file = blobInfo.blob();
		const filename = blobInfo.filename();
		const media = new store.model.Attachment();

		media.filename = filename;
		media.inline = true;
		media.save().then(() => {
			media
				.uploadFile(media.filename, file)
				.on('progress', (event) => {})
				.end((error, result) => this.handleEnd(error, result, media, success, failure));
		});
	}

	beforeSave = () => {
		const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
		this.isLoading = true;

		if (EMAIL_REGEXP.test(this.newFeedback.email)) {
			this.error = null;
			return true;
		} else {
			this.error = t('feedback.invalidEmail');
			this.isLoading = false;
			return false;
		}
	}

	afterSave = async () => {
		this.attachmentIds.map(async (attach) => {
			await this.newFeedback.attachments.add(attach);
		});
		const resultSend = await this.newFeedback.sendMessage();
		console.log(resultSend);

		this.isSend = true;
		this.isLoading = false;
	}

	render() {

		return (
			<>
				{!this.isSend ? (
					<>
						<Form
							record={this.newFeedback}
							saveTitle={t('feedback.send')}
							onSave={this.afterSave}
							beforeSave={this.beforeSave}
							disableSave={!this.newFeedback.phone || !this.newFeedback.email || !this.newFeedback.subject || !this.newFeedback.description || this.isLoading}
							stay
							noCancel
							noDelete
						>
							<Row>
								<Field property='phone' label={t('feedback.phone')}>
									<MaskedInput mask='+7 (999) 999 99-99' />
								</Field>
								<Field property='email' className='feedback-email-input' label={t('feedback.email')} />
								<Field property='subject' label={t('feedback.subject')} />
							</Row>
							<div className='description-wrapper'>
								<Field property='description' label={t('feedback.description')}>
									<Editor
										onChange={this.onTextChange}
										onInit={this.onEditorInit}
										automaticUploads={false}
										autoResize
										height={250}
										menubar={false}
										toolbar='undo redo | bold italic | alignleft aligncenter alignjustify alignright | numlist bullist | blockquote | link image'
										imagesUploadHandler={this.imagesUploadHandler}
									/>
								</Field>
							</div>
						</Form>
						{this.error && (
							<div className='feedback-error'>
								{this.error}
							</div>
						)}
					</>
				) : (
					<div className='feedback-notice'>
						{(t('feedback.sent'))}
					</div>
				)}
			</>
		);
	}
}

