import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { TechRoadMapList } from './TechRoadMapList';
import { TechRoadMapEdit } from './TechRoadMapEdit';
import store from 'client/store';
import t from 'i18n';

const TechnicalRoadmap = () => {
	store.ui.title = t('techRoadMap.title');

	return (
		<Switch>
			<Route path='/technicalroadmap' exact children={({ match }) => <TechRoadMapList match={match} />} />
			<Route path='/technicalroadmap/create' children={({ match }) => <TechRoadMapEdit match={match} />} />
			<Route path='/technicalroadmap/:id' children={({ match }) => <TechRoadMapEdit match={match} />} />
		</Switch>
	);
};

export default TechnicalRoadmap;
