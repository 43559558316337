import React from 'react';
import t from 'i18n';
import { faUserGear, faIdCard, faHandshake, faUsers, faCog } from '@fortawesome/free-solid-svg-icons';

import UserIcon from 'client/img/sidebar/userperson.svg';
import RoleMappingIcon from 'client/img/sidebar/union.svg';

const checkAccess = ({ READ = [], WRITE = [] }) => {
	if (typeof READ === 'string') READ = [READ];
	if (typeof WRITE === 'string') WRITE = [WRITE];

	return (store) => {
		const isWriteAllowed = !!WRITE.find((modelName) => store.model[modelName]?.INFO.WRITE);
		const isReadAllowed = !!READ.find((modelName) => store.model[modelName]?.INFO.READ);
		return isWriteAllowed && isReadAllowed;
	};
};

export default [
	{
		type: 'submenu',
		id: 'contragents',
		label: t('contragent.plural'),
		enabled: checkAccess({ WRITE: 'Contragent', READ: 'Contragent' }),
		items: [
			{
				type: 'item',
				path: '/contragents',
				label: 'Контрагенты',
				icon: faHandshake,
				enabled: (store) => store.model.Contragent && store.model.Contragent.INFO.WRITE,
			},
			{
				type: 'item',
				path: '/contragenttypes',
				label: t('contragentType.plural'),
				icon: faUsers,
				enabled: (store) => store.model.Contragent && store.model.Contragent.INFO.WRITE,
			},
		],
	},
	{
		type: 'submenu',
		id: 'admin',
		label: t('administration'),
		enabled: checkAccess({ WRITE: 'ACL', READ: 'ACL' }),
		items: [
			{
				type: 'item',
				label: t('users'),
				path: '/users',
				reactIcon: <UserIcon />,
			},
			{
				type: 'item',
				label: t('roles'),
				path: '/roles',
				icon: faUserGear,
			},
			{
				type: 'item',
				path: '/acls',
				label: t('acls'),
				icon: faIdCard,
			},
			{
				type: 'item',
				path: '/rolemappings',
				label: t('roleMappings'),
				reactIcon: <RoleMappingIcon />,
			},
			{
				type: 'item',
				path: '/app-settings',
				label: t('appSettings'),
				icon: faCog,
			},
		],
	},
];

