import React from 'react';
import { observer } from 'mobx-react';
import { ThemeToggle } from './ThemeToggle';
import t from 'i18n';

@observer
export default class Footer extends React.Component {
	render() {
		return (
			<div className='footer'>
				<div className='theme'>
					<span>{t('selectTheme')}</span>
					<ThemeToggle />
				</div>
				<div className='copyright'>
					<div className='copyright-version'>
						{VERSION} ({BUILD})
					</div>
					<div className='copyright-bot'>© SmartUnit LLC {new Date().getFullYear()}</div>
				</div>
			</div>
		);
	}
}
