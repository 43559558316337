import React, { Component } from 'react';
import { Column, Table } from '@smartplatform/ui';
import { DeleteIconButton, BasicList } from 'components';
import { observer } from 'mobx-react';
import { renderUser } from 'client/tools';
import store from 'client/store';
import t from 'i18n';

@observer
export class WarehousesList extends Component {
	init;

	getInitFunction = (init) => {
		this.init = init;
	};

	onDelete = async (record) => {
		await record.delete();
		this.init();
	};

	onRowClick = (record) => store.route.push({ path: `/warehouses/${record.id}` });

	renderDeleteButton = ({ record }) => <DeleteIconButton onConfirm={() => this.onDelete(record)} />;

	render() {
		return (
			<BasicList
				model={store.model.Warehouse}
				path={this.props.path}
				filter={{ include: ['owner', 'contragent'] }}
				getInit={this.getInitFunction}
				onRowClick={this.onRowClick}
				rowHeight={56}
			>
				<Column property='name' label={t('name')} />
				<Column property='address' label={t('warehouse.address')} width={200} />
				<Column property='phone' label={t('warehouse.phone')} width={200} />
				<Column property='dopinfo' label={t('warehouse.dopinfo')} width={200} />
				<Column relation='user' computed={(r) => renderUser(r, 30)} label={t('warehouse.responsible')} />
				<Column relation='contragent' property='name' label={t('client.plural')} />
				<Column width={50}>{(record) => this.renderDeleteButton(record)}</Column>
			</BasicList>
		);
	}
}

