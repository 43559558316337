export default {
	translation: {
		quantity: 'Количество',
		changeDescription: 'Изменить описание',
		noStatus: 'Без статуса',
		watchAll: 'Смотреть все',
		performanceIndicator: 'КПЭ',
		change: 'Изменить',
		mine: 'Мои',
		show: 'Показать',
		nobody: 'Никто',
		reset: 'Сброс',
		typeText: 'Введите текст',
		recover: 'Восстановить',
		code: 'Код',
		appSettings: 'Настройки приложения',
		any: 'Любой',
		article: 'Артикул',
		selectAll: 'Выбрать все',
		clearAll: 'Убрать все',
		overdueNotClosed: 'Просрочено (не закрыты)',
		overdueNotClosedPopover: 'Количество просроченных задач, находящихся в работе',
		completedTasksPopover: 'Общее количество, завершенных задач в выбранном периоде',
		overdueClosed: 'Просрочено (закрыты)',
		overdueClosedPopover: 'Количество задач, завершенных не вовремя в выбранном периоде',
		notOverdue: 'Не просрочено',
		dueDateNow: 'Истекает сегодня',
		dueDateNowPopover: 'Количество задач, срок выполнения которых истекает сегодня',
		perfomanceAllTaskPopover: 'Количество задач, созданных в выбранном периоде',
		state: 'Состояние',
		responsible: 'Ответственный',
		selectLists: 'Назначить состояния',
		searchName: 'Поиск по наименованию',
		confirm: 'Подтвердить',
		selectTheme: 'Выбрать тему',
		address: 'Адрес',
		noSelectedLists: 'У вас нету списка состояния задач',
		selected: 'Выбрано',
		chart: 'График',
		select: 'Выберите из списка',
		export: 'Экспорт',
		author: 'Автор',
		telegram: 'Telegram',
		import: 'Импорт',
		clean: 'Сбросить',
		actions: 'Действия',
		noProjects: 'Не заданы проекты',
		all: 'Все',
		day: 'День',
		week: 'Неделя',
		currentWeek: 'Текущая неделя',
		weeks: 'Недели',
		monthes: 'Месяцы',
		currentMonth: 'Текущий месяц',
		month: 'Месяц',
		year: 'Год',
		quarter: 'Квартал',
		from: 'От',
		to: 'до',
		defaultTitle: 'SmartERP',
		name: 'Название',
		number: 'Номер',
		fio: 'ФИО',
		members: 'Участники',
		unnamed: 'Без названия',
		type: 'Тип',
		firstName: 'Имя',
		lastName: 'Фамилия',
		middleName: 'Отчество',
		fullName: 'Ф.И.О.',
		doubleClickToEdit: 'Двойной щелчок для редактирования',
		description: 'Описание',
		addDescription: 'Добавить описание',
		add: 'Добавить',
		finance: 'Финансы',
		loading: 'Загрузка...',
		information: 'Информация',
		toAttachFile: 'Прикрепить файл',
		attachments: 'Прикрепленные файлы',
		files: 'Файлы',
		uploadFiles: 'Загрузить файлы',
		deleteFile: 'Удалить файл',
		deleteCategory: 'Удалить категорию?',
		uploadingFiles: 'Загружаемые файлы',
		uploadError: 'Ошибка загрузки',
		uploadingFile: 'Загрузка файла',
		uploadSuccess: 'Загрузка завершена',
		rejectedFiles: 'Загрузка запрещена',
		selectedCount: 'Выбрано элементов',
		dragDropHint: 'Перетащите сюда файлы или нажмите чтобы выбрать файлы',
		editHint: 'Двойной клик для редактирования',
		editHintMobile: 'Долгий тап для редактирования',
		management: 'Управление',
		sum: 'Сумма',
		tax: 'Налог',
		date: 'Дата',
		rub: '₽',
		administration: 'Администрирование',
		usersAndRoles: 'Пользователи и права',
		settings: 'Настройки',
		percent: 'Процент',
		price: 'Цена',
		paid: 'Оплачено',
		phone: 'Телефон',
		create: 'Создать',
		search: 'Поиск',
		more: 'еще',
		moreEllipsis: 'Еще...',
		next: 'дальше',
		nextEllipsis: 'Дальше...',
		notFound: 'Не найдено',
		nothingFound: 'Ничего не найдено',
		edit: 'Редактировать',
		save: 'Сохранить',
		saving: 'Сохранение...',
		saved: 'Сохранено',
		savedAt: 'Сохранено в',
		cancel: 'Отмена',
		delete: 'Удалить',
		clear: 'Очистить',
		confirmDelete: 'Удалить?',
		deleting: 'Удаление...',
		deleted: 'Удалено',
		updatedAt: 'Обновлено',
		error: 'Ошибка',
		ok: 'ОК',
		yes: 'Да',
		no: 'Нет',
		projects: 'Проекты',
		board: 'Задачи',
		agent: 'Агент',
		clients: 'Клиенты',
		persons: 'Персоны',
		noSelected: 'не выбрано',
		noCreate: 'создание объекта здесь не разрешено',
		payments: 'Платежи',
		paymentInfo: 'Платежные реквизиты',
		realm: 'Область',
		status: 'Статус',
		color: 'Цвет',
		image: 'Изображение',
		avatar: 'Аватар',
		notSet: 'Не задано',
		priority: 'Приоритет',
		created: 'Создано',
		telegram: {
			title: 'Telegram',
			token: 'Токен бота',
			bot: 'Имя бота',
			proxy: 'Прокси',
		},
		// modify: 'Изменить',
		modify: {
			title: 'Изменить',
			description: 'Изменить описание',
		},
		modified: 'Изменено',
		username: 'Пользователь',
		users: 'Пользователи',
		executor: 'Исполнитель',
		roles: 'Роли',
		acls: 'Права',
		perPage: 30,
		itemsPerPage: 'Кол-во на странице',
		invalidId: 'Некорректный ID',
		modelAccessDenied: 'Нет доступа к модели',
		private: 'Приватность',
		startDate: 'Начало',
		dueDate: 'Завершение',
		roleMappings: 'Назначения ролей',

		'Element does not exist': 'Объект не найден',
		'Element updated': 'Объект обновален',
		'Element created': 'Объект создан',
		'Element deleted': 'Объект удален',
		percentLeft: 'Доля компании',
		authorization: 'Авторизация',
		login: 'Войти',
		logout: 'Выйти',
		LOGIN_FAILED: 'Неверный логин/пароль',
		LOGIN_WAIT: 'Превышен лимит попыток',
		USER_BLOCKED: 'Учетная запись заблокирована',
		NO_SESSION_CAPTCHA: 'Не сгенерирован новый код',
		EMPTY_CAPTCHA: 'Не введен код',
		INVALID_CAPTCHA: 'Неверный код',
		AUTHORIZATION_REQUIRED: 'Необходимо авторизоваться',
		EMAIL_NOT_FOUND: 'Email не найден',
		Unauthorized: 'Необходимо авторизоваться',
		required: 'Необходимо заполнить поле',
		datePickerPlaceholder: 'ДД.ММ.ГГГГ',
		apply: 'Применить',
		feedback: {
			title: 'Форма обратной связи',
			new: 'Новое обращение',
			phone: 'Номер телефона',
			email: 'Электронная почта',
			subject: 'Тема письма',
			send: 'Отправить',
			description: 'Описание',
			sent: 'Ваше обращение зарегистрировано!',
			invalidEmail: 'Некорректный E-mail',
		},
		states: {
			new: 'Новое состояние',
		},
		parameters: {
			new: 'Новый параметр',
		},
		dashBoard: 'Главная',
		datePeriod: {
			select: 'Выберите период',
			specified: 'Произвольный период',
			currentMonth: 'Текущий месяц',
			currentQuarter: 'Текущий квартал',
			currentYear: 'Текущий год',
			lastMonth: 'Прошлый месяц',
			lastQuarter: 'Прошлый квартал',
			lastYear: 'Прошлый год',
			all: 'Всё время',
		},
		auth: {
			TOKEN_ROTTEN: 'Срок действия токена истек',
			title: 'Авторизация',
			login: 'Вход в аккаунт',
			logout: 'Выйти',
			goBack: 'Назад',
			register: 'Зарегистрироваться',
			restore: 'Восстановление пароля',
			next: 'Далее',
			email: 'E-mail',
			lastName: 'Фамилия',
			firstName: 'Имя',
			middleName: 'Отчество',
			password: 'Пароль',
			order: 'Получить доступ',
			enterEmail: 'Введите E-mail',
			enterLogin: 'Введите логин',
			enterPassword: 'Введите пароль',
			repeatPassword: 'Повторите пароль',
			enterRepeatedPassword: 'Повторите пароль',
			repeat: 'Повторите',
			minPasswordLength: 'Минимальная длина пароля',
			invalidEmail: 'Некорректный E-mail',
			emailExists: 'Такой E-mail уже зарегистрирован',
			registrationSuccess: 'На ваш E-mail ($email) была выслана ссылка для подтверждения регистрации.',
			noMatch: 'Пароли не совпадают',
			enterCode: 'Введите код',
			forgotPassword: 'Забыли пароль?',
			recover: 'Восстановить',
			required: 'Необходимо заполнить поле',
			wait: 'Подождите',
			seconds: 'секунд',
			limitExceeded: 'Превышен лимит попыток',
			tryAgain: 'Введите логин / пароль',
			registration: 'Регистрация',
			captcha: 'Код с картинки',
		},
		placeholder: {
			search: 'Поиск...',
		},
		email: {
			title: 'E-mail',
			hint: 'mail@example.com',
			field: 'Электронная почта',
		},
		sign: {
			in: 'Авторизация',
			out: 'Выход',
		},
		password: {
			changed: 'Пароль изменен',
			hint: 'Пароль...',
			field: 'Пароль',
			input: 'Введите пароль',
			repeat: 'Повторите пароль',
			newPasswordHint: 'Оставьте это поле пустым, чтобы не менять пароль',
			empty: 'Введите пароль в оба поля',
			noMatch: 'Пароли не совпадают',
			match: 'Пароли совпадают',
			new: 'Новый пароль',
		},
		editor: {
			enterURL: 'Введите URL:',
		},
		document: {
			title: 'Документ',
			new: 'Новый документ',
			plural: 'Документы',
			moduleTitle: 'Документооборот',
			search: 'Фильтр + поиск по наименованию',
			total: 'Всего документов',
			totalSum: 'Сумма',
			card: {
				name: 'Наименование',
				number: 'Номер',
				type: 'Тип',
				status: 'Статус',
				contragents: 'Контрагенты',
				labels: 'Метки',
				responsible: 'Ответственный',
				totalSum: 'Сумма',
				project: 'Проект',
				addName: 'Введите название документа',
				addNumber: 'Введите номер',
				addSum: 'Введите сумму',
				clientNotFound: 'Клиент не найден',
				tabs: {
					info: 'Общая информация',
					projects: 'Проекты',
				},
			},
		},
		documentType: {
			title: 'Тип документа',
			plural: 'Типы документов',
			new: 'Новый тип документа',
			delete: 'Удалить тип',
		},
		documentStatus: {
			title: 'Статус документа',
			plural: 'Статусы документов',
			new: 'Новый статус документа',
			delete: 'Удалить статус',
		},
		category: {
			new: 'Новая категория',
			total: 'Всего категорий',
			title: 'Категория',
			plural: 'Категории',
			name: 'Название',
			parent: 'Родительская категория',
		},
		project: {
			goBack: 'Вернуться назад к проектам',
			select: 'Выберите проект из списка',
			creator: 'Автор проекта',
			title: 'Проект',
			plural: 'Проекты',
			createNew: 'Создать новый проект',
			created: 'Проект создан:',
			addRole: 'Добавить участника',
			addTask: 'Добавить задачу',
			dueDate: 'Дедлайн проекта',
			labels: 'Метки проекта',
			name: 'Название проекта',
			addName: 'Введите название проекта',
			description: 'Описание проекта',
			projectList: 'Состояние проекта:',
			projectListShort: 'Состояние',
			tasks: 'Задачи',
			lists: 'Состояния задач',
			members: 'Участники',
			history: 'История проекта',
			info: 'Информация',
			deleteMsg: 'Вы собираетесь удалить проект! Вы уверены?',
			notFound: 'Проект не найден',
			listMode: 'Список',
			kanbanMode: 'Канбан',
			count: 'Проектов',
			tasksCount: 'Задач',
			openedCount: 'Открытых',
			closedCount: 'Закрытых',
			filterHint: 'Поиск по id, названию',
			search: 'Поиск проекта',
			total: 'Всего проектов',
			mode: 'Вид',
			all: 'Все проекты',
			active: 'Активные',
			closed: 'Закрытые',
			sortByUpdated: 'Недавно обновленные',
			sortByNew: 'Сначала новые',
			owner: 'Проект создал(а)',
			searchByMember: 'Поиск по участнику',
			delete: 'Удалить проект',
			startDate: 'Начало проекта',
			new: 'Новый проект',
			taskList: 'Состояния задач',
			memberExist: 'Такой пользователь уже является участником проекта. Обновите страницу',
			mine: 'Мои проекты',
		},
		client: {
			title: 'Контрагент',
			plural: 'Контрагенты',
			create: 'Новый контрагент',
			personName: 'Контактное лицо',
			personRole: 'Должность',
			contacts: 'Контактные лица',
			addContact: 'Добавить',
			orders: 'Заказы контрагента',
			removeContact: 'Убрать из контактных лиц контрагента?',
			removeContactWarning:
				'Убрать из контактных лиц контрагента?\nЭто также уберет этот контакт из всех заказов этого контрагента.\nЗаказов с этим контактом: ',
			noContacts: 'Контактные лица не найдены',
			onlyAvailable: 'Только контактные лица контрагента',
			total: 'Всего контрагентов',
		},
		contact: {
			searchHint: 'Поиск по Ф.И.О. / логину / email',
			new: 'Новый контакт',
			emptyName: 'Заполните хотя бы одно поле (фамилия / имя / отчество / логин)',
			add: 'Добавить контактное лицо',
		},
		person: {
			title: 'Персона',
			plural: 'Персоны',
			create: 'Новая персона',
			choose: 'Выберите персону',
		},
		payment: {
			title: 'Платеж',
			plural: 'Платежи',
			contragent: 'Контрагент',
			date: 'Дата оплаты',
			total: 'Сумма',
			payer: 'Отправитель',
			recipient: 'Получатель',
			vat: 'Сумма НДС',
			vatRate: 'Ставка НДС',
			payerText: 'Текст отправителя',
			recipientText: 'Текст получателя',
			selectProject: 'Выберите проект',
			selectPayer: 'Выберите отправителя',
			selectRecipient: 'Выберите получателя',
			selectBankAccount: 'Выберите счёт',
			selectCashFlow: 'Выберите статью',
			selectCurrency: 'Выберите валюту',
			selectContragent: 'Выберите контрагента',
			selectDirection: 'Выберите направление',
			allContragents: 'Все контрагенты',
			allDirections: 'Все направления',
			selectedContragents: 'Выбрано контрагентов',
			selectedBankAccounts: 'Выбрано счетов',
			selectedCashFlows: 'Выбрано статей',
			selectedProjects: 'Выбрано проектов',
			selectedDirections: 'Выбрано направлений',
			direction: 'Направление',
			bankAccount: 'Счет',
			recipientBankAccount: 'Банковский счет получателя',
			payerBankAccount: 'Банковский счет отправителя',
			number: 'Номер',
			paymentId: 'Идентификатор',
			purpose: 'Назначение',
			priority: 'Приоритет',
			responsiblePerson: 'Ответственное лицо',
			providerStatus: 'Статус составителя',
			KBKindicator: 'Показатель КБК',
			okato: 'ОКАТО',
			baseIndicator: 'Показатель основания',
			periodIndicator: 'Показатель периода',
			numberIndicator: 'Показатель номера',
			dateIndicator: 'Показатель даты',
			typeIndicator: 'Показатель типа',
			budgetTransfer: 'Перечисления в бюджет',
			paid: 'Оплачено',
			contract: 'Договор',
			allBankAccounts: 'Все счета',
			allCashFlows: 'Все статьи',
			allProjects: 'Все проекты',
			income: 'Приход',
			costs: 'Расход',
			transfer: 'Перемещение',
			addIncome: 'Добавить операцию дохода',
			addCosts: 'Добавить операцию расхода',
			addTransfer: 'Добавить перевод',
			add: 'Добавить новую операцию',
			edit: 'Карточка операции',
			transferCashFlow: 'Перевод между счетами',
			plan: 'Плановые',
			fact: 'Фактические',
			balance: 'Баланс',
			all: 'Все',
			bankAccountFrom: 'Со счета',
			bankAccountTo: 'На счет',
			billingDate: 'Дата начисления',
			splitTotal: 'Разбить платеж',
			delete: 'Удалить операцию',
		},
		paymentDirection: {
			title: 'Направление платежа',
			plural: 'Направления платежа',
			create: 'Новое направление платежа',
			delete: 'Удалить направление',
		},
		contract: {
			title: 'Договор',
			plural: 'Договора',
			date: 'Дата',
			contragent: 'Контрагент',
			organization: 'Оргиназиация',
			name: 'Наименование',
			number: 'Номер',
			contractType: 'Тип',
			conventionalUnit: 'Расчеты в условных единицах',
		},
		projectRole: {
			title: 'Роль в проекте',
			plural: 'Роли в проектах',
			short: 'Роль',
			new: 'Новая роль в проекте',
			choose: 'Выберите роль в проекте',
			delete: 'Удалить роль',
		},
		user: {
			allExecutors: 'Все исполнители',
			selectedExecutors: 'Выбрано исполнителей',
			allAuthors: 'Все авторы',
			selectedAuthors: 'Выбрано авторов',
			allMembers: 'Все участники',
			selectedMembers: 'Выбрано участников',
			recoverEmailSended: 'На указанный Вами адрес эл.почты отправлено письмо с инструкциями для восстановления пароля',
			isBlocked: 'Заблокирован',
			title: 'Пользователь',
			plural: 'Пользователи',
			new: 'Новый пользователь',
			phone: 'Телефон',
			email: 'E-mail',
			emptyEmail: 'Введите E-mail',
			invalidEmail: 'Некорректный E-mail',
			emailVerified: 'E-mail подтвержден',
			name: 'Логин',
			loginOrEmail: 'Логин / E-mail',
			password: 'Пароль',
			firstName: 'Имя',
			lastName: 'Фамилия',
			middleName: 'Отчество',
			profile: 'Профиль',
			leftComment: 'оставил(а) комментарий',
			createdTask: 'создал(а) задачу',
			telegram: 'Telegram ID',
			deletedUser: 'Неизвестный пользователь',
			emailAlreadyExists: 'Пользователь с таким E-mail уже существует',
			all: 'Все пользователи',
		},
		profile: {
			enterName: 'Введите имя',
			enterLastName: 'Введите фамилию',
			enterMiddleName: 'Введите отчество',
			phone: 'Номер телефона',
			enterId: 'Введите ID',
			title: 'Настройки профиля',
			addAvatar: 'Добавление фотографии',
			selectPicture: 'Выбрать фото',
			blocked: 'Вы собираетесь заблокировать учетную запись! Вы уверены?',
			notBlocked: 'Вы собираетесь разблокировать учетную запись! Вы уверены?',
		},
		role: {
			title: 'Роль',
			plural: 'Роли',
			new: 'Новая роль',
			name: 'Название',
			description: 'Описание',
		},
		acl: {
			title: 'Право',
			plural: 'Права',
			create: 'Новое право',
			principalType: 'Вид субъекта',
			app: 'Приложение',
			role: 'Роль',
			user: 'Пользователь',
			accessType: 'Действие',
			read: 'Чтение',
			write: 'Запись',
			execute: 'Выполнение',
			all: 'Все',
			principalId: 'Субъект',
			model: 'Модель',
			permission: 'Право',
			allow: 'Разрешить',
			deny: 'Запретить',
			property: 'Свойство',
		},
		roleMapping: {
			title: 'Назначение роли',
			plural: 'Назначенные роли',
			create: 'Новое назначение роли',
		},
		projectMembers: 'Участники проекта',
		projectMember: {
			title: 'Участник',
			plural: 'Участники',
			create: 'Новый участник',
			choose: 'Выберите или персону или роль в проекте',
			same: 'Такой участник проекта уже есть',
			comment: 'Комментарий',
			search: 'Поиск по ФИО участника, названию роли',
		},
		labels: 'Метки',
		label: {
			title: 'Метка',
			plural: 'Метки',
			new: 'Новыя метка',
			delete: 'Удалить метку',
		},
		tasks: 'Задачи',
		task: {
			overDue: 'Просроченные задачи',
			mine: 'Мои задачи',
			mineOverdueTasks: 'Мои просроченные задачи',
			overview: 'Обзор задач',
			otherLists: 'Другие',
			any: 'Любой',
			title: 'Задача',
			plural: 'Задачи',
			create: 'Новая задача',
			delete: 'Удалить задачу',
			confirmDelete: 'Вы уверены?',
			name: 'Название задачи',
			description: 'Описание задачи',
			changeDescription: 'Изменить описание',
			hasDescription: 'У этой задачи есть описание',
			createDescription: 'Добавить описание',
			priority: 'Приоритет',
			person: 'Назначена',
			startDate: 'Начало задачи',
			dueDate: 'Дедлайн',
			assignedTo: 'Назначено',
			notAssigned: 'Не назначена',
			actions: 'Действия с задачей',
			labels: 'Метки задачи',
			updatedAt: 'Обновлено',
			closed: 'Закрытые',
			active: 'Активные',
			status: 'Состояние',
			boardList: 'Состояние задачи',
			all: 'Все',
			allAuthor: 'Любой автор',
			private: 'Приватная',
			subject: 'задачу',
			noProject: 'Задача без проекта!',
			sortByUpdated: 'Сначала новые',
			total: 'Всего задач',
			notFound: 'Задача не найдена',
			addDescription: 'Добавьте описание к задаче',
			assignedToMe: 'Назначено на меня',
			addName: 'Введите название задачи',
			iAuthor: 'Я автор',
			meTaskMember: 'Я участник задачи',
			selectedTaskMembers: 'Выбрано участников:',
			me: 'Я',
		},
		history: {
			title: 'История задачи',
			created: 'создал(а) задачу',
			comments: 'Комментарии',
			leftComment: 'оставил(а) комментарий',
			updated: 'обновил(а)',
			appointed: 'назначил(а) задачу на',
			appointedSelf: 'назначил(а) задачу на себя',
			appointedNull: 'убрал(а) назначение задачи',
			status: 'перевел(а) задачу в статус',
			priority: 'изменил(а) приоритет задачи',
			private: 'сделал(а) задачу приватной',
			public: 'сделал(а) задачу публичной',
			startDate: 'установил(а) начало задачи на',
			dueDate: 'установил(а) дедлайн на',
			initial: 'изначальная версия',
			prev: 'предыдущая версия',
			updatedTo: 'обновленная версия',
			deleteFile: 'удалил(а) файл',
			empty: 'не задано',
			nulled: 'значение удалено',
			addLabel: 'добавил(а) метку',
			removeLabel: 'удалил(а) метку',
			removeDueDate: 'убрал(а) дедлайн',
			removeStartDate: 'убрал(а) начало задачи',
			project: 'изменил(а) проект на',
			taskMemberAdded: 'добавил(а) в участники задачи ',
			taskMemberSelfAdded: 'добавил(а) себя в участников задачи',
			taskMemberRemoved: 'убрал(а) из участников задачи ',
			taskMemberSelfRemoved: 'убрал(а) себя из участников задачи',
			memberStatus: 'изменил(а) статус участника(цы)',
			memberStatusSelfChange: 'изменил(а) свой статус участника ',
			to: 'на',
		},
		boardLists: 'Состояния задач',
		boardList: {
			title: 'Состояние задачи',
			plural: 'Состояния задач',
			new: 'Новое состояние задачи',
			name: 'Название состояния',
			selectListToMove: 'Куда перенести задачи из этого списка?',
			moveTo: 'Перенести задачи в ',
			choose: 'Выберите список',
			movingTasksTo: 'Идет перемещение задач в список: ',
			closing: 'Закрывающий',
			closingShort: 'Закрывающий',
			all: 'Все состояния задач',
			selected: 'Выбрано состояний',
			projectDefault: 'По умолчанию в проекте',
			default: 'Состояние задачи по умолчанию',
			delete: 'Удалить состояние',
			deleteInvalidMsg: 'Невозможно удалить, пока есть задача с данным состоянием',
			search: 'Поиск состояния',
		},
		projectLists: 'Состояния проектов',
		projectList: {
			title: 'Состояние проекта',
			plural: 'Состояния проекта',
			new: 'Новое состояние проекта',
			name: 'Название состояния',
			closing: 'Закрывающий',
			default: 'По умолчанию',
			delete: 'Удалить состояние',
			all: 'Все состояния проектов',
			selected: 'Выбрано состояний',
			deleteInvalidMsg: 'Невозможно удалить, пока есть проекты с данным состоянием',
		},
		taskLabel: {
			title: 'Метка задачи',
			labels: 'Метки задачи',
			plural: 'Метки задач',
			create: 'Новая метка задачи',
		},
		file: {
			uploadFile: 'Загрузить файл',
			uploadImage: 'Загрузить изображение',
			uploadStart: 'начинаю загрузку',
			uploadError: 'Ошибка загрузки',
			fileTooBig: 'Слишком большой файл',
			uploading: 'загрузка',
			uploadComplete: 'загрузка завершена',
			notSelected: 'файл не выбран',
			willBeUploaded: 'Будет загружен',
			wrongType: 'Неверный тип файла',
		},
		validate: {
			empty: 'Пустое значение',
		},
		kanban: {
			searchHint: 'Поиск по номеру, названию, Ф.И.О., меткам',
			orderByPriority: 'По умолчанию',
			orderByUpdate: 'По дате обновления',
			orderByDueDate: 'По дате дедлайна',
			orderByUpdateInfo: 'При сортировке по дате обновления\\nперетаскивать задачу можно только вверх списка',
			orderByDueDateInfo: 'При сортировке по дате дедлайна\\nперетаскивать задачи нельзя',
		},
		analytics: {
			title: 'Аналитика',
			plural: 'Аналитика',
			projects: 'По проектам',
		},
		comment: {
			title: 'Комментарий',
			postTitle: 'Написать комментарий',
			placeholder: 'Текст комментария',
			post: 'Отправить',
			confirmDelete: 'Удалить комментарий?',
		},
		filter: {
			all: 'все',
			none: 'нет',
			equals: 'равно',
			notEquals: 'не равно',
			greater: 'больше чем',
			greaterOrEqual: 'больше или равно',
			less: 'меньше чем',
			lessOrEqual: 'меньше или равно',
			contains: 'содержит',
			doesNotContain: 'не содержит',
			startsWith: 'начинается с',
			endsWith: 'заканчивается на',
		},
		ui: {
			theme: 'Тема',
			themes: {
				default: 'По умолчанию',
				dark: 'Тёмная',
			},
		},
		sales: {
			title: 'Продажи',
		},
		feature: {
			title: 'Характеристика',
			plural: 'Характеристики',
			create: 'Создать характеристику',
			dataType: 'Тип данных',
			featureType: 'Тип характеристики',
			values: 'Значения списка',
			string: 'Текст',
			number: 'Число',
			boolean: 'Да / Нет',
			list: 'Список',
			required: 'Обязательное',
			weight: 'Порядок',
			products: 'Виды продукции',
			product: 'Продукт',
			variant: 'Вариация продукта',
			null: '-',
		},
		priceList: {
			title: 'Прайс-лист',
			plural: 'Прайс-листы',
			description: 'Описание прайс-листа',
			name: 'Название прайс-листа',
			start: 'Начало действия',
			end: 'Конец действия',
			period: 'Период действия',
			positions: 'Позиций',
			total: 'Всего прайс-листов',
			catalog: 'Каталог',
			betaTitle: 'Прайс-лист (бета)',
			new: 'Новый прайс-лист',
			create: 'Новый прайс-лист',
			info: 'Информация',
			copy: 'Копировать прайс-лист',
		},
		priceCategory: {
			title: 'Категория прайс-листа',
			plural: 'Категории прайс-листа',
			create: 'Новая категория',
			features: 'Характеристики',
			priceCalculation: 'Рассчет стоимости',
			positions: 'Позиции',
			moveTo: 'Переместить в категорию',
			confirmMove: 'Переместить?',
			root: 'Корень',
			name: 'Название',
			new: 'Новая категория',
			enterName: 'Введите название',
		},
		product: {
			title: 'Продукт',
			plural: 'Продукты',
			create: 'Новый продукт',
			info: 'Общая информация',
			name: 'Название',
			description: 'Описание',
			type: 'Тип продукта',
			category: 'Категория',
			variant: 'Вариация',
			variants: 'Вариации',
			createVariant: 'Создать вариацию',
			deleteVariant: 'Удалить вариацию',
			variantsCount: 'Вариантов',
			confirmDeleteVariant: 'Удалить вариацию?',
			variantImage: 'Изображение вариации',
			amountRanges: 'Диапазоны количества для образования цен',
			features: 'Характеристики',
			new: 'Новый продукт',
			goBackToPriceList: 'Вернуться в прайс-лист',
			addDescription: 'Добавьте описание к продукту',
		},
		productCategory: {
			title: 'Категория продукции',
			plural: 'Категории продукции',
			add: 'Добавить категорию',
			addBefore: 'Добавить перед',
			addAfter: 'Добавить после',
			addChild: 'Добавить подкатегорию',
		},
		pricePosition: {
			title: 'Позиция прайс-листа',
			plural: 'Позиции прайс-листа',
			amount: 'Количество',
			from: 'от',
			to: 'до',
			amountFrom: 'Количество от',
			amountTo: 'Количество до',
			price: 'Стоимость',
			priceForOne: 'Цена за шт.',
			copyPrice: 'Скопировать',
			total: 'Итого',
			name: 'Наименование позиции',
			nameShort: 'Наименование',
		},
		process: {
			title: 'Процесс',
			add: 'Новый процесс',
			plural: 'Процессы',
			position: 'Позиция',
			new: 'Новый процесс',
			addName: 'Введите название процесса',
			noPositionCategory: 'Не указана категория позиции',
			selectPosition: 'Выбрать позицию заказа',
			delete: 'Удалить процесс',
			addDescription: 'Добавьте описание к процессу',
			newProcess: 'Новый процесс',
			workshop: {
				title: 'Цех',
				plural: 'Цеха',
				new: 'Для добавления склада цеха необходимо сохранить запись',
				search: 'Поиск цеха',
				warehouseLabel: 'Склады',
				placeholder: 'Выберите из списка',
				warehouseCount: 'Количество складов',
			},
			targetWorkshop: 'Цех назначения',
			equipment: {
				title: 'Оборудование',
				invNumber: 'Инв.номер',
				selectWorkshops: 'Выберите цех',
				selectedWorkshops: 'Выбрано цехов:',
			},
			operatorsNumber: 'Количество операторов',
			adjustmentsNumber: 'Количество приладок',
			workingTime: 'Время работы',
		},
		processEquipment: {
			title: 'Оборудование',
			count: 'Количество оборудования',
			new: 'Новое оборудование',
		},
		material: {
			all: 'Все материалы',
			add: 'Новый материал',
			group: 'Группа метериалов',
			title: 'Материал',
			workshop: 'Цех',
			position: 'Позиция',
			plural: 'Материалы',
			name: 'Название',
			feature: 'Характеристика',
			format: 'Формат',
			units: 'Еденица измерения',
			copiesNumber: 'Количество на тираж',
			adjustmentsNumber: 'Количество на приладку',
			totalNumber: 'Количество общее',
			count: 'Количество материалов',
			selectPosition: 'Выбрать позицию заказа',
			noPositionCategory: 'Не указана категория позиции',
			type: {
				add: 'Новый тип материала',
				title: 'Тип материала',
				plural: 'Типы материалов',
				materials: 'Материалы типа',
				new: 'Для добавления материалов к типу необходимо сохранить запись',
			},
		},
		technologyRoadmap: {
			title: 'Технологическая карта',
		},
		order: {
			totalShort: 'Сумма',
			prepaidValue: 'Сумма аванса',
			name: 'Название заказа',
			categoriesAndAmount: 'Категория - количество',
			delete: 'Удалить заказ',
			newNameOrder: 'Введите название заказа',
			inputInvoiceNumber: 'Введите номер счета',
			inputContractNumber: 'Введите номер договора',
			copied: 'Копия заказа создана',
			descrName: 'Описание заказа',
			amount: 'Количество',
			new: 'Новый заказ',
			report: 'Отчет',
			document: 'Документ',
			editReport: 'Редактировать отчет',
			downloadReport: 'Скачать отчет',
			count: 'Кол-во заказов',
			noOrders: 'Заказы не найдены',
			category: 'Категория',
			defaultListError: 'Не выставлено состояние заказа по умолчанию',
			copy: 'Копировать заказ',
			garant: 'Гарантия',
			addGarant: 'Добавить гарантию',
			getprepaid: 'Получен',
			title: 'Заказ',
			plural: 'Заказы',
			number: 'Номер',
			n: '№',
			description: 'Описание',
			comment: 'Комментарий',
			updatedAt: 'Заказ обновлен',
			info: 'Информация о заказе',
			infoShort: 'Информация',
			positions: 'Состав заказа',
			positionsCount: 'Позиций',
			total: 'Стоимость заказа',
			totalAndDebt: 'Общая стоимость с учетом пени',
			price: 'Стоимость',
			client: 'Контрагент',
			selectClient: 'не выбран',
			invoiceNumber: 'Номер счета',
			invoiceDate: 'Дата счета',
			contractNumber: 'Номер договора',
			contractDate: 'Дата договора',
			paidStatus: 'Статус оплаты',
			paid: 'Оплачено',
			notPaid: 'Не оплачено',
			status: 'Статус заказа',
			manager: 'Менеджер',
			addDescription: 'Добавить описание',
			addComment: 'Добавить комментарий',
			startDate: 'Дата передачи',
			endDate: 'Дата выдачи',
			startDateFrom: 'Дата передачи, с',
			startDateTo: 'Дата передачи, по',
			endDateFrom: 'Дата выдачи, с',
			endDateTo: 'Дата выдачи, по',
			mode: 'Вид',
			priceFrom: 'Стоимость, от',
			priceTo: 'Стоимость, до',
			confirmClientChange: 'В данном заказе есть контактные лица предыдущего контрагента. При смене контрагента они будут удалены.',
			gotoClient: 'Перейти к контрагенту',
			removeContact: 'Убрать из контактных лиц заказа?',
			searchHint: 'Поиск по номеру заказа, контрагенту, менеджеру, номеру счета',
			prepaid: 'Аванс',
			passport: 'Паспорт заказа',
			technologyRoadmap: 'Технологическая карта',
			dueDatePaid: 'Срок платежа',
			datePaid: 'Дата платежа факт',
			percentDebt: 'Процент пени в день',
			debt: 'Пени итого',
			history: {
				old: 'Было',
				new: 'Стало',
				editPositionLog: 'изменил(а) позицию заказа',
				addPositionLog: 'добавил(а) позицию заказа',
				dueDatePaid: 'изменил(а) срок платежа',
				datePaid: 'изменил(а) дату платежа факт',
				percentDebt: 'изменил(а) процент пени в день',
				addAttachment: 'добавил(а) документ',
				addContactPerson: 'добавил(а) контактное лицо',
				removeContactPerson: 'удалил(а) контактное лицо',
				changeContragent: 'изменил(а) контрагента',
				addLabel: 'добавил(а) метку',
				removeLabel: 'удалил(а) метку',
				removeAttachment: 'удалил(а) документ',
				title: 'История заказа',
				created: 'создал(а) заказ',
				invoiceNumber: 'изменил(а) номер счета',
				invoiceDate: 'изменил(а) дату счета',
				contractNumber: 'изменил(а) номер договора',
				contractDate: 'изменил(а) дату договора',
				paid: 'изменил(а) статус оплаты на',
				paidYes: 'Оплачено',
				paidNo: 'Не оплачено',
				status: 'изменил(а) статус заказа на',
				newPosition: 'добавил(а) позицию заказа',
				deletedPosition: 'удалил(а) позицию заказа',
				newWaybill: 'добавил(а) накладную',
				deletedWaybill: 'удалил(а) накладную',
				total: 'итоговая стоимость изменилась: ',
				appointed: 'назначил(а) менеджера: ',
				appointedSelf: 'назначил(а) менеджером себя',
				appointedNull: 'убрал(а) назначение менеджера',
				client: 'изменил(а) контрагента: ',
				startDate: 'изменил(а) дату начала:',
				endDate: 'изменил(а) дату конца:',
				prepaidValue: 'обновил(а) размер аванса: ',
				prepaid: 'изменил(а) статус аванса на',
				prepaidYes: 'Получен',
				prepaidNo: 'Не получен',
				newProcess: 'добавил(а) процесс заказа',
				deletedProcess: 'удалил(а) процесс заказа',
				newMaterial: 'добавил(а) материал заказа',
				deletedMaterial: 'удалил(а) материал заказа',
				deletedPositionAttachment: 'удалил(а) из позиции заказа файл(ы)',
				addedPositionAttachment: 'добавил(а) в позицию заказа файл(ы)',
			},
			waybill: {
				tabname: 'Накладная',
				name: 'Наименование',
				gramms: 'Размер листа',
				count: 'Кол. Листов',
				density: 'Плотность',
				gaveOut: 'Выдал',
				defect: 'Брак',
				return: 'Возврат',
				group: 'Группа номенклатуры',
			},
			contragent: {
				new: 'Новый контрагент',
				fastCreate: `Данная форма используется для упрощенного создания контрагента.
				Для редактирования дополнительных сведений, перейдите в карточку контрагента в разделе "Контрагенты",
				или воспользуйтесь ссылкой "Перейти к контрагенту"`,
			},
			position: {
				fastCreate:
					'Созданная категория будет являться родительской, для создания дочерних категорий воспользуйтесь разделом "Прайс-лист"',
			},
		},
		viewProjectList: {
			plural: 'Список состояний проекта',
		},
		orderList: {
			title: 'Состояние заказа',
			plural: 'Состояния',
			create: 'Новое состояние',
			name: 'Название состояния',
			default: 'По умолчанию',
			closing: 'Закрывающий',
			roleNames: 'Роли',
			role: 'Роль',
		},
		orderTechPassport: {
			bindingType: 'Тип переплета',
			textColorfulness: 'Красочность текста',
			publicationFormat: 'Формат издания',
			pastingColorfulness: 'Красочность вклейки',
			cutFormat: 'Формат до обреза',
			colorfulFlyleaf: 'Красочность форзаца',
			formatAfterCutting: 'Формат после обреза',
			coverColorfulness: 'Красочность обложки',
			pagesNumber: 'Кол-во страниц (текст + вкл)',
			filmType: 'Тип пленки',
			textVolume: 'Объем текста',
			embossing: 'Тиснение',
			pasteVolume: 'Объем вклейки',
			varnishing: 'Лакирование',
			additionalInformation: 'Дополнительные сведения',
		},
		orderPosition: {
			discount: 'Скидка, руб',
			copy: 'Копировать позицию',
			title: 'Позиция заказа',
			plural: 'Позиции заказа',
			amount: 'Количество',
			price: 'Стоимость',
			priceForOne: 'Цена за шт.',
			category: 'Категория',
			pricePositionName: 'Наименование позиции',
			selectedPricePositionName: 'Выбранная позиция',
			save: 'Сохранить',
			lookingForMatches: 'Поиск совпадений',
			matchingPricePositions: 'Найдено совпадений',
			emptyAmount: 'Показаны совпадения без учёта количества. Чтобы скопировать цену, укажите количество в позиции заказа.',
			noMatches: 'Совпадений нет',
			noCategorySelected: 'Не выбрана категория',
			selectCategoryToEditFeatures: 'Выберите категорию, чтобы редактировать характеристики.',
			noFeaturesSetForCategory: 'Для данной категории не заданы характеристики.',
			gotoCategory: 'Перейти в категорию',
			discountPercent: 'Скидка, %',
			discountDirect: 'Скидка, руб.',
			final: 'Итоговая стоимость',
			members: 'Участники заказа',
			comment: 'Комментарий',
			attachmentsLink: 'Ссылка на материалы',
			membersShort: 'Участники',
			addMember: 'Добавить участника',
			addParameter: 'Добавить параметр',
			parameters: 'Дополнительные параметры',
			size: 'Размеры',
			unitPrice: 'Цена за ед.',
			delete: 'Удалить позицию',
			materialRef: 'Ссылка на материалы',
			willAbleAfterSave: 'Дополнительные данные будут доступы после сохранения',
			enterDiscount: 'Введите скидку',
			enterAmount: 'Введите количество',
			enterUnitPrice: 'Введите цену за ед.',
			enterSize: 'Введите размер',
			enterPrice: 'Введите стоимость',
			enterRef: 'Введите ссылку',
		},
		orderPositionParameter: {
			title: 'Параметр в позиции заказа',
			plural: 'Параметры в позиции заказа',
			new: 'Новый параметр в позиции заказа',
			delete: 'Удалить параметр',
		},
		orderPositionRole: {
			title: 'Роль в позиции заказа',
			plural: 'Роли в позиции заказа',
			new: 'Новая роль в позиции заказа',
			delete: 'Удалить роль',
		},
		sorting: {
			title: 'Сортировка',
			newFirst: 'Сначала новые',
			oldFirst: 'Сначала старые',
			priceAsc: 'Сначала дорогие',
			priceDesc: 'Сначала недорогие',
		},
		nomenclatureGroup: {
			all: 'Все категории',
			title: 'Категория номенклутуры',
			new: 'Новая категория номенклатуры',
			delete: 'Удалить категорию',
			plural: 'Категории номенклатуры',
			parent: 'Родительская категория',
		},
		enterpriseData: {
			title: '1С',
			sidebar: {
				submenu: '1C-Синхронизация',
				books: '',
				nodes: 'Узлы обмена',
				settings: 'Параметры',
				objects: 'Настройка объектов',
				history: 'История синхронизаций',
				journal: {
					title: 'Полученные сообщения',
					header: 'Журнал синхронизации',
					downloadFile: 'Скачать файл',
					goToBackVersion: 'Откатиться до этой версии',
				},
			},
			settings: {
				title: 'Параметры информационной базы',
			},
			importCategory: 'Импортировать категорию в прайс-лист',
		},
		contragents: {
			sidebar: {
				label: 'Контрагенты',
			},
			card: {
				title: 'Карточка контрагента',
				tabs: {
					info: 'Общая информация',
					staff: 'Сотрудники',
					staffNew: 'Новый сотрудник',
					bank: 'Банковские реквизиты',
					bankNew: 'Новые реквизиты',
					projects: 'Проекты',
					docs: 'Документы',
				},
				name: 'Наименование',
				phone: 'Телефон',
				email: 'E-mail',
				legalAddress: 'Юридический адрес',
				actualAddress: 'Фактический адрес',
				comments: 'Комментари',
				basicInfo: 'Основные сведения',
				additionalInfo: 'Дополнительные сведения',
				personName: 'Фамилия, имя, отчество',
				personRole: 'Должность',
				inn: 'ИНН',
				bic: 'БИК',
				checkingAccount: 'Расчётный счёт',
				kpp: 'КПП',
				okato: 'ОКАТО',
				oktmo: 'ОКТМО',
				okved: 'ОКВЭД',
				ogrn: 'ОГРН',
				okpo: 'ОКПО',
				myCompany: 'Моя компания',
				bank: 'Банк',
				innExist: 'уже используется другим контрагентом',
				type: 'Тип контрагента',
			},
		},
		nomenclature: {
			onlyFavourites: 'Показать только избранные',
			showNegativeTotal: 'Показать отрицательные остатки',
			date: 'Дата добавления',
			favourite: 'Избранное',
			material: 'Материал',
			plural: 'Номенклатура',
			title: 'Номенклатура',
			position: 'Позиция номенклатуры',
			from: 'Откуда',
			to: 'Куда',
			quantity: 'Количество',
			measure: 'Единицы измерения',
			onlyNonZeroQuantity: 'Только ненулевые остатки',
			lastBalanceUpdate: 'Дата обновления',
			noGroups: 'Не удалось получить группы',
			total: 'Всего остатков',
			delete: 'Удалить номенклатуру',
			new: 'Добавить номенклатуру',
			storehouse: 'Склад',
			remains: 'Остатки по складам',
			group: 'Номенклатурная группа',
		},
		storehouse: {
			title: 'Склад',
		},
		operation: {
			title: 'Операция',
			plural: 'Операции',
			count: 'Количество операций',
			add: 'Новая операция',
			edit: 'Изменить операцию',
			notFound: 'Операции не найдены',
			withContragent: 'Операции с контрагентом',
			new: 'Новая операция',
			total: 'Всего операций',
			search: 'Поиск по сумме и описанию',
		},
		bankAccount: {
			name: 'Название',
			title: 'Банковский счет',
			plural: 'Банковские счета',
			selectBankAccount: 'Выберите банковский счет',
			allMyBankAccount: 'Все мои банковские счета',
			notSelected: 'Не выбран банковский счет',
			bank: 'Банк',
			balance: 'Баланс',
			checkingAccount: 'Расчетный счет',
			correspondentAccount: 'Корреспондентский. счет',
			bik: 'БИК',
		},
		cashFlow: {
			title: 'Cтатья ДДС',
			plural: 'Статьи ДДС',
			shortTitle: 'Статья',
			allCashFlow: 'Все статьи',
			selectCashFlow: 'Выберите статью',
			new: 'Новая статья ДДС',
			delete: 'Удалить статью',
		},
		contragent: {
			title: 'Контрагент',
			plural: 'Контрагенты',
			myCompany: 'Моя компания',
			myCompanies: 'Мои компании',
			delete: 'Удалить контрагента',
			dataAboutBoss: 'Данные о руководителе',
		},
		currency: {
			title: 'Валюта',
			plural: 'Валюты',
		},
		production: {
			title: 'Производство',
			workshops: 'Цеха',
			equipments: 'Оборудование',
			process: 'Процессы',
			materials: 'Материалы',
			techRoadMap: 'Технол. Карты',
			warehouses: 'Склады',
		},
		processWorkshop: {
			add: 'Новый цех',
			title: 'Цех',
			plural: 'Цеха',
		},
		techRoadMap: {
			productName: 'Название продукта',
			plural: 'Технологичкие карты',
			comment: 'Комментарий',
			author: 'Технолог',
			title: 'Технологическая карта',
			number: 'Номер',
			material: 'Материал',
			quantity: 'Количество',
			money: 'Деньги',
			addOperation: 'Добавить процесс',
			new: 'Новая тех.карта',
			materials: 'Количество материалов',
			processes: 'Количество процессов',
			equipments: 'Количество оборудования',
			delete: 'Удалить тех.карту',
			process: {
				title: 'Процесс',
				delete: 'Удалить процесс',
			},
		},
		warehouse: {
			all: 'Все склады',
			plural: 'Склады',
			add: 'Новый склад',
			address: 'Адрес',
			phone: 'Телефон',
			dopinfo: 'Дополнительная информация',
			remove: 'Удалить склад',
			new: 'Новый склад',
			createWarehouses: 'Создать склад',
			warehouseTitle: 'Название склада',
			contragent: 'Контрагент',
			responsible: 'Ответственный',
			warehouseFrom: 'Склад отправитель',
			warehouseTo: 'Склад получатель',
			nomenclatureRemains: 'Остатки номенклатуры',
			errors: {
				notExist: 'Склада с таким id не найдено',
			},
		},
		declinationOfNumber: {
			direction1: 'направление',
			direction2: 'направления',
			direction5: 'направлений',
			project1: 'проект',
			project2: 'проекта',
			project5: 'проектов',
			cashFlow1: 'статья',
			cashFlow2: 'статьи',
			cashFlow5: 'статей',
			contragent1: 'контрагент',
			contragent2: 'контрагента',
			contragent5: 'контрагентов',
		},
		measure: {
			title: 'Единица измерения',
			plural: 'Единицы измерения',
			new: 'Новая единица измерения',
			delete: 'Удалить единицу измерения',
		},
		contragentType: {
			title: 'Тип контрагента',
			plural: 'Типы контрагентов',
			new: 'Новый тип контрагента',
			name: 'Короткое название',
			fullName: 'Название',
			delete: 'Удалить тип',
		},
		nomenclatureBalanceAccount: {
			title: 'Счет баланса',
			plural: 'Счета баланса',
			new: 'Новый счет баланса',
			isAsset: 'Учитывать при рассчёте остатков',
			delete: 'Удалить счет',
		},
		nomenclatureMovement: {
			title: 'Движения товаров',
			new: 'Новое перемещение',
			plural: 'Движения товаров',
			income: 'Приход',
			cost: 'Расход',
			transfer: 'Перемещение',
			newMovement: 'Новое перемещение',
			editMovement: 'Редактировать перемещение',
			code: 'Номер',
			numberOfLine: 'Строка №',
			total: 'Сумма',
			price: 'Цена',
			currency: 'Валюта',
			errors: {
				emptyDate: 'Не заполнено поле дата',
				emptyWarehouseTo: 'Не заполнен склад получатель',
				emptyWarehouseFrom: 'Не заполнен склад отправитель',
				emptyNomenclature: 'Не заполнена номенклатура',
				emptyQuantity: 'Не заполнено количество',
			},
		},
		total: 'Всего',
	},
};
