import management from './management';
import sales from './sales';
import finance from './finance';
import storehouse from './storehouse';
import manufacture from './manufacture';
import documents from './documents';

const modules = {
	management,
	sales,
	finance,
	storehouse,
	manufacture,
	documents,
};

export default modules;
