import { observable } from 'mobx';
import store from 'client/store';
import { MODES } from 'client/constants';
import { LOCAL_STORAGE_FIELDS } from './constants';

export default class DocumentsStore {
	@observable mode = MODES.LIST;
	@observable search = { name: '', typeName: [], labelNames: [], statusName: [] };
	@observable count = null;
	@observable labels = [];
	@observable types = [];
	@observable statuses = [];
	@observable isLoading = true;
	@observable query = {};

	init = async () => {
		this.loadLocalStorage();
		await Promise.all([this.fetchFilters()]);
		this.isLoading = false;
	};

	fetchFilters = async () => {
		const [count, labels, types, statuses] = await Promise.all([
			store.model.ViewDocument.count(),
			store.model.Label.find(),
			store.model.DocumentType.find(),
			store.model.DocumentStatus.find(),
		]);
		this.count = count;
		this.labels = labels.map((label) => label.name);
		this.types = types.map((type) => type.name);
		this.statuses = statuses.map((status) => status.name);
	};

	onChange = (prop) => (value) => {
		this[prop] = value;
		this.saveToLocalStorage();
	};

	// localstorage
	localStorage = store.local.documents;
	saveToLocalStorage = () => {
		LOCAL_STORAGE_FIELDS.forEach((field) => (this.localStorage[field] = this[field]));
		store.local.save();
	};
	loadLocalStorage = () => {
		const storageKeys = Object.keys(this.localStorage);
		if (storageKeys.length)
			storageKeys.forEach((key) => {
				this[key] = this.localStorage[key];
			});
	};
}

