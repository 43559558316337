import React from 'react';
import { observer } from 'mobx-react';
import store from 'client/store';
import classNames from 'classnames';

import './style.scss';

@observer
export class Notifications extends React.Component {
	render() {
		return (
			<div className='notifications'>
				{store.ui.notifications.map((notification) => {
					const notificationType = notification.error ? 'error' : 'success';

					return (
						<div
							className={classNames(`notification notification-${notificationType}`)}
							onAnimationEnd={() => {
								store.ui.removeNotification();
							}}
							key={notification.id}
						>
							{notification.message && <p>{notification.message}</p>}
							{notification.error && <p>{notification.error}</p>}
						</div>
					);
				})}
			</div>
		);
	}
}

