import React from 'react';
import { observer, inject, Provider } from 'mobx-react';
import { Table, Column } from '@smartplatform/ui';
import { ReportToolbar } from './toolbar';
import ReportStore from './store';
import store from 'client/store';

import './ordersReport.scss';

@inject('store')
@observer
export class OrdersReport extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store;
		this.reportStore = new ReportStore(this.store);
		this.reportStore.init();
	}

	render() {
		let { selectedColumns } = this.reportStore;

		const _selectedColumns = selectedColumns
			.filter((column) => column.isSelected)
			.map((column) => <Column key={column.label} {...column} />);

		return (
			<Provider reportStore={this.reportStore}>
				<div className='orders-report'>
					<ReportToolbar />
					<Table rows={this.reportStore.records}>{_selectedColumns}</Table>
				</div>
			</Provider>
		);
	}
}

