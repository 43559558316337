import React, { Component } from 'react';
import { Checkbox, Column, RecordSelect, Table, Pager } from '@smartplatform/ui';
import { observer } from 'mobx-react';
import store from 'client/store';
import { MODEL_FILTER, MODEL_NAME, NOMENCLATURE_CREATE_PATH } from './constants';
import { getLabelName } from 'client/tools';
import { ListStore } from 'components/stores';
import t from 'i18n';
import { Toolbar } from 'components';
import { observable } from 'mobx';
import classNames from 'classnames';

@observer
export class NomenclatureList extends Component {
	@observable warehouse = null;
	@observable nomenclatureGroup = null;
	@observable material = null;
	@observable onlyFavourites = false;
	@observable showNegativeTotal = false;

	constructor(props) {
		super(props);
		this.model = store.model[MODEL_NAME];
		store.ui.title = t(getLabelName('plural', MODEL_NAME));
		this.filterDisabled = true;
		this.store = new ListStore({ filters: this.filters, model: this.model, path: this.props.path });
	}

	componentDidMount() {
		this.store.setPerPage();
	}

	componentDidUpdate() {
		this.filterDisabled = false;
	}

	renderWarehouses = (r) => {
		r = JSON.parse(r.warehouses).filter((r) => r.total > 0);
		return r.map((i) => i.name).join(', ');
	};

	get filters() {
		const filters = { ...MODEL_FILTER };

		const where = { and: [] };
		if (this.warehouse) {
			where.and.push({ warehouses: { ilike: `%"id": "${this.warehouse.id}"%` } });
		}
		if (this.nomenclatureGroup) {
			where.and.push({ groupId: this.nomenclatureGroup.id });
		}
		if (this.material) {
			where.and.push({ materialId: this.material.id });
		}

		if (this.onlyFavourites) {
			where.and.push({ favourite: true });
		}

		if (!this.showNegativeTotal) {
			where.and.push({ total: { gte: 0 } });
		}
		filters.where = where;
		return filters;
	}

	onChange = (prop) => (value) => {
		this.filterDisabled = this[prop] !== value;
		this[prop] = value;
		this.store.updateFilters(this.filters);
	};

	cleanFilters = () => {
		this.filterDisabled = this.warehouse !== null || this.nomenclatureGroup !== null || this.material !== null || this.onlyFavourites !== false || this.showNegativeTotal !== false;

		this.warehouse = null;
		this.nomenclatureGroup = null;
		this.material = null;
		this.onlyFavourites = false;
		this.showNegativeTotal = false;
		this.store.updateFilters(this.filters);
	};

	render() {
		const { tableProps, page, totalCount, onChange: listStoreOnChange, setPerPage, perPage, create, onSearch, search } = this.store;

		const getSelectProps = (prop) => ({
			noTotal: true,
			maxItems: 10,
			width: 220,
			showValue: showValue(t(prop + '.all'), this[prop]?.name),
			onChange: this.onChange(prop),
			value: this[prop],
			property: 'name',
			model: store.model[prop.charAt(0).toUpperCase() + prop.slice(1)],
		});

		const showValue = (placeholder, value) => value || <span className='placeholder'>{placeholder}</span>;

		return (
			<div className='nomenclature-list'>
				<Toolbar className='basic-list-toolbar'>
					<Toolbar.CreateBtn onClick={create} text={t('nomenclature.new')} />
					<Toolbar.CreateBtn onClick={() => store.route.push({ path: `${NOMENCLATURE_CREATE_PATH}` })} text={t('nomenclatureMovement.newMovement')} />
					<Toolbar.Search value={search} onSearch={onSearch} placeholder={t('search')} />
				</Toolbar>
				<div className='filters'>
					<RecordSelect {...getSelectProps('warehouse')} disabled={this.filterDisabled} />
					<RecordSelect {...getSelectProps('nomenclatureGroup')} disabled={this.filterDisabled} />
					<RecordSelect {...getSelectProps('material')} disabled={this.filterDisabled} />
					{['onlyFavourites', 'showNegativeTotal'].map((prop) => {
						const className = classNames('form-field', prop + '-checkbox');
						return (
							<div className={className} key={prop}>
								<Checkbox value={this[prop]} onChange={this.onChange(prop)} disabled={this.filterDisabled} />
								<label>{t('nomenclature.' + prop)}</label>
							</div>
						);
					})}

					<Toolbar.CleanButton onClick={this.cleanFilters} />
				</div>

				<div id='table'>
					<Table {...tableProps}>
						<Column property='name' label={t('nomenclature.title')} />
						<Column property='total' label={t('nomenclature.quantity')} />
						<Column relation='measure' property='name' label={t('measure.title')} />
						<Column computed={this.renderWarehouses} label={t('warehouse.plural')} />
						<Column property='favourite' label={t('nomenclature.favourite')} />
					</Table>
				</div>
				<Pager current={page} totalCount={totalCount || 0} onChange={listStoreOnChange('page')} itemsPerPage={perPage} noCount />
			</div>
		);
	}
}

