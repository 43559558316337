export const DICTIONARIES = [
	{ modelName: 'DocumentType' },
	{ modelName: 'DocumentStatus', path: '/documentstatuses' },
	{ modelName: 'NomenclatureGroup', editRelations: [], noDeleteIfExternalId: true },
	{ modelName: 'Measure', noDeleteIfExternalId: true },
	{ modelName: 'CashFlow', noDeleteIfExternalId: true },
	{ modelName: 'ContragentType', listProperties: ['name', 'fullName'], editProperties: ['name', 'fullName'], noDeleteIfExternalId: true },

	// { modelName: 'OrderPositionParameter' },
	// { modelName: 'OrderPositionRole' },
];

