import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import Value from './Value';
import { AddCircleButton } from 'components';
import t from 'i18n';
import store from 'client/store';

@observer
export default class Values extends React.Component {
	@observable values = [];
	@observable isAddingValue = false;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const { record, relation } = this.props;
		this.values = await record[relation].find({ order: 'id asc' });
	};

	endAdding = () => {
		this.isAddingValue = false;
		console.log('endAdding', this.isAddingValue);
		this.init();
	};

	addValue = async () => {
		const { record, modelName, foreignKey } = this.props;
		this.isAddingValue = true;
		const value = new store.model[modelName]();
		value[foreignKey] = record.id;
		value.name = '';
		this.values.push(value);
	};

	render() {
		return (
			<div className='workshop-values'>
				<div className='table-list'>
					<table>
						<thead>
							<tr>
								<th>№</th>
								<th>{t('name')}</th>
								<th />
								<th />
							</tr>
						</thead>
						<tbody>
							{this.values.map((value, i) => (
								// <div>123</div>
								<Value
									index={i}
									key={value.id || 'new'}
									value={value}
									onDelete={this.init}
									onCreate={this.endAdding}
									onCancel={this.endAdding}
								/>
							))}
						</tbody>
					</table>
					<AddCircleButton onClick={this.addValue} disabled={this.isAddingValue} />
				</div>
			</div>
		);
	}
}
