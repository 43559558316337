import React from 'react';
import { RecordSelect } from './RecordSelect';
import { observer } from 'mobx-react';
import { renderStatus, renderPlaceholder } from 'client/tools';
import store from 'client/store';
import t from 'i18n';
import { toJS } from 'mobx';

const DEFAULT_FILTER = {
	fields: ['id', 'name', 'color'],
	order: 'name asc',
};

@observer
export class BoardListSelect extends React.Component {
	static defaultProps = {
		filter: DEFAULT_FILTER,
		searchPlaceholder: t('search'),
		showClearButton: true,
	};

	get showValue() {
		const { value, computed = renderStatus } = this.props;
		if (Array.isArray(toJS(value))) {
			if (value.length === 1) {
				return computed(value[0]);
			}
			if (value.length > 1) {
				return `${t('boardList.selected')}: ${value.length}`;
			}
			return renderPlaceholder(t('boardList.all'));
		}
		return computed(value);
	}
	render() {
		const { computed, model, showValue, maxItems, itemsPerPage, ...restProps } = this.props;

		return (
			<RecordSelect
				itemsPerPage={itemsPerPage || store.maxSelectItems}
				maxItems={maxItems || store.maxSelectItems}
				model={model || store.model.BoardList}
				computed={computed || renderStatus}
				showValue={showValue || this.showValue}
				{...restProps}
			/>
		);
	}
}

