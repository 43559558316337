import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Workshops from './workshops';
import { Equipments } from './equipments';
import Processes from './processes';
import Materials from './materials';
import TechnicalRoadmap from './techroadmap';

/**
 * Главный компонент с роутами и компонентами модуля.
 * Также может содержать, например, некую асинхронную логику инициализации.
 */

export default () => (
	<Switch>
		<Route path='/workshops' component={Workshops} />
		<Route path='/equipments' component={Equipments} />
		<Route path='/processes' component={Processes} />
		<Route path='/materials' component={Materials} />
		<Route path='/technicalroadmap' component={TechnicalRoadmap} />
	</Switch>
);

