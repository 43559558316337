import React from 'react';
import { Form as Form_ } from '@smartplatform/ui';
import { Actionbar } from './actionbar';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';

import './formComponent.scss';

export class Form extends React.Component {
	static propTypes = {
		noSave: PropTypes.bool,
		noDelete: PropTypes.bool,
		noCancel: PropTypes.bool,
		saveTitle: PropTypes.string,
		deleteTitle: PropTypes.string,
		cancelTitle: PropTypes.string,
		onCancel: PropTypes.func,
		disableSave: PropTypes.bool,
		disableDelete: PropTypes.bool,
		className: PropTypes.string,
	};

	static defaultProps = {};

	render() {
		const {
			children,
			noSave,
			noDelete,
			noCancel,
			saveTitle,
			deleteTitle,
			cancelTitle,
			onCancel,
			disableSave,
			disableDelete,
			className,
			...formProps
		} = this.props;

		const className_ = classNames(className, { 'no-form-submit': noCancel && noDelete && noSave });

		const actionBarProps = {
			noSave,
			noDelete,
			noCancel,
			saveTitle,
			deleteTitle,
			cancelTitle,
			onCancel,
			disableSave,
			disableDelete,
		};

		return (
			<Form_ noDelete noSave className={className_} controls={<Actionbar {...actionBarProps} />} {...formProps}>
				{children}
			</Form_>
		);
	}
}
