import store from 'client/store';
import { observable, decorate } from 'mobx';
import { COSTS, INCOME, TRANSFER } from '../constants';

export default class EditOperationStore {
	@observable isSplitPayment = false;
	@observable paymentType = INCOME;
	@observable record = null;
	@observable children = [];

	@observable myCompanyBankAccount = null;
	@observable contragentBankAccount = null;
	@observable contragent = null;
	@observable cashFlow = null;
	@observable project = null;
	@observable isLoading = true;
	childrenToDelete = [];

	constructor(operationsStore) {
		this.store = operationsStore;
		this.init();
	}

	get isTransfer() {
		return this.paymentType === TRANSFER;
	}

	get hasWrongChild() {
		return !this.children.every((child) => child.total > 0 && child.contragent?.id && child.cashFlow?.id);
	}

	get childrenSum() {
		const sum = this.children.reduce((acc, value) => acc + value.total || 0, 0);
		return parseFloat(sum.toFixed(2));
	}

	init = async () => {
		const { selectedRecord } = this.store;
		this.isNew = !selectedRecord;
		this.record = new store.model.Payment(selectedRecord || { date: new Date(), total: 0 });

		if (!this.isNew) {
			this.paymentType = selectedRecord.paymentType || INCOME;
			// фетч нужен чтобы таблица не меняла значения до сохранения
			this.children = await store.model.ViewPayment.find({ where: { parentId: selectedRecord.id } });
			this.project = this.record.project;
			this.cashFlow = this.record.cashFlow;

			if ([COSTS, TRANSFER].includes(this.paymentType)) {
				this.myCompanyBankAccount = this.record.payerBankAccount;
				this.contragentBankAccount = this.record.recipientBankAccount;
				this.contragent = this.record.recipient;
			} else {
				this.contragent = this.record.payer;
				this.myCompanyBankAccount = this.record.recipientBankAccount;
			}

			for (const child of this.children) {
				const contragentRelation = this.paymentType === COSTS ? 'recipient' : 'payer';
				child.contragent = child[contragentRelation];
			}

			this.checkIsSplitPayment();
		}

		this.isLoading = false;
	};

	calcTotal = () => {
		this.record.total = this.childrenSum;
	};

	onIsSplitPaymentChange = (value) => {
		this.isSplitPayment = value;
		if (value && !this.children.length) {
			this.addChild(this.record.total);
			this.addChild();
		}
	};

	addChild = (total) => {
		if (!Number(total)) total = 0;
		const { date } = this.record;
		const child = new store.model.ViewPayment({ total: total || 0, date });

		for (const field of ['cashFlow', 'project', 'contragent']) {
			child[field + 'Id'] = this[field]?.id;
			child[field] = this[field];
		}

		this.children.push(child);
	};

	onClose = () => {
		this.store.selectedRecord = null;
		this.store.toggleProp('showEditPopup')(false);
	};

	onAction = () => {
		this.onClose();
		this.store.refetch();
	};

	onChange = (prop) => (value) => {
		if (this[prop] !== value) {
			this[prop] = value;
		}
		if (prop === 'paymentType') {
			this.checkIsSplitPayment();
		}
	};

	checkIsSplitPayment = () => (this.isSplitPayment = !this.isTransfer && Boolean(this.children.length));

	beforeSave = async (record) => {
		const getId = (record, relation) => {
			if (relation) return record[relation]?.id || null;
			else return record?.id || null;
		};
		const myCompanyBankAccountId = getId(this.myCompanyBankAccount);
		const contragentBankAccountId = getId(this.contragentBankAccount);
		const myCompanyId = getId(this.myCompanyBankAccount, 'contragent');
		const contragentId = getId(this.contragent);

		switch (this.paymentType) {
			case TRANSFER: {
				record.payerId = myCompanyId;
				record.payerBankAccountId = myCompanyBankAccountId;
				record.recipientBankAccountId = contragentBankAccountId;
				record.recipientId = getId(this.contragentBankAccount, 'contragent');
				break;
			}
			case INCOME: {
				record.payerId = contragentId;
				record.payerBankAccountId = null;
				record.recipientBankAccountId = myCompanyBankAccountId;
				record.recipientId = myCompanyId;
				break;
			}
			case COSTS: {
				record.payerBankAccountId = myCompanyBankAccountId;
				record.payerId = myCompanyId;
				record.recipientId = contragentId;
				record.recipientBankAccountId = null;
				break;
			}
		}
		if (this.children.length) {
			record.cashFlowId = null;
			record.projectId = null;
		} else {
			record.cashFlowId = getId(this.cashFlow);
			record.projectId = getId(this.project);
		}
	};

	afterSave = async (record) => {
		if (this.children.length) {
			const promises = [];
			for (let child of this.children) {
				const contragent = child.contragent;
				child = new store.model.Payment(child);
				if (this.isSplitPayment) {
					// дети получают данные от родителя после сохранения, потом переназначается только контрагент(recipient или payer)
					child.parentId = record.id;
					child.payerId = record.payerId;
					child.recipientId = record.recipientId;
					child.payerBankAccountId = record.payerBankAccountId;
					child.recipientBankAccountId = record.recipientBankAccountId;
					const contragentField = this.paymentType === COSTS ? 'recipientId' : 'payerId';
					child[contragentField] = contragent?.id || null;
					//

					promises.push(child.save());
				} else {
					child.id && promises.push(child.delete());
				}
			}

			for (let child of this.childrenToDelete) {
				child = new store.model.Payment(child);
				promises.push(child.delete());
			}

			await Promise.all(promises);
		}
		this.onAction();
	};

	onDelete = async () => {
		const promises = [];
		for (let child of this.children) {
			child = new store.model.Payment(child);
			child.id && promises.push(child.delete());
		}
		await Promise.all(promises);
		this.onAction();
	};

	deleteChild = async (child, index) => {
		if (child.id) {
			// удалять чайлдов надо не сразу
			this.childrenToDelete.push(child);
		}
		this.children.splice(index, 1);
		this.calcTotal();
		this.checkIsSplitPayment();
	};
}

