import React from 'react';
import { observer, Provider } from 'mobx-react';
import { MODES } from 'client/constants';
import OrdersStore from './store';
import { OrdersList } from './list';
import { OrdersKanban } from './kanban';
import { OrdersTiles } from './tiles';
import { OrdersReport } from './report';
import { OrdersToolbar } from './toolbar';
import { ORDERS_MODE_REPORT } from './constants';
import store from 'client/store';
import t from 'i18n';

import './orders.scss';

@observer
export class Orders extends React.Component {
	constructor(props) {
		super(props);
		this.store = new OrdersStore();
		this.checkReportMode();
		this.setUiTitle();
		this.store.init();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.title !== this.props.title) {
			this.setUiTitle();
		}
		if (prevProps.reportMode !== this.props.reportMode) {
			this.checkReportMode();
		}
	}

	checkReportMode = () => {
		this.store.isReportMode = !!this.props.reportMode;
	};

	setUiTitle = () => {
		store.ui.title = this.props.title || t('order.plural');
	};

	render() {
		const { mode } = this.store;
		const { reportMode } = this.props;

		const components = {
			[MODES.LIST]: <OrdersList />,
			[MODES.KANBAN]: <OrdersKanban />,
			[MODES.TILES]: <OrdersTiles />,
			[ORDERS_MODE_REPORT]: <OrdersReport />,
		};

		const component = components[reportMode] || components[mode] || components[MODES.LIST];

		let toolbarProps = {};
		if (reportMode) {
			toolbarProps = {
				noButton: true,
				isReportMode: true,
			};
		}

		return (
			<Provider store={this.store}>
				<div className='orders-root'>
					<OrdersToolbar mode={this.props.mode} {...toolbarProps} />
					{component}
				</div>
			</Provider>
		);
	}
}

