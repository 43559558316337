import { Field, Row } from '@smartplatform/ui';
import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { checkModelAccess } from 'client/tools/checkModelAccess';
import { ModelEdit } from 'components';
import { Warehouses } from './warehouse-item';

import store from 'client/store';
import t from 'i18n';

const FILTER = {
	include: ['warehouses'],
};
@observer
export class Edit extends React.Component {
	@observable record = null;
	@observable error = false;

	constructor(props) {
		super(props);
		this.error = checkModelAccess(this.props.modelName);
	}

	getRecord = async (record) => {
		this.record = record;
	};

	render() {
		if (this.error) return <div className='error'>{this.error}</div>;
		const { modelName, path, match, title } = this.props;
		const id = parseInt(match.params.id);

		return (
			<div className='workshop-wrapper'>
				<ModelEdit
					model={store.model[modelName]}
					stay
					getRecord={this.getRecord}
					disableSave={!this.record?.name}
					filter={FILTER}
					path={path}
					id={id}
					title={title}
				>
					<div className='info-form workshop-form'>
						<div className='fields'>
							<Row>
								<Field property='name' label={t('name')} />
							</Row>
							<Row>
								<Field property='address' label={t('address')} />
							</Row>
							<Row>
								<Field property='info' label={t('information')}>
									<textarea rows='10'></textarea>
								</Field>
							</Row>
						</div>
						<Row>
							<Field relation='warehouses' label={t('process.workshop.warehouseLabel')}>
								<Warehouses />
							</Field>
						</Row>
					</div>
				</ModelEdit>
			</div>
		);
	}
}

