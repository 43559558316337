import React from 'react';
import { inject, observer } from 'mobx-react';
import { RefreshButton, Button, CancelButton } from 'components';
import { REPORT_SELECT_ITEMS } from '../constants';
import { Select } from '@smartplatform/ui';
import { EditColumnsPopup } from './editPopup';
import t from 'i18n';

import './orderReportToolbar.scss';

@inject('reportStore')
@observer
export class ReportToolbar extends React.Component {
	render() {
		const { isLoading, limit, onLimitChange, downloadReport, isEdit, refresh, onToggleEdit } = this.props.reportStore;

		const disabled = isEdit || isLoading;

		return (
			<div className='toolbar'>
				<div className='action-bar'>
					<div className='form-field'>
						<label>{t('order.count')}</label>
						<Select
							items={REPORT_SELECT_ITEMS}
							value={limit}
							size='sm'
							onChange={onLimitChange}
							noSearch
							width={100}
							disabled={disabled}
							isRequired
						/>
					</div>
					<div className='actions'>
						<Button size='sm' disabled={disabled} onClick={downloadReport} text={t('order.downloadReport')} />
						<Button size='sm' disabled={disabled} onClick={onToggleEdit(true)} text={t('order.editReport')} />
						{/* <RefreshButton onClick={refresh} disabled={disabled} /> */}
						{isEdit && <EditColumnsPopup />}
					</div>
				</div>
			</div>
		);
	}
}

