import React from 'react';
import Root from './Root';
import t from 'i18n';

import { faFile, faPaste, faFileCircleCheck } from '@fortawesome/free-solid-svg-icons';

/**
 * Конфигурация модуля.
 */

export default {
	/** Заголовок модуля */
	title: t('document.moduleTitle'),

	/** Код модуля на латинице, обязателен */
	code: 'documents',

	/** Опциональная дополнительная функция проверки доступности модуля */
	enabled: (store) => store.model.Document?.INFO.READ,

	/** Главный компонент с роутами и компонентами модуля */
	component: Root,

	/** Необязательный компонент для общего дашбоарда */
	// dashboardCard: DocumentsCard,

	/** Меню модуля, может быть массивом или функцией (в аргументе передастся глобальный стор) */
	menu: () => [
		{
			type: 'item',
			path: '/documents',
			label: t('document.plural'),
			icon: faFile,
			enabled: (store) => store.model.Document && store.model.Document.INFO.READ,
		},
		{
			type: 'item',
			label: t('documentType.plural'),
			path: '/documenttypes',
			icon: faPaste,
		},
		{
			type: 'item',
			label: t('documentStatus.plural'),
			path: '/documentstatuses',
			icon: faFileCircleCheck,
		},
	],
};

