import { Column } from '@smartplatform/ui';
import { BasicList } from 'components';
import React, { Component } from 'react';
import { checkModelAccess } from 'client/tools/checkModelAccess';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import store from 'client/store';
import t from 'i18n';

@observer
export class List extends Component {
	render() {
		const { modelName } = this.props;
		const error = checkModelAccess(modelName);

		if (error) return <div className='error'>{error}</div>;

		const renderCount = (record) => record.length;

		return (
			<BasicList model={store.model[modelName]} filter={{ include: ['warehouses'] }} toolbarButtonText='processWorkshop.add'>
				<Column property='name' label={t('name')} />
				<Column property='address' label={t('address')} />
				<Column relation='warehouses' computed={renderCount} label={t('process.workshop.warehouseCount')} />
				<Column property='info' label={t('information')} />
			</BasicList>
		);
	}
}

