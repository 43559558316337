import React from 'react';
import t from 'i18n';
import { faDroplet } from '@fortawesome/free-solid-svg-icons';

import { MaterialsList } from './materialsList';
import { MaterialTypes } from './materialType';
import MaterialIcon from 'client/img/icons/materials.svg';
import { IconTitle } from 'components';

export const path = '/materials';

export const tabs = [
	{
		path: path + '/list',
		title: t('material.plural'),
		buttonTitle: t('material.title'),
		iconTitle: <IconTitle faIcon={faDroplet} text={t('material.plural')} />,
		Component: MaterialsList,
		modelName: 'Material',
		relationLabel: t('material.type.title'),
		relation: 'materialType',
	},
	{
		path: path + '/materialtypes',
		title: t('material.type.plural'),
		buttonTitle: t('material.type.title'),
		iconTitle: <IconTitle icon={<MaterialIcon />} text={t('material.type.plural')} />,
		Component: MaterialTypes,
		modelName: 'MaterialType',
		relationLabel: t('material.count'),
		relation: 'materials',
	},
];

