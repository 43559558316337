import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { DASHBOARD_PATH, PROJECTS_PATH, SETTINGS_PATH, TASKS_PATH } from './constants';
import loadable from '@loadable/component';
const Dashboard = loadable(() => import('./dashboard'));
const Tasks = loadable(() => import('./tasks'));
const Settings = loadable(() => import('./settings'));
const Projects = loadable(() => import('./projects'));
/**
 * Главный компонент с роутами и компонентами модуля.
 * Также может содержать, например, некую асинхронную логику инициализации.
 */

export default () => (
	<Switch>
		<Route path={`${DASHBOARD_PATH}`} component={Dashboard} />
		<Route path={`${PROJECTS_PATH}`} component={Projects} />
		<Route path={`${TASKS_PATH}`} component={Tasks} />
		<Route path={`${SETTINGS_PATH}`} component={Settings} />
	</Switch>
);

