import React, { createRef } from 'react';
import { observer } from 'mobx-react';
import { Link, matchPath } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { AutoAnimate } from '@smartplatform/ui';
import MenuItem from './MenuItem';
import store from 'client/store';

@observer
export default class ModuleMenu extends React.Component {
	ref = createRef(null);

	constructor(props) {
		super(props);
		this.init();
	}

	init = () => {
		const { module } = this.props;

		if (!store.local.submenu) {
			store.local.submenu = {};
			store.local.save();
		}
		if (!store.local.submenu[module.code]) {
			store.local.submenu[module.code] = false;
			store.local.save();
		}
	};

	changeVisibility = (isVisible) => {
		const { module } = this.props;
		if (typeof isVisible === 'boolean') {
			if (isVisible !== store.local.submenu[module.code]) {
				store.local.submenu = {
					...store.local.submenu,
					[module.code]: isVisible,
				};
				store.local.save();
			}
		} else {
			store.local.submenu = {
				...store.local.submenu,
				[module.code]: !store.local.submenu[module.code],
			};
			store.local.save();
		}
	};

	render() {
		const { module } = this.props;
		const { title, code, settings } = module;

		const isActive = code === store.ui.activeSubMenu;

		const className = classNames('submenu', {
			hidden: !store.local.submenu[code] && !isActive,
		});

		let items = [];
		if (module.menu) {
			items = typeof module.menu === 'function' ? module.menu(store) : module.menu;
		}

		return (
			<div className={className}>
				<div className='title' onClick={!isActive ? this.changeVisibility : () => {}}>
					<strong>{title}</strong>
					<div className='actions'>
						{settings && (
							<Link className='action-btn' to={settings.path}>
								<FontAwesomeIcon icon={faGear} />
							</Link>
						)}
						<span className={'action-btn' + (isActive ? ' disabled' : '')}>
							<FontAwesomeIcon icon={faChevronUp} />
						</span>
					</div>
				</div>
				<AutoAnimate className='items'>
					{store.local.submenu[code] &&
						items.map((item, i) => {
							return (
								<MenuItem
									key={code + '-' + i}
									item={item}
									badgeFunc={item.badgeFunc}
									badgeEvent={item.badgeEvent}
									changeVisibility={this.changeVisibility}
									parentCode={code}
								/>
							);
						})}
				</AutoAnimate>
			</div>
		);
	}
}

