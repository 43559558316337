import React from 'react';
import Root from './Root';
import t from 'i18n';

import { faCubes, faScrewdriverWrench, faGears, faDroplet, faRandom } from '@fortawesome/free-solid-svg-icons';

/**
 * Конфигурация модуля.
 */

export default {
	/** Заголовок модуля */
	title: t('production.title'),

	/** Код модуля на латинице, обязателен */
	code: 'manufacture',

	/** Опциональная дополнительная функция проверки доступности модуля */
	enabled: (store) => store.model.ProcessWorkshop && store.model.ProcessWorkshop.INFO.READ,

	/** Главный компонент с роутами и компонентами модуля */
	component: Root,

	/** Необязательный компонент для общего дашбоарда, см. исходник */
	// dashboardCard: ManufactureCard,

	/** Меню модуля, может быть массивом или функцией (в аргументе передастся глобальный стор) */
	menu: () => [
		{
			type: 'item',
			label: t('production.workshops'),
			path: '/workshops',
			icon: faCubes,
			enabled: ({ model }) => model.ProcessWorkshop?.INFO.READ,
		},
		{
			type: 'item',
			label: t('production.equipments'),
			path: '/equipments',
			icon: faScrewdriverWrench,
			enabled: ({ model }) => model.ProcessEquipment?.INFO.READ,
		},
		{
			type: 'item',
			path: '/processes',
			label: t('production.process'),
			icon: faGears,
			enabled: ({ model }) => model.ProcessOperation.INFO.READ,
		},
		{
			type: 'item',
			path: '/materials/list',
			label: t('production.materials'),
			icon: faDroplet,
			enabled: ({ model }) => model.Material?.INFO.READ,
		},
		{
			type: 'item',
			path: '/technicalroadmap',
			label: t('production.techRoadMap'),
			icon: faRandom,
			enabled: ({ model }) => model.ProcessWorkshop?.INFO.READ,
		},
	],
};

