import React, { Component } from 'react';
import { Table, Column } from '@smartplatform/ui';
import { Toolbar, DeleteIconButton, BasicList } from 'components';
import store from 'client/store';
import t from 'i18n';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

@observer
export class TechRoadMapList extends Component {
	getInit = (init) => {
		this.init = init;
	};

	onDelete = async (record) => {
		await record.delete();
		this.init();
	};

	renderDeleteButton = ({ record }) => <DeleteIconButton onConfirm={() => this.onDelete(record)} />;

	render() {
		return (
			<BasicList
				model={store.model.ViewTechRoadMap}
				getInit={this.getInit}
				title={t('techRoadMap.plural')}
				toolbarButtonText={t('techRoadMap.new')}
				rowHeight={56}
			>
				<Column property='name' label={t('name')} />
				<Column property='processesCount' label={t('techRoadMap.processes')} width={200} />
				<Column property='materialsCount' label={t('techRoadMap.materials')} width={200} />
				<Column property='equipmentsCount' label={t('techRoadMap.equipments')} width={200} />
				<Column property='money' label={t('techRoadMap.money')} width={200} />
				<Column width={50}>{(data) => this.renderDeleteButton(data)}</Column>
			</BasicList>
		);
	}
}

