import React, { Component } from 'react';
import { Field, RecordSelect, Row } from '@smartplatform/ui';
import store from 'client/store';
import { Button, Form, UserSelectOld } from 'components';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { ProcessItem } from './process-item';
import { renderUser } from 'client/tools';
import t from 'i18n';

import './techRoadMap.scss';

@observer
export class TechRoadMapEdit extends Component {
	@observable record = null;
	@observable isLoading = true;
	@observable processes = [];
	@observable isAdding = false;

	constructor(props) {
		super();
		this.id = props.match.params?.id;
		this.init();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.match.params?.id !== this.props.match.params?.id) {
			this.id = this.props.match.params?.id;
			this.init();
		}
	}

	init = async () => {
		this.isLoading = true;

		if (this.id) {
			this.record = await store.model.TechRoadMap.findById(this.id, {
				include: [
					'user',
					'product',
					'owner',
					{
						relation: 'process',
						scope: {
							order: 'id desc',
							include: ['process', 'materials'],
						},
					},
				],
			});
			this.processes = this.record.process().reverse();
		} else {
			this.record = new store.model.TechRoadMap();
		}
		this.isLoading = false;
	};

	onAddProcess = async () => {
		this.isAdding = true;
		const newProcess = new store.model.TechRoadMapProcess({ techMapId: this.id });
		await newProcess.save();
		newProcess.isNew = true;
		this.processes.push(newProcess);
	};

	onRemoveProcess = async (record, isNew) => {
		const index = this.processes.findIndex((item) => item.id === record.id);
		await this.record.process.remove(record.id);
		if (index !== -1) this.processes.splice(index, 1);
		if (isNew) this.isAdding = false;
	};

	onSave = () => {
		if (this.id) store.route.push({ path: `/technicalroadmap` });
		else store.route.push({ path: `/technicalroadmap/${this.record.id}` });
	};

	onCancel = () => store.route.push({ path: `/technicalroadmap` });

	onSaveNewProcess = () => (this.isAdding = false);

	render() {
		if (this.isLoading) return 'Loading';

		console.log(this.record);

		return (
			<>
				<div className='techRoadMapEdit-form-wrapper'>
					<Form
						record={this.record}
						onSave={this.onSave}
						className='techRoadMap-create-form'
						disableSave={!this.record.name}
						deleteTitle={t('techRoadMap.delete')}
						onCancel={this.onCancel}
						onDelete={this.onCancel}
						stay
					>
						<Row>
							<Field property='name' label={t('name')} placeholder={t('project.addName')} isRequired />
						</Row>
						<Row>
							<Field relation='product' property='name' label={t('techRoadMap.productName')} >
								<RecordSelect />
							</Field>
						</Row>
						<Row>
							<Field relation='owner' label={t('techRoadMap.author')} disabled>
								<UserSelectOld showValue={!this.record.id && renderUser(store.model.user)} />
							</Field>
						</Row>
						<Row>
							<Field property='comment' label={t('techRoadMap.comment')}>
								<textarea rows='5' />
							</Field>
						</Row>
					</Form>
					<div className='techRoadMapEdit-processes-wrapper'>
						{this.record.id && (
							<>
								<div className='processes-items'>
									{this.processes.map((item, index) => (
										<ProcessItem
											item={item}
											indexItem={index}
											key={item.id}
											onDelete={this.onRemoveProcess}
											onSaveNewProcess={this.onSaveNewProcess}
											record={this.record}
											isNew={item?.isNew}
										/>
									))}
								</div>
								<Button
									onClick={this.onAddProcess}
									disabled={this.isAdding}
									text={t('techRoadMap.addOperation')}
									className='process-create'
								/>
							</>
						)}
					</div>
				</div>
			</>
		);
	}
}

