import { Button, ButtonGroup, Popup } from '@smartplatform/ui';
import { inject, observer, Provider } from 'mobx-react';
import React from 'react';
import t from 'i18n';
import { COSTS, INCOME, TRANSFER } from '../constants';
import './style.scss';
import { Transfer } from './Transfer';
import { Payment } from './Payment';
import EditOperationStore from './store';

@inject('store')
@observer
export class EditPopup extends React.Component {
	originRecord;

	constructor(props) {
		super(props);
		this.store = this.props.store;
		this.editStore = new EditOperationStore(this.store);
	}

	render() {
		const { onClose, isSplitPayment, paymentType, isNew, onChange, isLoading, record } = this.editStore;
		if (isLoading) return null;
		const title = t('payment.' + (isNew ? 'add' : 'edit'));
		const form = paymentType === TRANSFER ? <Transfer /> : <Payment />;

		return (
			<Provider editStore={this.editStore}>
				<Popup className='operation-edit-popup' onClose={onClose} width={isSplitPayment ? 1300 : 700}>
					<h2>{title}</h2>
					<div className='payment-buttons'>
						<ButtonGroup className='button-group'>
							{[INCOME, COSTS, TRANSFER].map((typeName) => (
								<Button
									key={typeName}
									disabled={!!record.parentId && typeName !== paymentType}
									variant={paymentType === typeName ? 'primary' : 'default'}
									onClick={() => onChange('paymentType')(typeName)}
								>
									{t('payment.' + typeName.toLowerCase())}
								</Button>
							))}
						</ButtonGroup>
					</div>
					{/* <div>Плательщик {record.payer?.name}</div>
					<div>Счет {record.payerBankAccount?.name}</div>
					<div>Получатель {record.recipient?.name}</div>
					<div>Счет {record.recipientBankAccount?.name}</div> */}
					{form}
				</Popup>
			</Provider>
		);
	}
}

