import React from 'react';
import { observable } from 'mobx';
import { Logo } from '../components/logo/Logo';
import { Button } from '../../buttons';
import { observer } from 'mobx-react';
import t from 'i18n';
import store from 'client/store';
import { ErrorMessage, FieldPassword } from '../components';
import { MIN_PASSWORD_LENGTH } from '../constants';

const INIT_ERRORS = {
	password: null,
	repeatedPassword: null,
	general: null,
};
@observer
export class Reset extends React.Component {
	@observable password = '';
	@observable repeatedPassword = '';
	@observable errors = INIT_ERRORS;
	@observable isPasswordChanged = false;

	constructor(props) {
		super(props);
		this.elements = {};
		this.token = props.match.params?.token;
	}

	back = () => store.route.push({ path: '/login' });
	onChange = (fieldName) => (e) => (this[fieldName] = e.target.value);
	onMount = (name) => (el) => (this.elements[name] = el);

	onSubmit = async () => {
		this.errors = { ...INIT_ERRORS };
		if (this.password.length < MIN_PASSWORD_LENGTH) {
			this.errors.password = t('auth.minPasswordLength') + ': ' + MIN_PASSWORD_LENGTH;
			return;
		}
		if (this.password !== this.repeatedPassword) {
			this.errors.repeatedPassword = t('auth.noMatch');
			this.focus('repeatedPassword');
			return;
		}

		const res = await fetch('/api/users/reset-password', {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				Authorization: this.token,
			},
			body: JSON.stringify({ newPassword: this.password }),
		});

		if (res.ok) {
			this.isPasswordChanged = true;
		} else {
			this.errors.general = t('auth.TOKEN_ROTTEN');
		}
	};

	render() {
		const yearNow = new Date().getFullYear();

		const passwordFields = (
			<>
				<FieldPassword
					isRequired
					label={t('auth.password')}
					value={this.password}
					onChange={this.onChange('password')}
					inputRef={this.onMount('password')}
					error={this.errors.password}
				/>
				<FieldPassword
					isRequired
					label={t('auth.repeatPassword')}
					value={this.repeatedPassword}
					onChange={this.onChange('repeatedPassword')}
					inputRef={this.onMount('repeatedPassword')}
					error={this.errors.repeatedPassword}
				/>
			</>
		);

		let content = passwordFields;
		if (this.isPasswordChanged) content = <span className='passwordChanged'>{t('password.changed')}</span>;
		else if (this.errors.general) content = <ErrorMessage field={this.errors.general} />;

		const isSubmitButtonHidden = !!this.isPasswordChanged || !!this.errors.general;

		return (
			<>
				<Logo />
				<form className='auth-form'>
					<h3>{t('password.new')}</h3>
					{content}
					<div className='submit'>
						<Button variant='default' onClick={this.back} text={t('auth.goBack')} />
						{!isSubmitButtonHidden && <Button text={t('change')} onClick={this.onSubmit} />}
					</div>
					<div className='copyright'>© 2015-{yearNow} ООО «Смарт Юнит»</div>
				</form>
			</>
		);
	}
}

