import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { DeleteIconButton } from 'components';
import t from 'i18n';

@observer
export default class Attachment extends React.Component {
	static propTypes = {
		attachment: PropTypes.object,
		property: PropTypes.string,
		onDelete: PropTypes.func,
	};

	static defaultProps = {
		property: 'filename',
	};

	render() {
		const { attachment, property, isNew, deleteAttach } = this.props;

		const msg = (
			<>
				{t('deleteFile')}
				<br />
				<em>{attachment[property]}</em>?
			</>
		);

		return (
			<div className='attachment'>
				<div className='info'>
					{!isNew && <DeleteIconButton noBorder confirmMessage={msg} onConfirm={() => deleteAttach({ attachment, property })} />}
					<a className='file-name' target='_blank' href={attachment.downloadFile(property)}>
						{attachment[property]}
					</a>
				</div>
			</div>
		);
	}
}
