import React, { Component } from 'react';
import { Checkbox, Column, Field, Input, RecordSelect, Row, Table } from '@smartplatform/ui';
import store from 'client/store';
import { Form, ModelEdit } from 'components';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import t from 'i18n';
import { MODEL_FILTER, MODEL_NAME, WAREHOUSE_PATH } from './constants';
import { getLabelName } from 'client/tools';

@observer
export class NomenclatureEdit extends Component {
	@observable record;
	@observable warehouses = [];

	constructor(props) {
		super(props);
		this.path = props.path;
		this.model = store.model[MODEL_NAME];
		this.id = parseInt(props.match.params.id);
	}

	beforeSave = async (record) => {
		record = new store.model.Nomenclature(record);
		await record.save();
		this.onBack();
		return false;
	};

	beforeDelete = async (record) => {
		record = new store.model.Nomenclature(record);
		await record.delete();
		this.onBack();
		return false;
	};

	onBack = () => setTimeout(() => store.route.push({ path: this.path }), 0);

	getRecord = (record) => {
		this.record = record;
		store.ui.title = record.name;
		this.warehouses = record?.warehouses ? JSON.parse(record.warehouses) : [];
	};

	renderProp = (prop) => (record) => record[prop];

	onClickWarehouse = (record) => console.log(record) || store.route.push({ path: `${WAREHOUSE_PATH}/${record.id}` });

	render() {
		const deleteLabel = getLabelName('delete', MODEL_NAME);
		const deleteTitle = t(deleteLabel) === deleteLabel ? t('delete') : t(deleteLabel);
		const { beforeSave, beforeDelete, id, model, onBack, getRecord, warehouses, renderProp } = this;
		const selectProps = { noTotal: true, maxItems: 10, width: 200 };
		const noDeleteIfExternalId = !!this.record?.externalId;

		return (
			<ModelEdit
				model={model}
				id={id}
				deleteTitle={deleteTitle}
				beforeSave={beforeSave}
				beforeDelete={beforeDelete}
				onCancel={onBack}
				filter={MODEL_FILTER}
				getRecord={getRecord}
				className='nomenclature-edit'
				noDelete={noDeleteIfExternalId}
			>
				<h2>{t('nomenclature.position')}</h2>
				<div className='form-content'>
					<div className='left'>
						<Field property='name' label={t('name')} isRequired autoFocus={!this.record?.id} />
						<Field relation='group' property='name' label={t('category.title')}>
							<RecordSelect isRequired />
						</Field>

						<Row>
							<Field property='article' label={t('article')}>
								<Input placeholder={t('typeText')} />
							</Field>
							<Field property='code' label={t('code')}>
								<Input placeholder={t('typeText')} />
							</Field>
						</Row>

						<Field relation='material' property='name' label={t('material.title')}>
							<RecordSelect />
						</Field>

						<Row>
							<Field relation='measure' property='name' label={t('measure.title')}>
								<RecordSelect {...selectProps} />
							</Field>
							<Field property='favourite' className='favourite-checkbox'>
								<Checkbox />
								<label>{t('nomenclature.favourite')}</label>
							</Field>
						</Row>
					</div>
					{!!warehouses?.length && (
						<div className='right'>
							<h3>{t('nomenclature.remains')}</h3>
							<Table rows={warehouses} onRowClick={this.onClickWarehouse}>
								<Column computed={renderProp('code')} label={t('code')} width={100} />
								<Column computed={renderProp('name')} label={t('storehouse.title')} />
								<Column computed={renderProp('total')} label={t('total')} width={100} className='text-right' />
							</Table>
						</div>
					)}
				</div>
			</ModelEdit>
		);
	}
}

