import React from 'react';
import './movement.scss';
import { Button, ButtonGroup, DatePicker, RecordSelect, Input } from '@smartplatform/ui';
import { Toolbar } from 'components';
import { observer, Provider } from 'mobx-react';
import store from 'client/store';
import NomenclatureMovementStore from './store';
import { COST, INCOME, TRANSFER, FILTER_CLOSED_BUTTONS } from './constants';
import { NomenclatureMovementTable } from './movementTable';
import t from 'i18n';

@observer
export class Movement extends React.Component {
	constructor(props) {
		super(props);
		this.store = this.props.store;
		this.editStore = new NomenclatureMovementStore();
		store.ui.title = t('nomenclatureMovement.newMovement');
		this.init();
	}

	init = async () => {
		this.isLoading = true;

		this.record = new store.model.NomenclatureMovement();
		store.ui.title = t('nomenclatureMovement.newMovement');

		this.isLoading = false;
	};

	render() {
		const { movementType, onChangeValue, date, code, warehouseFrom, warehouseTo, contragentFrom, contragentTo, back, onSave, validateData} = this.editStore;

		const disabledSave = !validateData();

		return (
			<Provider store={this.editStore}>
				<>
					<div className='movements-buttons'>
						<ButtonGroup className='button-group'>
							{[INCOME, COST, TRANSFER].map((typeName) => (
								<Button
									key={typeName}
									variant={movementType === typeName ? 'primary' : 'default'}
									onClick={() => onChangeValue('movementType')(typeName)}
								>
									{t('nomenclatureMovement.' + typeName.toLowerCase())}
								</Button>
							))}
						</ButtonGroup>
					</div>
					<div className='info-section'>
						<div className='title'>
							<span>{store.ui.title}</span>
						</div>
						<div className='common-fields'>
							<div className='field'>
								<label>{t('nomenclatureMovement.code')}</label>
								<Input type='text' value={code} onChange={(value) => onChangeValue('code')(value)} />
							</div>
							<div className='field'>
								<label className='required'>{t('date')}</label>
								<DatePicker
									type='date'
									value={date}
									placeholder={t('datePickerPlaceholder')}
									onChange={(value) => onChangeValue('date')(value)}
								/>
							</div>
							<div className='fields-group'>
								{movementType === INCOME && (
									<div className='field'>
										<label>{t('contragent.title')}</label>
										<RecordSelect
											model={store.model.Contragent}
											property='name'
											value={contragentFrom}
											onChange={(value) => onChangeValue('contragentFrom')(value)}
										/>
									</div>
								)}
								{(movementType === COST) && (
									<div className='field'>
										<label>{t('contragent.title')}</label>
										<RecordSelect
											model={store.model.Contragent}
											property='name'
											value={contragentTo}
											onChange={(value) => onChangeValue('contragentTo')(value)}
										/>
									</div>
								)}
							</div>
							<div className='fields-group'>
								{(movementType === INCOME || movementType === TRANSFER) && (
									<div className='field'>
										<label className='required'>{t('warehouse.warehouseTo')}</label>
										<RecordSelect
											model={store.model.Warehouse}
											property='name'
											value={warehouseTo}
											onChange={(value) => onChangeValue('warehouseTo')(value)}
											width={300}
										/>
									</div>
								)}
								{(movementType === COST || movementType === TRANSFER) && (
									<div className='field'>
										<label className='required'>{t('warehouse.warehouseFrom')}</label>
										<RecordSelect
											model={store.model.Warehouse}
											property='name'
											value={warehouseFrom}
											onChange={(value) => onChangeValue('warehouseFrom')(value)}
											width={300}
										/>
									</div>
								)}
							</div>
						</div>
						<NomenclatureMovementTable />
						<Button className='button' onClick={onSave} variant='primary' disabled={disabledSave}>
							{t('save')}
						</Button>
						<Button className='button' onClick={back}>
							{t('cancel')}
						</Button>
					</div>
				</>
			</Provider>
		);
	}
}

