import React, { Component, createRef } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import store from 'client/store';
import t from 'i18n';
import classNames from 'classnames';
import { MenuItem } from './MenuItem';

import './navigationMenu.scss';

@observer
export class NavigationMenu extends Component {
	render() {
		const { items, className, isChildren, onClick, index } = this.props;

		let _items = (
			<ul>
				{items.map((item, i) => (
					<MenuItem item={item} key={item.id || i} onClick={onClick} index={index} hoverStyle={{ paddingLeft: 12 * index }} />
				))}
			</ul>
		);

		if (!isChildren) {
			return (
				<div className={classNames('navigation-menu', className)}>
					<nav>{_items}</nav>
				</div>
			);
		}

		return _items;
	}
}

NavigationMenu.propTypes = {
	items: PropTypes.any,
	className: PropTypes.string,
};

