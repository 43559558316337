export const processText = (html) => {
	if (html) {
		// нельзя использовать lookbehind - (?<= ) и (?<! ) - https://caniuse.com/js-regexp-lookbehind
		// временно заменяем готовые гиперссылки (тэги <a>) на "--anchor[N]--" и сохраняем их в массиве
		const anchorMatch = html.match(/<a.*<\/a>/gi);
		const anchors = [];
		if (anchorMatch) {
			anchorMatch.forEach((anchor, i) => {
				console.log(anchor);
				anchors.push(anchor);
				html = html.replace(anchor, `--anchor${i}--`);
			});
		}
		// заменяем голые URL на гиперссылки
		const urlMatch = html.match(/((https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi);
		if (urlMatch) {
			urlMatch.forEach((url) => {
				html = html.replace(url, `<a target="_blank" href="${url}">${url}</a>`);
			});
		}
		// возвращаем сохраненные ранее готовые гиперссылки
		anchors.forEach((anchor, i) => (html = html.replace(`--anchor${i}--`, anchor)));

		const match = html.match(/<img[^>]+>/gi);
		if (match) {
			match.forEach((imgTag, i) => {
				const srcMatch = imgTag.match(/src="([^"]+)"/i);
				if (srcMatch && srcMatch[1]) {
					const src = srcMatch[1];
					try {
						const re = new RegExp(`(${imgTag})`, 'g');
						html = html.replace(re, `<a class="lightbox" target="_blank" href="${src}">$1</a>`);
					} catch (e) {
						console.warn(e);
					}
				}
			});
		}
	}
	return html;
};

