import React, { Component, createRef } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { NavigationMenu } from './NavigationMenu';
import classNames from 'classnames';
import { DeleteIconButton, IconButton } from '../buttons';
import { renderFaIcons } from 'client/tools';
import store from 'client/store';
import t from 'i18n';
import { TruncateText } from '../text';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { AutoAnimate } from '@smartplatform/ui';

@inject('productListStore')
@observer
export class MenuItem extends Component {
	@observable isMenuClose = true;

	constructor(props) {
		super(props);
		this.ref = createRef();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { item, productListStore } = this.props;
		if (item.id === productListStore.selectedCategoryId) {
			this.isMenuClose = false;
		}
	}

	deleteCategory = async (item) => {
		const { priceListId } = this.props.productListStore;
		await store.model.ProductCategory.deleteById(item.id);
		store.route.push({ path: `/sales/pricelists/${priceListId}/catalog`, params: {} });
		await this.props.productListStore.fetchAllCategories();
	};

	render() {
		const { item, onClick, index, hoverStyle, productListStore } = this.props;
		const { selectedCategoryId } = productListStore;
		let _index = index;

		const isChildrenExist = item.children?.length > 0;

		let renderItem = <TruncateText>{item.name}</TruncateText>;

		if (isChildrenExist) {
			renderItem = (
				<div className='name'>
					{renderItem}
					{isChildrenExist && (
						<IconButton
							icon={this.isMenuClose ? renderFaIcons.arrowDown : renderFaIcons.arrowUp}
							size='sm'
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								this.isMenuClose = !this.isMenuClose;
							}}
							noBorder
						/>
					)}
				</div>
			);
			_index++;
		}

		return (
			<AutoAnimate
				type='li'
				className={classNames('basic-li', { 'parent-li': isChildrenExist, itemSelected: selectedCategoryId === item.id })}
			>
				<div
					className='hover'
					onClick={() => {
						onClick(item);
					}}
				>
					<Link to={item.path} style={hoverStyle}>
						{renderItem}
					</Link>
					{!isChildrenExist && !!item.id && (
						<DeleteIconButton noBorder confirmMessage={t('deleteCategory')} onConfirm={() => this.deleteCategory(item)} />
					)}
				</div>
				{isChildrenExist && !this.isMenuClose && (
					<NavigationMenu onClick={onClick} items={item.children} isChildren index={_index} />
				)}
			</AutoAnimate>
		);
	}
}

