import React, { Component } from 'react';
import _Logo from 'client/img/sp-logo-new.svg';
import LogoSmart from 'client/img/SMART-logo.svg';
import LogoErp from 'client/img/ERP-logo.svg';
import './logo.scss';

export class Logo extends Component {
	render() {
		return (
			<div className='auth-logo'>
				<div className='logo-icon-wrapper'>
					<_Logo className='logo-icon' />
				</div>
				<div className='logo-text-wrapper'>
					<LogoSmart />
					<LogoErp className='logo-erp' />
				</div>
			</div>
		);
	}
}

