import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Documents from './Documents';
import { Document } from './single-page';
import './documents.scss';

/**
 * Главный компонент с роутами и компонентами модуля.
 * Также может содержать, например, некую асинхронную логику инициализации.
 */

const path = '/documents';

export default () => <Switch>
	<Route path={path} exact><Documents /></Route>
	<Route path={`${path}/page/:page`} children={({ match }) => <Documents match={match} />} />
	<Route path={`${path}/create`} children={({ match }) => <Document match={match} path={path} />} />
	<Route path={`${path}/:id`} children={({ match }) => <Document match={match} path={path} />} />
</Switch>;
