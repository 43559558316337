import React from 'react';
import { observable, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import { PER_PAGE, ORDER_TILES_SORTING_VARIANTS } from '../constants';
import { Pager, Select } from '@smartplatform/ui';
import store from 'client/store';
import OrderTile from './OrderTile';

import './ordersTiles.scss';

@inject('store')
@observer
export class OrdersTiles extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store;
		this.store.isLoading = true;
		this.store.fetchTilesRecords();
	}

	render() {
		const { records, isLoading, pageSize, page, onChange, order } = this.store;

		if (isLoading) return 'Loading...';

		return (
			<div className='orders-tiles'>
				<div className='pager-and-sort'>
					<Select items={ORDER_TILES_SORTING_VARIANTS} value={order} onChange={onChange('order')} isRequired />
				</div>
				<div className='tiles-block'>
					{records.map((order) => (
						<OrderTile key={order.id} order={order} />
					))}
				</div>
				<Pager itemsPerPage={20} current={page} onChange={onChange('page')} totalCount={records.totalCount} />
			</div>
		);
	}
}

