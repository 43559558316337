import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { DeleteButton, Button, CancelButton } from 'components';
import t from 'i18n';

@observer
export default class Value extends React.Component {
	@observable changed = false;

	constructor(props) {
		super(props);
		this.origName = props.value.name || '';
	}

	onNameChange = (e) => {
		this.props.value.name = e.target.value;
		this.changed = this.props.value.name !== this.origName;
	};

	delete = async () => {
		await this.props.value.delete();
		this.props.onDelete();
	};

	save = async () => {
		const isNew = !this.props.value.id;
		await this.props.value.save();
		this.origName = this.props.value.name;
		this.changed = false;
		if (isNew) this.props.onCreate();
	};

	render() {
		const { value, index, onCancel } = this.props;
		return (
			<tr>
				<td width={50}>{index + 1}</td>
				<td>
					<input type='text' size={'70'} value={value.name || ''} autoFocus={!value.id} onChange={this.onNameChange} />
				</td>
				<td className='actions'>
					{this.changed && <Button text={t('save')} onClick={this.save} />}
					{!!value.id && <DeleteButton onConfirm={this.delete} />}
					{!value.id && <CancelButton onClick={onCancel} />}
				</td>
			</tr>
		);
	}
}
