import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import store from 'client/store';

@observer
export class ThemeToggle extends React.Component {
	@observable isDarkMode = store.local.isDarkMode || false;

	switchTheme = (e) => {
		e.preventDefault();

		if (this.isDarkMode) {
			this.isDarkMode = false;
			document.body.className = 'default-theme';
		} else {
			this.isDarkMode = true;
			document.body.className = 'dark-theme';
		}

		store.local.isDarkMode = this.isDarkMode;
		store.local.save();
	};

	render() {
		return (
			<div className={classNames('themes', { dark: this.isDarkMode })} onClick={(e) => this.switchTheme(e)}>
				<div className={classNames('wrapper-toggle-themes', { dark: this.isDarkMode })}>
					<div className='toggle-themes'></div>
				</div>
				<div className='sun'>
					<FontAwesomeIcon icon={faSun} />
				</div>
				<div className='moon'>
					<FontAwesomeIcon icon={faMoon} />
				</div>
			</div>
		);
	}
}
