import React from 'react';
import { Route, Switch } from 'react-router-dom';
import store from 'client/store';
import t from 'i18n';
import { WarehousesList } from './WarehousesList';
import { WarehousesEdit } from './WarehousesEdit';
import { WarehouseMain } from './WarehouseMain';

import './warehouses.scss';

const path = '/warehouses';

export default class Warehouses extends React.Component {
	constructor(props) {
		super(props);
		store.ui.title = t('warehouses.title');
	}

	render() {
		return (
			<Switch>
				<Route path='/warehouses' exact children={({ match }) => <WarehousesList match={match} createPath={path} />} />
				<Route path={'/warehouses/page/:page'} children={({ match }) => <WarehousesList match={match} createPath={path} />} />
				<Route path='/warehouses/create' children={({ match }) => <WarehouseMain match={match} />} />
				<Route path='/warehouses/:id' children={({ match }) => <WarehouseMain match={match} />} />
			</Switch>
		);
	}
}

