import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Edit } from './Edit';
import { List } from './List';

import t from 'i18n';
import './workshops.scss';

const path = '/workshops';
const title = t('process.workshop.plural');
const modelName = 'ProcessWorkshop';

const Workshops = () => (
	<Switch>
		<Route path={path} exact children={({ match }) => <List title={title} match={match} modelName={modelName} createPath={path} />} />
		<Route
			path={`${path}/page/:page`}
			children={({ match }) => <List title={title} match={match} modelName={modelName} createPath={path} />}
		/>
		<Route path={`${path}/:id`} children={({ match }) => <Edit title={title} match={match} path={path} modelName={modelName} />} />
	</Switch>
);

export default Workshops;
