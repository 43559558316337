import { faClock } from '@fortawesome/free-regular-svg-icons';
import { IconTitle } from './IconTitle';
import React from 'react';
import classNames from 'classnames';
import { relative as _relative, formatDate } from 'client/tools';

export const DueDate = ({ date, warningStep = 86400000, relative, className, taskClosed }) => {
	const dueDateClassName = () => {
		if (taskClosed) return '';

		const dueDate = new Date(date);
		const currentDate = new Date();
		if (dueDate < currentDate) return 'expired';
		if (dueDate - currentDate < warningStep) return 'expiring-soon';
		return '';
	};

	const _className = classNames('due-date', dueDateClassName(), className);
	const _date = relative ? _relative(date) : formatDate(date);
	return <IconTitle text={_date} className={_className} faIcon={faClock} />;
};

