import React from 'react';
import { observer } from 'mobx-react';
import { BoardListSelect } from '../selects';

export const ToolbarListSelect = observer(({ label, ...recordSelectProps }) => {
	return (
		<div className='toolbar-list-select'>
			{label && <label>{label}</label>}
			<BoardListSelect {...recordSelectProps} />
		</div>
	);
});

