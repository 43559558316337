export default {
	translation: {
		name: 'Name',
		show: 'Show',
		project: {
			select: 'Select a project from the list',
			creator: 'The author of the project',
			title: 'Project',
			plural: 'Projects',
			createNew: 'Create new project',
			created: 'Project was created',
			addRole: 'Add member',
			addTask: 'Add task',
			dueDate: 'Project deadline',
			labels: 'Project labels',
			name: 'Project name',
			addName: 'Enter the project name',
			description: 'Project description',
			projectList: 'Status of the project',
			projectListShort: 'Status',
			tasks: 'Tasks',
			lists: 'Task status',
			members: 'Members',
			history: 'Project history',
			info: 'Information',
			deleteMsg: 'You are gonna delete the proejct! Are you sure?',
			notFound: 'Project was not found',
			listMode: 'List',
			kanbanMode: 'Kanban',
			count: 'Projects count',
			tasksCount: 'Tasks count',
			openedCount: 'Opened',
			closedCount: 'Closed',
			filterHint: 'Search by id, name',
			search: 'Search project',
			total: 'Projects total',
			mode: 'View',
			all: 'All',
			active: 'Active',
			closed: 'Closed',
			sortByUpdated: 'Recently updated',
			sortByNew: 'New',
			owner: 'Project was created by:',
			searchByMember: 'Search members',
			delete: 'Delete the project',
			startDate: 'Project starts',
			new: 'New project',
			taskList: 'Task status',
		},
		task: {
			title: 'Task',
			plural: 'Tasks',
			create: 'New task',
			delete: 'Delete task',
			confirmDelete: 'Are you sure?',
			name: 'Task name',
			description: 'Task description',
			changeDescription: 'Change description',
			hasDescription: 'This task has description',
			createDescription: 'Add description',
			priority: 'Priority',
			person: 'Assign to',
			startDate: 'Task start',
			dueDate: 'Task deadline',
			assignedTo: 'Assigned to',
			notAssigned: 'Not assigned',
			actions: 'Actions with task',
			labels: 'Task labels',
			updatedAt: 'Task has been updated',
			closed: 'Closed',
			active: 'Active',
			status: 'Statuses',
			boardList: 'Task statuses',
			all: 'All',
			allAuthor: 'Any author',
			private: 'Private',
			subject: 'task',
			noProject: 'Task without project!',
			sortByUpdated: 'Sort by new',
			total: 'Task total',
			notFound: 'Task was not found',
			addDescription: 'Add task description',
			assignedToMe: 'Assigned to me',
			addName: 'Enter task name',
			iAuthor: 'I am the author'
		},
		order: {
			descrName: 'Order description',
			amount: 'Amount',
			new: 'New order',
			report: 'Report',
			document: 'Document',
			editReport: 'Edit report',
			downloadReport: 'Download report',
			count: 'Orders count',
			noOrders: 'Order was not found',
			category: 'Category',
			defaultListError: 'Order default status not set',
			copy: 'Copy order',
			garant: 'Guarantee',
			addGarant: 'Add assurance',
			getprepaid: 'Order is received',
			title: 'Order',
			plural: 'Orders',
			number: 'Number',
			n: '№',
			description: 'Description',
			comment: 'Comment',
			updatedAt: 'Order has been updated',
			info: 'Order information',
			infoShort: 'Information',
			positions: 'Order positions',
			positionsCount: 'Positions',
			total: 'Total cost',
			totalShort: 'Total',
			price: 'Price',
			client: 'Counteragent',
			selectClient: 'Not selected',
			invoiceNumber: 'Invoice number',
			invoiceDate: 'Invoice date',
			contractNumber: 'Contract number',
			contractDate: 'Contract date',
			paidStatus: 'Paid status',
			paid: 'It is paid',
			notPaid: 'Not paid',
			status: 'Order status',
			manager: 'Manager',
			addDescription: 'Add description',
			addComment: 'Add comment',
			startDate: 'Start date',
			endDate: 'End date',
			startDateFrom: 'Start date from',
			startDateTo: 'Start date to',
			endDateFrom: 'End date from',
			endDateTo: 'End date to',
			mode: 'View',
			priceFrom: 'Price from',
			priceTo: 'Price to',
			confirmClientChange:
				'This order has contacts from previous counteragent. When counteragent will be changing they will be removed',
			gotoClient: 'Go to counteragent',
			removeContact: 'Do you wanna remove the contact from order?',
			searchHint: 'Search by order number, counteragent, manager',
			prepaid: 'Prepaid',
			passport: 'Order passport',
			technologyRoadmap: 'Flow chart',
			history: {
				title: 'Order history',
				created: 'created the order',
				invoiceNumber: 'changed invoice number',
				invoiceDate: 'changed invoice date',
				contractNumber: 'changed contract number',
				contractDate: 'changed contract date',
				paid: 'changed payment status on',
				paidYes: 'Paid',
				paidNo: 'Not paid',
				status: 'changed order status on',
				newPosition: 'added new position',
				deletedPosition: 'deleted order position',
				newWaybill: 'added waybill',
				deletedWaybill: 'deleted waybill',
				total: 'Total cost changed to ',
				appointed: 'appointed manager: ',
				appointedSelf: 'appointed self as manager',
				appointedNull: 'removed manager appointed ',
				client: 'changed counteragent: ',
				startDate: 'changed start date:',
				endDate: 'changed end date:',
				prepaid: 'changed prepaid',
				prepaidCancel: 'removed prepaid',
				newProcess: 'added new proccess',
				deletedProcess: 'removed proccess',
				newMaterial: 'added new material',
				deletedMaterial: 'removed new material',
				deletedPositionAttachment: 'removed from order position attachment(s)',
				addedPositionAttachment: 'added from order position attachment(s)',
			},
		},
		warehouses: {
			title: 'Warehouses',
			add: 'New warehouse',
			address: 'Address',
			phone: 'Phone',
			dopinfo: 'Additional information',
			remove: 'Delete warehouse',
			newWarehouse: 'New warehouse',
			createWarehouses: 'Create warehouse',
			warehouseTitle: 'Warehouse name',
			contragent: 'Contragent',
			responsible: 'Accountable'
		},
		nomenclatureMovement: {
			title: 'Nomenclature movements',
			new: 'New movement',
			plural: 'Nomenclature movements',
			income: 'Income',
			cost: 'Cost',
			transfer: 'Transfer',
			newMovement: 'New movement',
			EditMovement: 'Edit movement',
			code: 'Number',
			numberOfLine: 'Line №',
			total: 'Total',
			price: 'Price',
			currency: 'Currency',
			errors: {
				emptyDate: 'Date field is empty',
				emptyWarehouseTo: 'Recipient warehouse field is empty',
				emptyWarehouseFrom: 'Sender warehouse field is empty',
				emptyNomenclature: 'НNomenclature field is empty',
				emptyQuantity: 'Quantity field is empty',
			}
		},
	},
};
