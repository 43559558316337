import React from 'react';
import { Column } from '@smartplatform/ui';
import { BasicList } from 'components';
import store from 'client/store';
import t from 'i18n';

export default ({ modelName, path, title, relation, relationLabel }) => {
	return (
		<BasicList model={store.model[modelName]} path={path} title={title} filter={{ include: relation }} toolbarButtonText='material.add'>
			<Column property='name' label={t('name')} />
			<Column relation={relation} property='name' label={t(relationLabel)} />
		</BasicList>
	);
};

