import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ORDERS_PATH, ORDERS_REPORT_PATH, PRICE_CATEGORIES_PATH, PRICE_LISTS_PATH, SETTINGS_PATH } from './constants';
import loadable from '@loadable/component';
const PriceLists = loadable(() => import('./pricelists'));
const PriceList = loadable(() => import('./pricelist'));
const Settings = loadable(() => import('./settings'));
const Orders = loadable(() => import('./orders'));

/**
 * Главный компонент с роутами и компонентами модуля.
 * Также может содержать, например, некую асинхронную логику инициализации.
 */

export default () => (
	<Switch>
		<Route path={ORDERS_PATH} component={Orders} />
		<Route path={ORDERS_REPORT_PATH} component={Orders} />
		<Route path={PRICE_CATEGORIES_PATH} component={PriceList} />
		<Route path={PRICE_LISTS_PATH} component={PriceLists} />
		<Route path={SETTINGS_PATH} component={Settings} />
	</Switch>
);

