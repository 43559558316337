import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { DeleteButton, DeleteIconButton, Button, EditButton, IconButton, CancelButton } from 'components';
import { MaterialsTable } from './MaterialsTable';
import { RecordSelect, NumberInput, Form, Field, Row } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';

import './processItem.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';

@observer
export class ProcessItem extends Component {
	@observable isSaved = false;
	@observable isEdit = false;
	@observable isMaterialsTableOpen = false;
	@observable savedValues = {
		money : 0,
		process : null,
	};

	constructor(props) {
		super(props);
		if (props.item.processId)
			this.isSaved = true;
		else
			this.isMaterialsTableOpen = true;
	}

	onSave = async (event) => {
		event.stopPropagation();
		await this.props.item.save();
		if (this.props.isNew) {
			await this.props.record.process.add(this.props.item.id);
			this.props.onSaveNewProcess();
		}
		this.isSaved = true;
	};

	onChange = (prop) => (value) => {
		if (prop === 'money') {
			this.props.item[prop] = value;
		} else if (prop === 'process') {
			this.props.item[`${prop}Id`] = value.id;
			this.props.item[prop] = value;
		}
	};

	onChangeDropDown = () => this.isMaterialsTableOpen = !this.isMaterialsTableOpen

	onEdit = (event, record) => {
		event.stopPropagation();
		this.savedValues.money = record.money;
		this.savedValues.process = record.process;
		this.isSaved = false;
		this.isEdit = true;
	}

	onCancel = (event, record) => {
		event.stopPropagation();
		this.isEdit = false;
		this.isSaved = true;
		record.money = this.savedValues.money;
		record.process = this.savedValues.process;
	}

	renderCancel = (record) => {
		if(this.isEdit) {
			return <CancelButton onClick={(event) => this.onCancel(event, record)}/>
		}
	}

	renderButtons = (record) => {
		return this.isSaved ? (
			<>
				<EditButton noBorder onClick={(event) => this.onEdit(event, record)} />
				<DeleteIconButton noBorder onConfirm={() => this.props.onDelete(record, this.props.isNew)} />
				<IconButton
					icon={<FontAwesomeIcon icon={faAngleUp} />}
					className={this.isMaterialsTableOpen ? 'up' : 'down'}
					disableRipple
					noBorder
				/>

			</>
		) : (
			<>
				<Button
					onClick={(event) => this.onSave(event)}
					text={t('save')}
					disabled={!record.processId}
				/>
				{this.renderCancel(record)}
				{this.isEdit
					? (<DeleteIconButton onConfirm={() => this.props.onDelete(record, this.props.isNew)} />)
					: (<DeleteButton text={t('techRoadMap.process.delete')} onConfirm={() => this.props.onDelete(record, this.props.isNew)} />)
				}

			</>
		);
	};

	renderMoney = (record) => {
		return !record.process || !this.isSaved ? (
			<NumberInput
				positiveOnly
				onChange={this.onChange('money')}
				value={record.money}
				placeholder={t('techRoadMap.money')}
				onClick={(e) => e.stopPropagation()}
			/>
		) : (
			(record.money ? record.money + ' ₽' : '')
		);
	};

	renderProcess = (record) => {
		return !record.process || !this.isSaved ? (
			<RecordSelect
				model={store.model.ProcessOperation}
				onChange={this.onChange('process')}
				placeholder={t('techRoadMap.process.title')}
				isRequired
				property='name'
				value={record.process}
			/>
		) : record.process?.name;
	};

	render() {
		const { item, indexItem } = this.props;

		return (
			<div className={'process-item ' + (this.isMaterialsTableOpen ? 'process-item-open' : 'process-item-close')} >
				<div className='process-and-money' onClick={this.onChangeDropDown}>
					<Form record={item} noDelete noSave>
						<Row>
							{indexItem+1}
							<Field disabled={this.isSaved}>
								{(record) => this.renderProcess(record)}
							</Field>
							<Field property='money'>
								{(record) => this.renderMoney(record)}
							</Field>
							<Field className='process-buttons'>
								{this.renderButtons}
							</Field>
						</Row>
					</Form>
				</div>
				<MaterialsTable process={item} className='process-materials'/>
			</div>
		);
	}
}
