import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { DeleteIconButton, Button, EditButton, CancelButton } from 'components';
import { renderPlaceholder } from 'client/tools';
import { RecordSelect, FormContext } from '@smartplatform/ui';

import store from 'client/store';
import t from 'i18n';

@observer
export class WarehouseItem extends Component {
	@observable isEdit = false;
	@observable currentValue = this.props.item;

	static contextType = FormContext;

	constructor(props, context) {
		super(props);
		this.workshop = context.record;
	}

	onEdit = () => {
		this.isEdit = true;
	};

	onCancel = () => {
		this.isEdit = false;
		this.currentValue = this.props.item;
	};

	onChange = (warehouse) => {
		this.currentValue = warehouse;
		this.props.getWarehousesIds(warehouse.id);
	};

	onSave = async () => {
		if (this.props.item.id === this.currentValue.id) {
			this.isEdit = false;
		} else {
			if (this.context.record?.id) {
				await this.workshop.warehouses.remove(this.props.item.id);
				await this.workshop.warehouses.add(this.currentValue.id);
				this.props.init();
			} else {
				this.props.onDelete(this.props.item)();
				this.props.onSave(this.currentValue);
				this.isEdit = true;
			}
		}
	};

	renderButtons = () =>
		!this.isEdit ? (
			<>
				<EditButton onClick={this.onEdit} disableRipple />
			</>
		) : (
			<>
				<Button text={t('save')} onClick={this.onSave} />
				<CancelButton onClick={this.onCancel} />
			</>
		);

	render() {
		const { item, excludeIds } = this.props;

		return (
			<>
				<div className='warehouse-line'>
					<div className='warehouse-wrapper'>
						<RecordSelect
							model={store.model.Warehouse}
							onChange={this.onChange}
							filter={{ where: { id: { nin: excludeIds } } }}
							placeholder={renderPlaceholder(t('process.workshop.placeholder'))}
							disabled={!this.isEdit}
							property='name'
							isRequired
							value={this.currentValue}
						/>
					</div>
					<div className='warehouse-item-buttons'>
						<DeleteIconButton onConfirm={this.props.onDelete(item)} />
						{this.renderButtons()}
					</div>
				</div>
			</>
		);
	}
}

