import React from 'react';
import { Route, Switch } from 'react-router-dom';
import store from 'client/store';
import t from 'i18n';
import { MovementsList } from './MovementsList';
import { Movement } from './movement';


const path = '/nomenclaturemovements';

export class Movements extends React.Component {
	constructor(props) {
		super(props);
		store.ui.title = t('nomenclatureMovement.title');
	}

	render() {
		return (
			<Switch>
				<Route path='/nomenclaturemovements' exact children={({ match }) => <MovementsList match={match} createPath={path} />} />
				<Route path={'/nomenclaturemovements/page/:page'} children={({ match }) => <MovementsList match={match} createPath={path} />} />
				<Route path='/nomenclaturemovements/create' children={({ match }) => <Movement match={match} />} />
				<Route path='/nomenclaturemovements/:id' children={({ match }) => <Movement match={match} />} />
			</Switch>
		);
	}
}