import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { ToolbarCreateBtn } from './ToolbarCreateBtn';
import { ToolbarExportBtn } from './ToolbarExportBtn';
import { ToolbarCount } from './ToolbarCount';
import { ToolbarSearch } from './ToolbarSearch';
import { ToolbarMembers } from './ToolbarMembers';
import { ToolbarUserSelect } from './ToolbarUserSelect';
import { ToolbarButtonGroup } from './ToolbarButtonGroup';
import { ToolbarListSelect } from './ToolbarListSelect';
import { ToolbarSortSelect } from './ToolbarSortSelect';
import { ToolbarCleanButton } from './ToolbarCleanButton';
import { ToolbarProjectSelect } from './ToolbarProjectSelect';
import { ToolbarItemsSelect } from './ToolbarItemsSelect';
import { ToolbarTop } from './ToolbarTop';
import { ToolbarRightSide } from './ToolbarRightSide';
import { ToolbarLeftSide } from './ToolbarLeftSide';
import { ToolbarBottom } from './ToolbarBottom';

import './toolbar.scss';

export const Toolbar = ({ children, className }) => <div className={classNames('composite-toolbar', className)}>{children}</div>;

Toolbar.CreateBtn = ToolbarCreateBtn;
Toolbar.ExportBtn = ToolbarExportBtn;
Toolbar.Count = ToolbarCount;
Toolbar.Search = ToolbarSearch;
Toolbar.Members = ToolbarMembers;
Toolbar.UserSelect = ToolbarUserSelect;
Toolbar.ButtonGroup = ToolbarButtonGroup;
Toolbar.ListSelect = ToolbarListSelect;
Toolbar.SortSelect = ToolbarSortSelect;
Toolbar.CleanButton = ToolbarCleanButton;
Toolbar.ProjectSelect = ToolbarProjectSelect;
Toolbar.ItemsSelect = ToolbarItemsSelect;
Toolbar.Top = ToolbarTop;
Toolbar.Bottom = ToolbarBottom;
Toolbar.RightSide = ToolbarRightSide;
Toolbar.LeftSide = ToolbarLeftSide;

