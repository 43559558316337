import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import classNames from 'classnames';
import store from 'client/store';
import t from 'i18n';

import './kanbanSkeleton.scss';

@observer
export class KanbanSkeleton extends Component {
	listArray = [];
	tasksArray = [];

	constructor(props) {
		super(props);
		const listCount = 4;
		const tasksCount = 2;
		this.listArray = new Array(listCount).fill(1);
		this.tasksArray = new Array(tasksCount).fill(1);
	}

	render() {
		const { className } = this.props;

		return (
			<div className={classNames('kanban-skeleton', className)}>
				<div className='kanban-skeleton-container'>
					{this.listArray.map((_, i) => (
						<div className='skeleton-list-column' key={i}>
							<div className='skeleton-list-header' />
							<div className='skeleton-tasks-container'>
								{i % 2 === 0 ? (
									<div className='skeleton-task' key={i} />
								) : (
									this.tasksArray.map((_, i) => <div className='skeleton-task' key={i} />)
								)}
							</div>
						</div>
					))}
				</div>
			</div>
		);
	}
}

