import React, { Component } from 'react';
import { Tab, Tabs } from '@smartplatform/ui';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Redirect, Route } from 'react-router-dom';
import t from 'i18n';
import { Toolbar } from 'components';
import { tabs, path } from './tabs';

import store from 'client/store';

import './materials.scss';

@observer
export default class Materials extends Component {
	@observable search = '';

	resetSearch = () => {
		this.search = '';
	};

	onSearch = (e) => {
		this.search = e.target.value;
	};

	render() {
		let buttonTitle = '',
			createPath;
		tabs.forEach((tab) => {
			if (tab.title === store.ui.title) {
				buttonTitle = tab.buttonTitle;
				createPath = tab.path;
			}
		});

		return (
			<>
				<Route path={path}>
					<Redirect to={tabs[0].path} />
				</Route>
				{/* <Toolbar className='toolbar-materials'>
					<Toolbar.CreateBtn url={createPath + '/create'} text={`${t('add')} ${buttonTitle}`} />
					<Toolbar.Search value={this.search} onSearch={this.onSearch} />
				</Toolbar> */}
				<Tabs className='tabs'>
					{tabs.map(({ Component, path, iconTitle, exact, ...props }, i) => (
						<Tab path={path} title={iconTitle} onClick={this.resetSearch} key={i}>
							<Component searchValue={this.search} path={path} {...props} />
						</Tab>
					))}
				</Tabs>
			</>
		);
	}
}

