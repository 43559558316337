import React from 'react';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { KanbanSkeleton } from 'components';
import { DEFAULT_PRIORITY } from 'client/constants';
import { DOCUMENT_INCLUDE } from '../constants';
import { Kanban } from '@smartplatform/ui';
import DocumentCard from './DocumentCard';
import store from 'client/store';
import t from 'i18n';
import './style.scss';

@inject('store')
@observer
export default class DocumentsKanban extends React.Component {
	@observable lists = [];
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.store = props.store;
		props.store.reload = this.doSearch;
		this.init();
	}

	doSearch = async () => {
		await this.init();
		this.kanbanInstance.reload();
	};

	getInstance = (kanbanInstance) => {
		this.kanbanInstance = kanbanInstance;
	};

	compileFilter = () => {
		const { search } = this.store;
		const searchFields = ['typeName', 'statusName', 'labelNames'];

		const trimmed = search.name.trim();
		const where = {
			and: [{ name: { like: `%${trimmed}%` } }],
		};

		for (const field of searchFields) {
			if (search[field].length) {
				let RExp = search[field].join('|');
				RExp = new RegExp(RExp, 'gi');
				where.and.push({ [field]: { regexp: `${RExp}` } });
			}
		}

		return where;
	};

	init = async () => {
		const documentsWhere = this.compileFilter();
		const lists = await store.model.ViewDocumentList.find({
			include: [
				{
					relation: 'documents',
					scope: {
						where: documentsWhere,
						include: DOCUMENT_INCLUDE,
						order: 'priority desc',
					},
				},
			],
		});

		for (let list of lists) {
			list.totalCount = await store.model.ViewDocument.count({ statusId: list.id });
		}
		this.lists = lists;
		this.isLoading = false;
	};

	renderDocument = (doc, list, options) => <DocumentCard doc={doc} list={list} {...options} />;

	@action onChange = async ({ item, text, prev, next, list, index }) => {
		console.log('onChange', item, prev, next, list, index);
		this.saving = true;

		const doc = new store.model.Document(item);

		doc.statusId = list.id;
		if (!item) {
			doc.name = text;
		}

		if (!prev) {
			doc.priority = next ? next.priority * 2 : DEFAULT_PRIORITY;
		} else if (!next) {
			doc.priority = prev ? prev.priority / 2 : DEFAULT_PRIORITY;
		} else {
			doc.priority = (prev.priority + next.priority) / 2;
		}

		try {
			await doc.save();
			this.saving = false;
		} catch (e) {
			this.saving = false;
			throw e;
		}

		return {
			id: doc.id,
			name: doc.name,
			path: `/documents/${doc.id}`,
			data: item ?? doc,
		};
	};

	gotoItem = (path) => store.route.push({ path });

	render() {
		if (this.isLoading) return <KanbanSkeleton />;

		const documents = {
			name: t('document.plural'),
			lists: this.lists.map((list) => {
				return {
					id: list.id,
					name: list.name,
					color: list.color,
					totalCount: list.totalCount,
					data: list,
					items: list.documents().map((doc) => {
						return {
							id: doc.id,
							name: doc.name,
							listId: doc.statusId,
							path: `/documents/${doc.id}`,
							data: doc,
						};
					}),
				};
			}),
		};

		return (
			<div className='documents-kanban'>
				<div className='documents-kanban-container'>
					{this.lists.length > 0 ? (
						<Kanban
							project={documents}
							renderItem={this.renderDocument}
							itemClassName='document-kanban-card'
							onChange={this.onChange}
							gotoItem={this.gotoItem}
							getInstance={this.getInstance}
							itemsLimit={20}
						/>
					) : (
						<>
							Не заданы <Link to={`/document-types`}>состояния документов</Link>
						</>
					)}
				</div>
			</div>
		);
	}
}

