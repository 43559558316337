import React from 'react';
import t from 'i18n';
import store from 'client/store';
import { observer, Provider } from 'mobx-react';
import OperationsStore from './store';
import { Toolbar } from './toolbar';
import { Charts } from './charts';
import { EditPopup } from './edit-popup';
import { List } from './list';
import './style.scss';

@observer
export default class Operations extends React.Component {
	
	constructor(props) {
		super(props);
		store.ui.title = t('operation.plural');
		this.store = new OperationsStore();
		this.store.init();
	}

	render() {
		const { showCharts, showEditPopup, isInitialized } = this.store;
		if (!isInitialized) return null;

		return (
			<Provider store={this.store}>
				<div className='finance-operations'>
					<Toolbar />
					{showCharts && <Charts />}
					<List />
					{showEditPopup && <EditPopup />}
				</div>
			</Provider>
		);
	}
}

